import { ImageField } from 'react-admin';

export const PreviewImage = ({ record, source }: any) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};
