import BookIcon from '@material-ui/icons/Book';

import ServicesEdit from './services-edit';
import ServicesList from './services-list';
// import MarathonsEdit from './MarathonsEdit';

const services = {
  list: ServicesList,
  edit: ServicesEdit,
  // edit: MarathonsEdit,
  icon: BookIcon,
  name: 'services',
};

export default services;
