import { Box, Typography } from "@material-ui/core";
import {
  CreateButton,
  useListContext,
} from "react-admin";

interface EmptyProps {
  title?: string;
  text?: string;
  hasCreate?: boolean;
  icon?: any;
}

const Empty = ({ title, text, hasCreate = false, icon }: EmptyProps) => {
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1}>
      {icon}
      {title && (
        <Typography variant="h4" paragraph>
          {title}
        </Typography>
      )}
      {text && <Typography variant="body1">{text}</Typography>}
      {hasCreate && <CreateButton basePath={basePath} />}
    </Box>
  );
};

export default Empty;
