import React, { FC } from 'react';
import {
  DateField,
  Edit,
  FormDataConsumer,
  ReferenceField,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  UrlField,
  useNotify,
} from 'react-admin';

import { CustomRequestTypeEnum } from '../../../api/generated';
import Actions, { ActionsType } from '../../../components/actions';
import FullNameField from '../../../components/full-name-field';
import PromocodeReferenceInput from '../components/promocode-reference-input';
import { CustomRequestStatusChoices, CustomRequestTypeChoices } from '../index';

export const EditCustomRequest: FC = (props: any) => {
  const notify = useNotify();
  // const promocode = useWatch({ name: "promocode" });
  const PostEditToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      onSuccess={() => notify('Заявка обновлена', 'success')}
      hasShow
      hasList
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<PostEditToolbar />}>
        <TextField source={'id'} label={'id'} />

        <ReferenceField
          reference={'users'}
          source={'authorId'}
          label={'Пользователь'}
          linkType={'show'}
        >
          <FullNameField />
        </ReferenceField>

        <SelectField
          source={'type'}
          choices={CustomRequestTypeChoices}
          label={'Тип'}
        />
        <SelectInput
          source={'status'}
          choices={CustomRequestStatusChoices}
          label={'Статус'}
        />
        <TextInput source={'comment'} label={'Комментарий'} />

        <UrlField source={'payload.socialSiteUrl'} label={'Ссылка'} />

        <DateField source={'createdAt'} label={'Дата создания'} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.type === CustomRequestTypeEnum.PersonalPromocode) {
              return (
                <PromocodeReferenceInput
                  source='promocode.id'
                  reference='promocodes'
                  label='Промокод'
                  filter={{ 'customRequest.id': null }}
                  allowEmpty
                  // validate={required()}
                  perPage={10000}
                  {...rest}
                />
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
