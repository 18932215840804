import React, { FC, useCallback } from 'react';
import {
  BooleanField,
  Datagrid,
  List,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';

import { Promocode, PromocodeTypeEnum } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';

const exporter = async (promocodes: Promocode[]) => {
  downloadCSV({
    filename: `promocodes.csv`,
    data: promocodes,
    columns: [
      'id',
      'title',
      'code',
      'privilege.freeConsultationsCount',
      'type',
      'isActive',
    ],
    columnsNames: [
      'id',
      'заголовок',
      'код',
      'количество бесплатных консультаций',
      'тип',
      'является актвиным',
    ],
  });
};

export const ListPromocodes: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve('show');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      bulkActionButtons={false}
      hasShow
      perPage={50}
      sort={{ field: 'isActive', order: 'DESC' }}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={'id'} label={'id'} />
        <TextField source={'title'} label={'Заголовок'} />
        <TextField source={'code'} label={'Код'} />
        <TextField
          source={'privilege.freeConsultationsCount'}
          label={'Бесплатных консультаций'}
          sortable={false}
        />
        <SelectField
          source={'type'}
          label={'Тип'}
          choices={[
            {
              id: PromocodeTypeEnum.FreeConsultation,
              name: 'Бесплатная консультация',
            },
          ]}
        />
        <BooleanField source={'isActive'} label={'Активный'} />
        <ReferenceField
          reference={'custom-requests'}
          source={'customRequest.id'}
          label={'Привязан к заявке'}
        >
          <TextField source='id' />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
