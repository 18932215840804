import { AuthProvider, UserIdentity } from 'ra-core';

import { api } from '../api';
import { LoginFormValues } from '../components/login-page/login-form';
import { cookie } from '../services/cookie';

export const authProvider: AuthProvider = {
  async checkAuth(params: any): Promise<void> {
    const token = cookie.get('token') || localStorage.getItem('token');
    if (token) {
      return Promise.resolve();
    }
    return Promise.reject({
      redirectTo: '/login',
      message: 'Необходимо войти в аккаунт',
    });
  },

  async checkError(error: any): Promise<void> {
    //TODO: remove next line
    console.log('error', error);

    return Promise.resolve(error);
  },

  async getIdentity(): Promise<UserIdentity> {
    try {
      const { data } = await api.V1UsersApi.usersControllerGetMe();
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject();
    }
  },

  async getPermissions(params: any): Promise<any> {
    return Promise.resolve();
  },

  async login({ phone, sms }: LoginFormValues): Promise<any> {
    try {
      const { data } = await api.V1UsersApi.usersControllerSignInSms({
        phone: phone,
        smscode: sms,
      });
      if (data.user.isAdmin) {
        try {
          cookie.set('token', data.token);
          localStorage.setItem('token', data.token);
        } catch (e) {
          console.log('error:', e);
        }
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (e) {
      return Promise.reject();
    }
  },

  async logout(params: any): Promise<void | false | string> {
    cookie.delete('token');
    localStorage.removeItem('token');
    if (!cookie.get('token')) {
      return Promise.resolve();
    }
    return Promise.reject({ redirect: '/login' });
  },
};
