import React, { FC } from 'react';
import {
  Create,
  FormTab,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

import EditToolbar from '../../../components/edit-toolbar';

export const CreateCategory: FC = (props: any) => {
  return (
    <Create {...props}>
      <TabbedForm
        toolbar={<EditToolbar />}
        defaultValue={''}
        submitOnEnter={false}
      >
        <FormTab label='Создать подтему'>
          <TextInput source={'name'} label={'Название'} />
          <TextInput source={'shortName'} label={'Короткое название'} />
          <ImageInput
            source={'iconUrl'}
            label={'Загрузить новую иконку'}
            multiple={false}
            accept={'image/jpeg,image/png'}
            maxSize={5242880}
          >
            <ImageField source={'src'} />
          </ImageInput>
          <ReferenceArrayInput
            source='rubricsIds'
            perPage={10000}
            reference='rubrics'
            label='Темы'
          >
            <SelectArrayInput optionText='name' />
          </ReferenceArrayInput>
          <TextInput
            source={'SEODescription'}
            label={'SEO Описание'}
            multiline
            fullWidth
          />
        </FormTab>
        <FormTab label='Создать подтему n-уровня'>
          <TextInput source={'name'} label={'Название'} />
          <ImageInput
            source={'iconUrl'}
            label={'Загрузить новую иконку'}
            multiple={false}
            accept={'image/jpeg,image/png'}
            maxSize={5242880}
          >
            <ImageField source={'src'} />
          </ImageInput>
          <ReferenceArrayInput
            source='rootCategoryId'
            reference='categories'
            perPage={10000}
            label='Родительская категория'
          >
            <SelectInput optionText='name' />
          </ReferenceArrayInput>
          <TextInput
            source={'SEODescription'}
            label={'SEO Описание'}
            multiline
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
