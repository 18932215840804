import React, { FC } from 'react';
import {
  Edit,
  ImageField,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import Actions, { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';
import FullNameField from '../../../components/full-name-field';
import { portfolioStatusOptions } from '../show-portfolio/show-portfolio';

export const EditPortfolio: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode={'pessimistic'}
      hasList
      hasShow
      actions={<Actions noDelete type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceField
          label={'Пользователь'}
          reference={'users'}
          source={'expertId'}
        >
          <FullNameField />
        </ReferenceField>
        <ImageField source={'imageUrls[0]'} label={'Фото 1'} />
        <ImageField source={'imageUrls[1]'} label={'Фото 2'} />
        <ImageField source={'imageUrls[2]'} label={'Фото 3'} />
        <ImageField source={'imageUrls[3]'} label={'Фото 4'} />
        <ImageField source={'imageUrls[4]'} label={'Фото 5'} />
        <ImageField source={'imageUrls[5]'} label={'Фото 6'} />
        <ImageField source={'imageUrls[6]'} label={'Фото 7'} />
        <ImageField source={'imageUrls[7]'} label={'Фото 8'} />
        <ImageField source={'imageUrls[8]'} label={'Фото 9'} />
        <ImageField source={'imageUrls[9]'} label={'Фото 10'} />
        <TextInput source={'adminComment'} label={'Комментарий'} />
        <SelectInput
          source={'status'}
          label={'Статус'}
          choices={portfolioStatusOptions}
        />
      </SimpleForm>
    </Edit>
  );
};
