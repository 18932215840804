import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import get from 'lodash.get';
import { FC } from 'react';
import {
  DateField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';
import { FieldProps, useRecordContext } from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import { ReceiptStatusChoices } from '../index';

const ArrayStringField = (props: FieldProps & { source: string }) => {
  const record = useRecordContext(props);
  const source = props.source;
  const array = get(record, source);

  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />;
  } else {
    return (
      <ul>
        {array.map((item: string | number) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  }
};
ArrayStringField.defaultProps = { addLabel: true };
export const ShowReceipts: FC = (props: any) => {
  return (
    <Show
      {...props}
      mutationMode='pessimistic'
      hasList
      hasEdit
      actions={<Actions type={ActionsType.show} />}
    >
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary>
          <Typography>Данные чека</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <SimpleShowLayout>
            <TextField fullWidth source={'id'} label={'id'} />
            <ReferenceField
              link={'show'}
              label={'Эксперт'}
              source='userId'
              reference='users'
            >
              <TextField source='id' />
            </ReferenceField>
            <ReferenceField
              link={false}
              label={'Имя'}
              source='userId'
              reference='users'
            >
              <TextField source='firstName' />
            </ReferenceField>
            <ReferenceField
              link={false}
              label={'Фамилия'}
              source='userId'
              reference='users'
            >
              <TextField source='lastName' />
            </ReferenceField>
            <ReferenceField
              link={'show'}
              label={'Консультация'}
              source='consultationId'
              reference='consultations'
            >
              <TextField source='id' />
            </ReferenceField>
            <DateField source={'createdAt'} label={'Дата создания'} />
            <UrlField source={'receiptFileUrl'} label={'Чек'} />
            <SelectField
              source={'status'}
              label={'Статус'}
              choices={ReceiptStatusChoices}
            />
            <ArrayStringField source={'adminComments'} label={'Комментарий'} />
          </SimpleShowLayout>
        </AccordionDetails>
      </Accordion>
    </Show>
  );
};
