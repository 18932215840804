import React, { FC } from "react";
import {
  BooleanField,
  FormDataConsumer,
  FunctionField,
  Labeled,
  ReferenceField,
  SelectField,
  Show,
  SimpleForm,
  TextField,
} from "react-admin";

import { complaintDecision } from "..";

export const ShowComplaint: FC = (props: any) => {
  return (
    <Show {...props}>
      <SimpleForm>
        <TextField source={"feedback"} label={"Текст жалобы"} />
        <TextField source={"date"} label={"Дата создания"} sortable={false} />
        <Labeled label="Консультация">
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ReferenceField
                source="consultationId"
                reference="consultations"
                link="show"
              >
                <FunctionField
                  render={(record: any) => formData.consultation}
                />
              </ReferenceField>
            )}
          </FormDataConsumer>
        </Labeled>
        <ReferenceField
          label="От кого жалоба"
          source="clientId"
          reference="users"
          link="show"
        >
          <FunctionField
            render={(record: any) =>
              record && `${record.firstName} ${record.lastName} id ${record.id}`
            }
          />
        </ReferenceField>
        <ReferenceField
          label="На кого жалоба"
          source="expertId"
          reference="users"
          link="show"
        >
          <FunctionField
            render={(record: any) =>
              record && `${record.firstName} ${record.lastName} id ${record.id}`
            }
          />
        </ReferenceField>
        <BooleanField source="wantRefund" label={"Хочу вернуть деньги"} />
        <SelectField
          source={"complaintDecision"}
          label={"Решение"}
          choices={complaintDecision}
        />
      </SimpleForm>
    </Show>
  );
};
