import React, { FC, useCallback } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  SelectField,
  TextField,
  TextInput,
} from 'react-admin';

import { CustomRequest } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';
import { CustomRequestStatusChoices, CustomRequestTypeChoices } from '../index';

const exporter = async (customReq: CustomRequest[]) => {
  downloadCSV({
    filename: `custom-request.csv`,
    data: customReq,
    columns: [
      'id',
      'author.firstName',
      'author.lastName',
      'type',
      'status',
      'createdAt',
      'promocode',
    ],
    columnsNames: [
      'id',
      'имя',
      'фамилия',
      'тип',
      'статус',
      'дата создания',
      'промокод',
    ],
  });
};

const CustomRequestsFilters = (props: any) => (
  <Filter {...props}>
    <TextInput source={'author.firstName'} label={'Имя'} />
    <TextInput source={'author.lastName'} label={'Фамилия'} />
  </Filter>
);

export const ListCustomRequest: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, '_blank');
      return Promise.resolve('');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<CustomRequestsFilters />}
      hasShow
      hasEdit
      perPage={50}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()} hasBulkActions={false}>
        <TextField source={'id'} label={'id'} />
        <TextField source={'author.firstName'} label={'Имя'} />
        <TextField source={'author.lastName'} label={'Фамилия'} />

        <SelectField
          source={'type'}
          choices={CustomRequestTypeChoices}
          label={'Тип'}
        />
        <SelectField
          source={'status'}
          choices={CustomRequestStatusChoices}
          label={'Статус'}
        />

        <DateField
          source={'createdAt'}
          label={'Дата создания'}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <DateField
          source={'updatedAt'}
          label={'Дата обновления'}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <ReferenceField
          label='Промокод'
          source='promocode.id'
          reference='promocodes'
        >
          <FunctionField render={(record: any) => record && `${record.id}`} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
