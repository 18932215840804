import { TransactionTypeEnum } from '../../api/generated';

export {default as ListTransactions } from './list-trtansactions';
export {default as ShowTransactions } from './show-transactions';

export const TransactionsTypeChoices = [
    {id: TransactionTypeEnum.CardAttached, name: 'Карточка прикреплена'},
    {id: TransactionTypeEnum.ConfirmDebitSession, name: 'Сессия списания подтвержден'},
    {id: TransactionTypeEnum.ConfirmTransferSession, name: 'Сессия перевода подтверждена'},
    {id: TransactionTypeEnum.InitTransferSession, name: 'Создана сессия перевода'},
    {id: TransactionTypeEnum.InitDebitSession, name: 'Создана сессия списания'},
    {id: TransactionTypeEnum.Refunded, name: 'Возвращен'},
    {id: TransactionTypeEnum.AuthorizeDebitSession, name: 'Сессия списание авторизована'},
    {id: TransactionTypeEnum.InitWithdrawal, name: 'Вывод создан'},
    {id: TransactionTypeEnum.ConfirmWithdrawal, name: 'Вывод подтвержден'},
];
