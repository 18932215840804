import React, { FC } from 'react';
import {
  DateField,
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import Actions, { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';

export const EditTheme: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      hasShow
      hasList
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source={'id'} label={'id'} />
        <TextInput source={'name'} label={'Название'} />
        <ImageField source={'iconUrl'} label={'Иконка'} />
        <ImageInput
          source={'iconUrl'}
          label={'Загрузить новую иконку'}
          multiple={false}
          accept={'image/jpeg,image/png'}
          maxSize={5242880}
        >
          <ImageField source={'src'} />
        </ImageInput>
        <TextField source={'expertsCount'} label={'Кол-во экспертов'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <TextInput
          source={'SEODescription'}
          label={'SEO Описание'}
          multiline
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};
