import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useState } from 'react';
import {
  Button,
  Confirm,
  EditButton,
  useDelete,
  useNotify,
  useRefresh,
} from 'react-admin';
import { Record } from 'react-admin';

type ShowEditRecordButtonProps = {
  record?: Record;
  basePath?: string;
};

export const ShowEditRecodrButton: FC<ShowEditRecordButtonProps> = ({
  record,
  basePath,
}) => {
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const [deleteOne, { loading }] = useDelete(
    basePath?.split('/')[1],
    record?.id,
    {},
    {
      mutationMode: 'pessimistic',
      onSuccess: () => {

        refresh(true);
      },
      onFailure: (error: any) => {
        notify(`Произошло ошибка: ${error.message}`, 'error');
      },
    }
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteOne();
    setOpen(false);
  };

  return (
    <>
      <Button
        label='Удалить'
        onClick={handleClick}
        startIcon={<DeleteIcon />}
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title='Удалить услугу'
        content='Вы уверены, что ходите удалить услугу?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <EditButton basePath={basePath} label='Редактировать' record={record} />
    </>
  );
};
