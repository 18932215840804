import React, { FC } from 'react';
import {
  DateField,
  Labeled,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';

import { CustomRequestTypeEnum } from '../../../api/generated';
import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import FullNameField from '../../../components/full-name-field';
import { CustomRequestStatusChoices, CustomRequestTypeChoices } from '../index';

export const PromocodeField = () => {
  const record = useRecordContext();

  if (record?.type === CustomRequestTypeEnum.PersonalPromocode) {
    return (
      <Labeled label='Промокод'>
        <ReferenceField reference={'promocodes'} source={'promocode.id'}>
          <TextField source='id' />
        </ReferenceField>
      </Labeled>
    );
  }

  return <></>;
};

export const ShowCustomRequest: FC = (props: any) => {
  return (
    <Show
      {...props}
      actions={<Actions type={ActionsType.show} />}
      hasList
      hasEdit
      basePath={'custom-requests'}
    >
      <SimpleShowLayout>
        <TextField source={'id'} label={'id'} />

        <ReferenceField
          reference={'users'}
          source={'authorId'}
          label={'Пользователь'}
          linkType={'show'}
        >
          <FullNameField />
        </ReferenceField>

        <SelectField
          source={'type'}
          choices={CustomRequestTypeChoices}
          label={'Тип'}
        />
        <SelectField
          source={'status'}
          choices={CustomRequestStatusChoices}
          label={'Статус'}
        />

        <UrlField source={'payload.socialSiteUrl'} label={'Ссылка'} />

        <DateField source={'createdAt'} label={'Дата создания'} />
        <PromocodeField />
      </SimpleShowLayout>
    </Show>
  );
};
