import React, { FC } from 'react';
import {
    BooleanInput,
    DateField, DateTimeInput,     Edit,
    NumberInput,
    SimpleForm,
TextField,
} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';

export const EditTariffPlans: FC = (props: any) => {

    return (
        <Edit {...props}
              mutationMode="pessimistic"
              hasShow
              hasList
              title={'Тарифный план'}
              actions={<Actions type={ActionsType.edit}/>}
        >
            <SimpleForm toolbar={<EditToolbar/>} defaultValue={''} submitOnEnter={false}>
                <TextField source={'id'} label={'id'}/>
                <NumberInput source={'commissionPercent'} label={'Процент комиссии'}/>
                <DateTimeInput source={'startDate'}
                               label={'Начало'}
                />
                <DateTimeInput source={'endDate'}
                               label={'Конец'}
                />
                <BooleanInput source={'isDefault'} label={'По умолчанию'}/>
                <DateField source={'createdAt'} label={'Дата создания'}/>
            </SimpleForm>
        </Edit>
    );
};