import { clearTel } from './clearPhone';

export const isNotPhone = () => {
  return (v: string) => {
    const clearedTel = clearTel(v);
    if (clearedTel.length === 1) {
      return 'Некорректный телефон';
    }

    return undefined;
  };
};
