import dayjs from "dayjs";
import {
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from "ra-core";

import { api } from "../../api";
import {
  AdminUpdateComplaintDecisionDtoComplaintDecisionEnum,
  Review,
} from "../../api/generated";
import { Params } from "../../types";
import { nestQueryBuilder } from "../../utils/queryBuilder";
import { RouteDataProvider } from "../types";

// @ts-ignore
export const complaintsDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll("sort"),
      filter: query.getAll("filter"),
      join: ["client", "expert", "consultation", "consultation.category"],
    };

    const { data }: any =
      await api.V1AdminComplaintsApi.getManyBaseAdminComplaintsControllerReview(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    data.data = data.data.map((complaint: any) => {
      complaint.objComplaint = `${complaint.client.lastName} ${complaint.client.firstName} id${complaint.client.id}`;
      complaint.subComplaint = `${complaint.expert.lastName} ${complaint.expert.firstName} id${complaint.expert.id}`;
      complaint.consultation = `${complaint.consultation.category.name} id${complaint.consultation.id}`;
      let date = dayjs(complaint.createdAt);
      complaint.date = date.format("DD.mm.YYYY HH:mm");
      return complaint;
    });

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const complaints = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === "number") {
        try {
          const { data: complaint } =
            await api.V1AdminComplaintsApi.getOneBaseAdminComplaintsControllerReview(
              id
            );
          complaints.push(complaint);
        } catch (e) {
          complaints.push({});
        }
      }
    }
    return { data: complaints };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {
      join: ["client", "expert", "consultation", "consultation.category"],
    };
    const { data: complaint } =
      await api.V1AdminComplaintsApi.getOneBaseAdminComplaintsControllerReview(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );
    let date = dayjs(complaint.createdAt);

    const res = {
      ...complaint,
      expertId: complaint.expert.id,
      clientId: complaint.client.id,
      consultationId: complaint.consultation.id,
      consultation: `${complaint.consultation.category?.name} id${complaint.consultation.id}`,
      date: date.format("DD.mm.YYYY HH:mm"),
    };

    return {
      data: res,
    };
  },

  async update(params: UpdateParams<Review>) {
    try {
      const { data: contract } =
        await api.V1AdminComplaintsApi.updateOneBaseAdminComplaintsControllerReview(
          params.data.id,
          {
            complaintDecision: params.data
              .complaintDecision as unknown as AdminUpdateComplaintDecisionDtoComplaintDecisionEnum,
          }
        );
      return {
        data: contract,
      };
    } catch (e) {
      return Promise.reject("Не удалось обновить жалобу");
    }
  },
} as RouteDataProvider;
