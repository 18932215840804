import { useNotify, useRedirect } from 'ra-core';
import React, { FC, useState } from 'react';
import { Button, Confirm, DateField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { api } from '../../../api';
import FullNameField from '../../../components/full-name-field';

export const ShowWallets: FC = (props: any) => {

    const redirect = useRedirect();
    const notify = useNotify();

    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        setIsOpen(s => !s);
    };

    const onWithdrawal = async () => {
        const id = parseInt(props.id);
        try{
            await api.V1AdminWalletsApi.adminWalletsControllerWithdrawalWallet(id);
            redirect('/wallets', 'list');
        }catch (e){
            notify('Не удалось вывести деньги');
        }
        toggleIsOpen();
    };

    return(
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source={'id'} label={'id'}/>
                <TextField source={'balance'} label={'Баланс'}/>
                <ReferenceField reference={'users'} source={'ownerId'} label={'Владелец'}>
                    <FullNameField/>
                </ReferenceField>
                <DateField source={'createdAt'} label={'Дата создания'}/>
                <Confirm
                    isOpen={isOpen}
                    onConfirm={onWithdrawal}
                    onClose={toggleIsOpen}
                    content={'Вы уверены, что хотите вывести деньги?'}
                    title={'Необходимо подтверждение'}
                    confirm={'Подтвердить'}
                    cancel={'Отклонить'}
                />
                <Button label={'Вывести'} onClick={toggleIsOpen} variant={'contained'}/>
            </SimpleShowLayout>
        </Show>
    );
};
