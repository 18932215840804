import React, { FC } from 'react';
import { ReferenceField, useRecordContext } from 'react-admin';

import FullNameField from '../full-name-field';

export const EvaluatedField: FC<any> = (props: any) => {
  const record = useRecordContext(props);

  let source = record.authorId === record.clientId ? 'expertId' : 'clientId';

  return (
    <ReferenceField
      reference={'users'}
      source={source}
      label={'Оцениваемый'}
      linkType={'show'}
    >
      <FullNameField />
    </ReferenceField>
  );
};
