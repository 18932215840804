import {
    GetOneParams,
    UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const faqDataProvider = {

    async getList() {
        const {data} = await api.V1AdminAboutApi.aboutControllerGetOne();
        return {
            data: [data],
            total: 1,
        };
    },

    async getOne(params: GetOneParams) {
        return await api.V1AdminAboutApi.aboutControllerGetOne();
    },

    async update(params: UpdateParams) {
        try {
            const {data: about} = await api.V1AdminAboutApi.aboutControllerUpdate(params.data);
            return {
                data: about,
            };
        } catch (e) {
            return Promise.reject('Не удалось обновить FAQ');
        }
    },

} as RouteDataProvider;
