import React, { FC } from 'react';
import {
  Create,
  DateTimeInput,
  maxLength,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';

import { CreatePromocodeDtoTypeEnum } from '../../../api/generated';

export const CreatePromocode: FC = (props: any) => {
  const validatePromocode = [maxLength(4), required()];
  const notify = useNotify();
  const requiredField = required();
  return (
    <Create
      {...props}
      onFailure={(error) => {
        if (
          (error =
            'insert or update on table "promocodes" violates foreign key constraint "FK_a98b7274a3c24733615dd3dbaca"')
        )
          notify('Пользователь с таким id не существует');
        else return error;
      }}
    >
      <SimpleForm>
        <SelectInput
          source={'type'}
          label={'Тип'}
          choices={[
            {
              id: CreatePromocodeDtoTypeEnum.FreeConsultation,
              name: 'Бесплатная консультация',
            },
          ]}
          validate={requiredField}
        />
        <TextInput
          source={'title'}
          label={'Заголовок'}
          validate={requiredField}
        />
        <TextInput source={'code'} label={'Код'} validate={validatePromocode} />
        <NumberInput
          source={'privilege.freeConsultationsCount'}
          label={'Бесплатных консультаций'}
          min={0}
          validate={requiredField}
        />
        <ReferenceInput
          reference={'custom-requests'}
          source={'customRequestId'}
          label={'ID Заявки'}
          allowEmpty
        >
          <SelectInput optionText='id' />
        </ReferenceInput>
        {/* <NumberInput source={"customRequestId"} label={"id заявки"} /> */}

        <DateTimeInput
          source={'expiresIn'}
          label={'Истекает'}
          validate={requiredField}
        />
      </SimpleForm>
    </Create>
  );
};
