import { DeleteParams,
    GetListParams,
    GetManyParams,
    GetOneParams,
    UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';


// @ts-ignore
export const tariffPlansDataProvider = {

    async delete(params: DeleteParams) {
        const id = params.id;
        if (typeof id === 'number') {
            const {data: user} = await api.V1AdminTariffPlansApi.getOneBaseAdminTariffPlansControllerTariffPlan(id);
            const {status} = await api.V1AdminTariffPlansApi.deleteOneBaseAdminTariffPlansControllerTariffPlan(id);
            if (status === 200) {
                return {data: user};
            }
        }
        return Promise.reject({data: params.previousData});
    },

    async getList(params: GetListParams) {
        const query = nestQueryBuilder(params);
        const reqParams: Params = {
            page: params.pagination.page,
            limit: params.pagination.perPage,
            sort: query.getAll('sort'),
            filter: query.getAll('filter'),
        };
        const {data}: any = await api.V1AdminTariffPlansApi.getManyBaseAdminTariffPlansControllerTariffPlan(
            reqParams.fields,
            reqParams.s,
            reqParams.filter,
            reqParams.or,
            reqParams.sort,
            reqParams.join,
            reqParams.limit,
            reqParams.offset,
            reqParams.page,
            reqParams.cache,
            reqParams.options
        );

        return {
            data: data.data,
            total: data.total
        };
    },

    async getMany(params: GetManyParams) {
        const tariffPlans = [];
        for (let i = 0; i < params.ids.length; i++) {
            const id = params.ids[i];
            if (typeof id === 'number') {
               try {
                   const {data: plan} = await api.V1AdminTariffPlansApi.getOneBaseAdminTariffPlansControllerTariffPlan(id);
                   tariffPlans.push(plan);
               }catch (e) {
                   tariffPlans.push({});
               }
            }
        }
        return {data: tariffPlans};
    },

    async getOne(params: GetOneParams) {
        return await api.V1AdminTariffPlansApi.getOneBaseAdminTariffPlansControllerTariffPlan(parseInt(params.id.toString()));
    },

    async create(params: any) {
        const {data} = await api.V1AdminTariffPlansApi.createOneBaseAdminTariffPlansControllerTariffPlan({
            ...params.data,
        });

        return {
            data: data,
        };
    },

    async update(params: UpdateParams) {
        const id = params.data.id;
        if (typeof id === 'number') {
            const {data: user} = await api.V1AdminTariffPlansApi.updateOneBaseAdminTariffPlansControllerTariffPlan(id, {...params.data});
            return {
                data: user,
            }
        }
        return Promise.reject();
    },
} as RouteDataProvider;
