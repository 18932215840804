import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import { ArrayInput, Edit, SimpleForm, SimpleFormIterator,TextInput } from 'react-admin';

export const EditFaq: FC = (props: any) => {
    return (
        <Edit {...props} mutationMode={'pessimistic'}>
            <SimpleForm>
                <ArrayInput source={'faq'}>
                    <SimpleFormIterator
                        addButton={
                            <Button variant="contained" color="primary">Добавить</Button>
                        }
                        removeButton={
                            <Button variant="contained" style={{backgroundColor: 'rgb(243 33 33 / 50%)'}}
                                    color="secondary">Удалить</Button>
                        }>
                        <TextInput source={'question'} label={'Вопрос'} multiline/>
                        <ArrayInput source={'answer'} label={''}>
                            <SimpleFormIterator
                                addButton={
                                    <Button variant="contained" color="primary">Добавить</Button>
                                }
                                removeButton={
                                    <Button variant="contained" style={{backgroundColor: 'rgb(243 33 33 / 50%)'}}
                                            color="secondary">Удалить</Button>
                                }>
                                <TextInput source={''} label={''} multiline/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};