import React, { FC } from 'react';
import {
  DateField,
  ImageField,
  RichTextField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import { AdminNewsStatus, AdminNewsToWhomChoices } from '..';

export const ShowAdminNews: FC = (props: any) => {
  return (
    <Show
      {...props}
      actions={<Actions type={ActionsType.show} />}
      hasList
      hasEdit
      basePath={'admin-news'}
    >
      <SimpleShowLayout>
        <TextField source={'id'} label={'id'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <ImageField source={'imageUrl'} label='Изображение' />
        <RichTextField source={'content'} label={'Сообщение'} />
        <SelectField
          source={'toWhom'}
          choices={AdminNewsToWhomChoices}
          label={'Кому'}
        />
        <TextField source={'numberOfViews'} label={'Кол-во просмотров'} />
        <DateField
          source={'scheduledTime'}
          label={'Запланированное время'}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <SelectField
          source={'status'}
          choices={AdminNewsStatus}
          label={'Статус'}
        />
      </SimpleShowLayout>
    </Show>
  );
};
