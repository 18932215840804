import React, { FC } from 'react';
import {
  BooleanInput,
  Edit,
  FormTab,
  SelectInput,
  TabbedForm,
  TextInput,
} from 'react-admin';

import { BlockUserDtoBlockStatusEnum } from '../../../api/generated';
import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';
import TitleUser from '../../../components/title-user';

export const EditUser: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      hasShow
      hasList
      title={<TitleUser />}
      actions={<Actions type={ActionsType.edit} />}
    >
      <TabbedForm
        toolbar={<EditToolbar />}
        defaultValue={''}
        submitOnEnter={false}
      >
        <FormTab label={'Главная информация'}>
          <TextInput source={'firstName'} label={'Имя'} />
          <TextInput source={'lastName'} label={'Фамилия'} />
          <TextInput source={'email'} label={'Email'} />
          <TextInput source={'phone'} label={'Телефон'} />
          <BooleanInput source={'isExpert'} label={'Эксперт'} />
          <TextInput source={'aboutSelf'} label={'О себе'} />
          <BooleanInput source={'isProfileVerified'} label={'Верифицирован'} />
          <BooleanInput source={'isAdmin'} label={'Админ'} />
          <BooleanInput source={'isApproved'} label={'Утвержден'} />
        </FormTab>
        <FormTab label={'Заблокировать'}>
          <SelectInput
            label={'Статус'}
            defaultChecked
            source={'block'}
            choices={[
              {
                id: BlockUserDtoBlockStatusEnum.Full,
                name: 'Полная блокировка',
              },
              {
                id: BlockUserDtoBlockStatusEnum.Topic,
                name: 'Блокировка в темах',
              },
              {
                id: BlockUserDtoBlockStatusEnum.Partial,
                name: 'Частичная блокировка',
              },
              {
                id: BlockUserDtoBlockStatusEnum.Unblock,
                name: 'Не заблокирован',
              },
            ]}
          />
          <TextInput source={'reasonBlock'} label={'Причина блокировки'} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
