import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import IconContentDelete from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import {
  Button,
  DateTimeInput,
  FormWithRedirect,
  maxLength,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
} from 'react-admin';
import { useForm } from 'react-final-form';

import { CreatePromocodeDtoTypeEnum } from '../../../api/generated';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    '& > div': {
      flexBasis: '48%',
      '@media (max-width: 600px)': {
        flexBasis: '100%',
      },
    },
  },
});

type Props = {
  onChange: () => void;
  id: string;
  promocodeId?: string;
  isRejected?: boolean;
};

function PromocodeQuickCreateButton({
  onChange,
  id,
  promocodeId,
  isRejected = false,
}: Props) {
  const validatePromocode = [maxLength(4), required()];
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading, error }] = useCreate('promocodes');
  const notify = useNotify();
  const classes = useStyles();
  const form = useForm();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleRemove = () => {
    form.change('promocode', null);
  };

  const handleSubmit = async (values: any) => {
    create(
      { payload: { data: values } },
      {
        //@ts-ignore
        onSuccess: ({ data }) => {
          setShowDialog(false);
          form.change('promocode.id', data.id);
          onChange();
        },
        //@ts-ignore
        onFailure: () => {
          notify(error, 'error');
        },
      }
    );
  };

  return (
    <>
      {!isRejected && !promocodeId && (
        <Button onClick={handleClick} label='ra.action.create'>
          <IconContentAdd />
        </Button>
      )}
      {promocodeId && isRejected && (
        <Button onClick={handleRemove} label='ra.action.delete'>
          <IconContentDelete />
        </Button>
      )}
      <Dialog
        className={classes.root}
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label='Create promocode'
      >
        <DialogTitle>Create promocode</DialogTitle>

        <FormWithRedirect
          resource='promocodes'
          initialValues={{ customRequestId: id }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent className={classes.content}>
                <NumberInput
                  source={'customRequestId'}
                  label={'ID заявки'}
                  disabled
                />
                <SelectInput
                  fullWidth
                  source={'type'}
                  label={'Тип'}
                  choices={[
                    {
                      id: CreatePromocodeDtoTypeEnum.FreeConsultation,
                      name: 'Бесплатная консультация',
                    },
                  ]}
                  validate={required()}
                />
                <TextInput
                  source={'title'}
                  label={'Заголовок'}
                  validate={required()}
                  fullWidth
                />

                <TextInput
                  fullWidth
                  source={'code'}
                  label={'Код'}
                  validate={validatePromocode}
                />
                <NumberInput
                  fullWidth
                  source={'privilege.freeConsultationsCount'}
                  label={'Бесплатных консультаций'}
                  min={0}
                  validate={required()}
                />
                <DateTimeInput
                  fullWidth
                  source={'expiresIn'}
                  label={'Истекает'}
                  validate={required()}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label='ra.action.cancel'
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  // pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default PromocodeQuickCreateButton;
