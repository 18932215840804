import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';

import { ThemeContext } from '../App';
import { lightTheme } from './themes';

// eslint-disable-next-line react/display-name
export default (props: LayoutProps) => (
  <ThemeContext.Consumer>
    {({ theme }) => {
      return <Layout {...props} theme={lightTheme} />;
    }}
  </ThemeContext.Consumer>
);
