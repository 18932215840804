import { GetListParams, GetManyParams, GetOneParams } from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const walletsDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['owner'],
    };
    const { data }: any =
      await api.V1AdminWalletsApi.getManyBaseAdminWalletsControllerWallet(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    data.data = data.data.map((item: any) => {
      return item.owner
        ? item
        : {
            ...item,
            owner: { firstName: 'пользователь удален', lastName: '-' },
          };
    });

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const wallets = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: wallet } =
            await api.V1AdminWalletsApi.getOneBaseAdminWalletsControllerWallet(
              id
            );
          wallets.push(wallet);
        } catch (e) {
          wallets.push({});
        }
      }
    }

    return { data: wallets };
  },

  async getOne(params: GetOneParams) {
    return await api.V1AdminWalletsApi.getOneBaseAdminWalletsControllerWallet(
      parseInt(params.id.toString())
    );
  },
} as RouteDataProvider;
