import React, { FC } from 'react';
import {
ReferenceField,
    Show,
    SimpleShowLayout,
    TextField} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import FullNameField from '../../../components/full-name-field';

export const ShowUsersTariffPlans: FC = (props: any) => {

    return (
        <Show
            {...props}
            actions={<Actions noDelete type={ActionsType.show}/>}
            hasList
            hasEdit
            basePath={'user-tariff-plans'}
        >
            <SimpleShowLayout>
                <ReferenceField reference={'users'} source={'id'} label={'Пользователь'} linkType={'show'}>
                    <FullNameField/>
                </ReferenceField>

                <ReferenceField reference={'tariff-plans'} source={'tariffPlanId'} label={'id тарифного плана'}
                                linkType={'show'}>
                    <TextField source={'id'}/>
                </ReferenceField>

                <ReferenceField reference={'tariff-plans'} source={'tariffPlanId'} label={'Процент по тарифному плану'}
                                linkType={'show'}>
                    <TextField source={'commissionPercent'}/>
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    );
};
