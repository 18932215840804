//@ts-ignore

export const downloadCSV = (args: any) => {
  let filename = args.filename || 'export.csv';
  let columns = args.columns || null;
  let columnsNames = args.columnsNames;

  // let csv = Papa.unparse({ data: args.data, fields: columns})

  function arrayToCsv(data: any[], fields: any[], columnsNames: string[]) {
    let arr: any[] = [];
    arr.push(columnsNames || fields);

    data.map((row: any) => {
      let rowArray: any = [];
      fields.map((key) => {
        if (key.includes('.')) {
          let keys = key.split('.');
          if (keys.length === 2) {
            row[keys[0]]
              ? rowArray.push(row[keys[0]][keys[1]])
              : rowArray.push(undefined);
          } else if (keys.length === 3) {
            row[keys[0]] &&
            row[keys[0]][keys[1]] &&
            row[keys[0]][keys[1]][keys[2]]
              ? rowArray.push(row[keys[0]][keys[1]][keys[2]])
              : rowArray.push(undefined);
          }
        } else {
          rowArray.push(row[key]);
        }
      });
      arr.push(rowArray.join());
    });

    return arr.join('\r\n');
  }

  let csv = arrayToCsv(args.data, args.columns, columnsNames);

  if (csv == null) return;

  var blob = new Blob([csv]);

  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
  document.body.removeChild(a);
};
