import dayjs from 'dayjs';
import {
  CreateParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { UpdatePromocodeDto } from '../../api/generated';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const promocodesDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['customRequest'],
    };

    const { data }: any =
      await api.V1AdminPromocodesApi.getManyBaseAdminPromocodesControllerPromocode(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );
    return {
      data: data.data,
      total: data.total,
    };
  },

  async create(params: CreateParams<any>) {
    try {
      const { data } =
        await api.V1AdminPromocodesApi.createOneBaseAdminPromocodesControllerPromocode(
          {
            ...params.data,
            id: params.data.customRequestId,
          }
        );

      return {
        data: data,
      };
    } catch (e: any) {
      if (typeof e.message === 'object') {
        return Promise.reject(e.message[0]);
      }
      return Promise.reject(e.message);
    }
  },

  async getMany(params: GetManyParams) {
    const promocodes = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: promocode } =
            await api.V1AdminPromocodesApi.getOneBaseAdminPromocodesControllerPromocode(
              id
            );
          promocodes.push(promocode);
        } catch (e) {
          promocodes.push({});
        }
      }
    }
    return { data: promocodes };
  },

  async getOne(params: GetOneParams) {
    const query = nestQueryBuilder(params);

    const reqParams: Params = {
      filter: query.getAll('filter'),
      join: ['invoices', 'invoices.invoice', 'customRequest'],
    };

    const { data: promocode } =
      await api.V1AdminPromocodesApi.getOneBaseAdminPromocodesControllerPromocode(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );
    return {
      data: promocode,
    };
  },

  async update(params: UpdateParams) {
    const { data, previousData } = params;
    if (dayjs().isBefore(dayjs(params.data.expiresIn))) {
      params.data = {
        ...data,
        isActive: true,
      };
    }

    const dto: UpdatePromocodeDto = {
      ...params.data,
      customRequestId: data.customRequest?.id,
    };

    if (data?.customRequest?.id === previousData?.customRequest?.id) {
      dto.customRequestId = undefined;
    }

    if (data?.customRequest?.id === null) {
      data.customRequestId = null;
    }
    delete dto.customRequest;

    const id = data.id;

    if (typeof id === 'number') {
      const { data: promocode } =
        await api.V1AdminPromocodesApi.updateOneBaseAdminPromocodesControllerPromocode(
          id,
          {
            ...dto,
          }
        );
      return {
        data: promocode,
      };
    }
    return Promise.reject();
  },

  async delete(params: DeleteParams) {
    const id = params.id;
    if (typeof id === 'number') {
      const { data: promocode } =
        await api.V1AdminPromocodesApi.getOneBaseAdminPromocodesControllerPromocode(
          id
        );
      const { status } =
        await api.V1AdminPromocodesApi.deleteOneBaseAdminPromocodesControllerPromocode(
          id
        );
      if (status === 200) {
        return { data: promocode };
      }
    }
    return Promise.reject({ data: params.previousData });
  },
} as RouteDataProvider;
