import React, { FC, useCallback } from "react";
import {
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  List,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { Payment } from "../../../api/generated";
import Pagination from "../../../components/pagination";
import { downloadCSV } from "../../../utils/download-csv";
import { PaymentOperationTypeChoices, PaymentStatusChoices } from "../index";

// let filters: {
//   [key: string]: string;
// } = {};

// const transformFilterObjectToQueryArray = (filter: {
//   [key: string]: string;
// }) => {
//   let queryFilter: string[] = [];
//   Object.keys(filter).forEach((key) => {
//     queryFilter.push(key + "||$eq||" + filter[key]);
//   });
//   return queryFilter;
// };

const PaymentsFilters = (props: any) => {
  //   filters = props.filterValues;
  return (
    <Filter {...props}>
      <SelectInput
        choices={PaymentOperationTypeChoices}
        label={"Тип"}
        source={"operationType"}
      />
      <DateTimeInput source={"from"} label={"С какой даты"} />
      <DateTimeInput source={"until"} label={"По какую дату"} />
      <TextInput source={"invoiceId"} label={"Счёт"} />

      {/* <TextInput source='payerWallet.owner.firstName' label='Имя плательщика' />
          <TextInput source='payerWallet.owner.lastName' label='Фамилия плательщика' />
  
          <TextInput source='recipientWallet.owner.firstName' label='Имя получателя' />
          <TextInput source='recipientWallet.owner.lastName' label='Фамилия получателя' /> */}
    </Filter>
  );
};

const exporter = async (payments: Payment[]) => {
  downloadCSV({
    filename: `payments.csv`,
    data: payments,
    columns: [
      "id",
      "invoiceId",
      "amount",
      "operationType",
      "status",
      "createdAt",
      "updatedAt",
      "payerWallet.owner.firstName",
      "payerWallet.owner.lastName",
      "recipientWallet.owner.firstName",
      "recipientWallet.owner.lastName",
    ],
    columnsNames: [
      "id",
      "номер счета",
      "сумма",
      "тип операции",
      "статус",
      "дата создания",
      "дата обновления",
      "имя плательщика",
      "фамилия плательщика",
      "имя получателя",
      "фамилия получателя",
    ],
  });
};

export const ListPayments: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve("show");
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<PaymentsFilters />}
      bulkActionButtons={false}
      sort={{field: 'updatedAt', order: 'DESC'}}
      perPage={50}
      exporter={exporter}
      hasShow
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={"id"} label={"id"} />
        {/* <ReferenceField reference={'invoices'} source={'invoiceId'} label={'Счёт'}>
                    <TextField source={'id'}/>
                </ReferenceField> */}
        <TextField source={"invoiceId"} label={"Счёт"} />
        <TextField source={"amount"} label={"Сумма"} />
        <SelectField
          choices={PaymentOperationTypeChoices}
          label={"Тип"}
          source={"operationType"}
        />
        <SelectField
          choices={PaymentStatusChoices}
          label={"Статус"}
          source={"status"}
        />
        <DateField source={"createdAt"} label={"Дата создания"} showTime />
        <DateField source={"updatedAt"} label={"Дата обновления"} showTime />

        <TextField
          source="payerWallet.owner.firstName"
          label="Имя плательщика"
          sortable={false}
        />
        <TextField
          source="payerWallet.owner.lastName"
          label="Фамилия плательщика"
          sortable={false}
        />

        <TextField
          source="recipientWallet.owner.firstName"
          label="Имя получателя"
          sortable={false}
        />
        <TextField
          source="recipientWallet.owner.lastName"
          label="Фамилия получателя"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
