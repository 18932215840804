import get from "lodash.get";
import { FieldProps, useRecordContext } from "react-admin";

const ArrayStringField = (props: FieldProps & {source: string;}) => {
    const record = useRecordContext(props);
    const source = props.source;
    const array = get(record, source);

    if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div/>;
    } else {
    return (
        <ul>
        {array.map((item : string | number) => <li key={item}>{item}</li>)}
        </ul>
    );
    }
};
ArrayStringField.defaultProps = {addLabel: true};

export default ArrayStringField;