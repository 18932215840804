import {
DeleteParams,
    GetListParams,
    GetManyParams,
    GetOneParams, UpdateParams} from 'ra-core';

import { api } from '../../api';
import {
    Contract,
} from '../../api/generated';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';


// @ts-ignore
export const contractProvider = {

    async getList(params: GetListParams) {
        const query = nestQueryBuilder(params);
        const reqParams: Params = {
            page: params.pagination.page,
            limit: params.pagination.perPage,
            sort: query.getAll('sort'),
            filter: query.getAll('filter'),
            join:['owner']
        };
        const {data}: any = await api.V1AdminContractsApi.getManyBaseAdminContractsControllerContract(
            reqParams.fields,
            reqParams.s,
            reqParams.filter,
            reqParams.or,
            reqParams.sort,
            reqParams.join,
            reqParams.limit,
            reqParams.offset,
            reqParams.page,
            reqParams.cache,
            reqParams.options
        );

        return {
            data: data.data,
            total: data.total
        };
    },

    async getMany(params: GetManyParams) {
        const contracts = [];
        for (let i = 0; i < params.ids.length; i++) {
            const id = params.ids[i];
            if (typeof id === 'number') {
                try {
                    const {data: contract} = await api.V1AdminContractsApi.getOneBaseAdminContractsControllerContract(id);
                    contracts.push(contract);
                }catch (e){
                    contracts.push({});
                }
            }
        }
        return {data: contracts};
    },

    async getOne(params: GetOneParams) {
        const reqParams: Params = {};

        const {data: customRequest} = await api.V1AdminContractsApi.getOneBaseAdminContractsControllerContract(
            parseInt(params.id.toString()),
            reqParams.fields,
            reqParams.join
        );

        return {
            data: customRequest
        };
    },

    async update(params: UpdateParams<Contract>) {

        try {
            //@ts-ignore
            const comment = params.data.comment;
            const status = params.data.status;

            const {data: contract} = await api.V1AdminContractsApi.adminContractsControllerProcessOne(
                params.data.id,
                {
                    comment: comment,
                    //@ts-ignore
                    status: status,
                }
            );
            return {
                data: contract,
            };
        } catch (e) {
            return Promise.reject('Не удалось обновить контракты');
        }
    },

    async delete(params: DeleteParams) {
        const id = params.id;
        if (typeof id === 'number') {
            try {
                const {data: contract} = await api.V1AdminContractsApi.getOneBaseAdminContractsControllerContract(id);
                await api.V1AdminContractsApi.deleteOneBaseAdminContractsControllerContract(id);

                return {
                    data: contract
                }
            }catch (e) {
                return Promise.reject({data: params.previousData});
            }
        }
    }

} as RouteDataProvider;
