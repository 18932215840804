import React, { FC, useCallback } from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SelectField,
  TextField,
  TextInput,
} from "react-admin";

import {
  AdminUpdateVerificationRequestDtoStatusEnum,
  VerificationRequest,
} from "../../../api/generated";
import Pagination from "../../../components/pagination";
import { downloadCSV } from "../../../utils/download-csv";

const exporter = async (vereficationRequests: VerificationRequest[]) => {
  downloadCSV({
    filename: `verifications-requests.csv`,
    data: vereficationRequests,
    columns: [
      "id",
      "author.firstName",
      "author.lastName",
      "status",
      "createdAt",
    ],
    columnsNames: ["id", "имя", "фамилия", "статус", "дата созданя"],
  });
};

const VerificationRequestsFilters = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label={"Имя"} source={"author.firstName"} alwaysOn />
      <TextInput label={"Фамилия"} source={"author.lastName"} alwaysOn />
    </Filter>
  );
};

export const ListVerificationRequests: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, "_blank");
      return Promise.resolve("");
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<VerificationRequestsFilters />}
      sort={{ field: "createdAt", order: "DESC" }}
      hasEdit
      hasShow
      perPage={50}
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={"id"} label={"id"} />
        <TextField source={"author.firstName"} label={"Имя"} />
        <TextField source={"author.lastName"} label={"Фамилия"} />
        <SelectField
          source={"status"}
          label={"Статус"}
          choices={[
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Pending,
              name: "На проверке",
            },
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Fulfilled,
              name: "Принят",
            },
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Rejected,
              name: "Отклонен",
            },
          ]}
        />
        <DateField source={"createdAt"} label={"Создан"} />
      </Datagrid>
    </List>
  );
};
