import {
  NewsStatusEnum,
  NewsToWhomEnum,
  UpdateNewsDtoToWhomEnum,
} from '../../api/generated';

export { default as ListAdminNews } from './list-admin-news';
export { default as ShowAdminNews } from './show-admin-news';
export { default as EditAdminNews } from './edit-admin-news';
export { default as CreateAdminNews } from './create-admin-news';

export const AdminNewsToWhomChoices = [
  {
    id: NewsToWhomEnum.All,
    name: 'Всем',
  },
  { id: NewsToWhomEnum.Client, name: 'Пользователям' },
  { id: NewsToWhomEnum.Expert, name: 'Экспертам' },
];

export const UpdateNewsDtoToWhomEnumChoices = [
  {
    id: UpdateNewsDtoToWhomEnum.All,
    name: 'Всем',
  },
  { id: UpdateNewsDtoToWhomEnum.Client, name: 'Пользователям' },
  { id: UpdateNewsDtoToWhomEnum.Expert, name: 'Экспертам' },
];

export const AdminNewsStatus = [
  { id: NewsStatusEnum.Planned, name: 'Запланировано' },
  { id: NewsStatusEnum.Published, name: 'Опубликовано' },
];
