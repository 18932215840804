import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import { CustomRequestStatusEnum } from '../../../api/generated';
import PromocodeQuickCreateButton from './promocode-create-btn';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

const spySubscription = { values: true };

const PromocodeReferenceInput = (props: any) => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const { values, dirty } = useFormState({ subscription: spySubscription });

  const handleChange = useCallback(() => setVersion(version + 1), [version]);
  const isRejected = values.status !== CustomRequestStatusEnum.Fulfilled;
  return (
    <div className={classes.root}>
      <ReferenceInput key={version} {...props}>
        <SelectInput
          optionText='title'
          source='id'
          allowEmpty
          resettable
          disabled={isRejected}
        />
      </ReferenceInput>

      <PromocodeQuickCreateButton
        onChange={handleChange}
        id={values.id}
        promocodeId={values.promocode?.id}
        isRejected={isRejected}
      />

      {/* {!!values.id && <PromocodeQuickPreviewButton id={values.id} />} */}
    </div>
  );
};

export default PromocodeReferenceInput;
