import { useRecordContext } from 'ra-core';
import React, { FC, useCallback } from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  List,
  NumberInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import { Consultation, ConsultationStatusEnum } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import StarRatingField from '../../../consultation/StarRatingField';
import { downloadCSV } from '../../../utils/download-csv';

const consultationsStatus = [
  { id: ConsultationStatusEnum.Active, name: 'В процессе' },
  { id: ConsultationStatusEnum.Ended, name: 'Закончилоась' },
  { id: ConsultationStatusEnum.EndedByClient, name: 'Закончил клиент' },
  { id: ConsultationStatusEnum.EndedByExpert, name: 'Закончил эксперт' },
  { id: ConsultationStatusEnum.PendingStart, name: 'Ожидает начала' },
  { id: ConsultationStatusEnum.PendingPayment, name: 'Ожидает оплаты' },
  { id: ConsultationStatusEnum.MessagesLimitExceeded, name: 'Лимит сообщений' },
  { id: ConsultationStatusEnum.TimeLimitExceeded, name: 'Лимит времени' },
  { id: ConsultationStatusEnum.PaymentExpired, name: 'Просроченный платеж' },
  {
    id: ConsultationStatusEnum.ClientRejectChatOffer,
    name: 'Клиент отклонил платеж',
  },
];

const exporter = async (consultations: Consultation[]) => {
  downloadCSV({
    filename: `consultation.csv`,
    data: consultations,
    columns: [
      'createdAt',
      'expiresIn',
      'client.firstName',
      'client.lastName',
      'expert.firstName',
      'expert.lastName',
      'rating',
      'isFree',
      'id',
      'amount',
      'isService',
    ],
    columnsNames: [
      'дата начала',
      'дата окончания',
      'имя клиента',
      'фамилия клиента',
      'имя эксперта',
      'фамилия экперта',
      'рейтинг консультации',
      'оплата жетоном',
      'id',
      'стоимость',
      'услуга',
    ],
  });
};

const ConsultationsFilters = (props: any) => (
  <Filter {...props}>
    <NumberInput source={'id'} label={'id'} />
    <TextInput source={'expert.firstName'} label={'Имя эксперта'} />
    <TextInput source={'expert.lastName'} label={'Фамилия эксперта'} />
    <TextInput source={'client.firstName'} label={'Имя клиента'} />
    <TextInput source={'client.lastName'} label={'Фамилия клиента'} />
    <DateTimeInput source={'from'} label={'С какой даты'} />
    <DateTimeInput source={'until'} label={'По какую дату'} />
    <SelectInput
      label={'Статус'}
      choices={consultationsStatus}
      source={'status'}
    />
    <BooleanInput label={'Услуга'} source={'isService'} />
  </Filter>
);

const ConsultationAmountField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const totalAmount = record.invoices.reduce((acc: any, v: any) => {
    if (v.invoice) {
      return acc + v?.invoice?.amount;
    }
    return acc;
  }, 0);
  return <span>{totalAmount}</span>;
};

export const ListConsultations: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, '_blank');
      return Promise.resolve('');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      bulkActionButtons={false}
      exporter={exporter}
      perPage={50}
      sort={{ field: 'completedAt', order: 'DESC' }}
      filters={<ConsultationsFilters />}
    >
      <Datagrid rowClick={rowClick()}>
        <DateField source={'createdAt'} label={'Начало'} />
        <DateField source={'completedAt'} label={'Конец'} />
        <TextField source={'client.firstName'} label={'Имя клиента'} />
        <TextField source={'client.lastName'} label={'Фамилия клиента'} />
        <TextField source={'expert.firstName'} label={'Имя эксперта'} />
        <TextField source={'expert.lastName'} label={'Фамилия эксперта'} />
        <StarRatingField
          source={'rating'}
          label={'Оценка клиента'}
          sortable={false}
        />
        <BooleanField
          source={'invoices[0].invoice.isFree'}
          label={'Оплачено жетоном'}
          sortable={false}
        />
        <TextField source={'id'} label={'id'} />
        <SelectField
          label={'Статус'}
          choices={consultationsStatus}
          source={'status'}
        />
        <BooleanField label={'Услуга'} source={'isService'} />
        <ConsultationAmountField label={'Стоимость'} />
      </Datagrid>
    </List>
  );
};
