import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

import { ConsultationStatusEnum } from '../../../api/generated';
import { EvaluatedField } from '../../../components/evaluated-field/evaluated-field';
import FullNameField from '../../../components/full-name-field';
import StarRatingField from '../../../consultation/StarRatingField';
import { InvoicesStatusChoices } from '../../invoices';
import { ConsultationInvoicesTypeChoices } from '../index';

const consultationsStatus = [
  { id: ConsultationStatusEnum.Active, name: 'В процессе' },
  { id: ConsultationStatusEnum.Ended, name: 'Закончилоась' },
  { id: ConsultationStatusEnum.EndedByClient, name: 'Закончил клиент' },
  { id: ConsultationStatusEnum.EndedByExpert, name: 'Закончил эксперт' },
  { id: ConsultationStatusEnum.PendingStart, name: 'Ожидает начала' },
  { id: ConsultationStatusEnum.PendingPayment, name: 'Ожидает оплаты' },
  { id: ConsultationStatusEnum.MessagesLimitExceeded, name: 'Лимит сообщений' },
  { id: ConsultationStatusEnum.TimeLimitExceeded, name: 'Лимит времени' },
];

export const ShowConsultations: FC = (props: any) => {
  const show = useShowController(props);

  return (
    <Show {...props}>
      <>
        <Accordion>
          <AccordionSummary>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SimpleShowLayout>
              <TextField source={'id'} label={'id'} />
              <DateField source={'createdAt'} label={'Начало'} showTime />
              <DateField source={'completedAt'} label={'Конец'} showTime />

              <ReferenceField
                label={'Клиент'}
                reference={'users'}
                source={'clientId'}
              >
                <FullNameField />
              </ReferenceField>

              <ReferenceField
                label={'Эксперт'}
                reference={'users'}
                source={'expertId'}
              >
                <FullNameField />
              </ReferenceField>

              <SelectField
                label={'Статус'}
                choices={consultationsStatus}
                source={'status'}
              />

              <ArrayField source={'reviews'} label={'Оценки'}>
                <Datagrid>
                  <ReferenceField
                    reference={'users'}
                    source={'authorId'}
                    label={'Оценивающий'}
                    linkType={'show'}
                  >
                    <FullNameField />
                  </ReferenceField>
                  {/*<ReferenceField reference={'users'} source={'clientId'} label={'Оцениваемый'} linkType={'show'}>*/}
                  {/*    <FullNameField/>*/}
                  {/*</ReferenceField>*/}
                  <EvaluatedField label={'Оцениваемый'} />
                  <StarRatingField label={'Рейтинг'} />
                  {/* <TextField source={'rating'} label={'Рейтинг'} /> */}
                  <TextField source={'feedback'} label={'Комментарий'} />
                  <DateField
                    source={'createdAt'}
                    label={'Дата создания'}
                    showTime
                  />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Оплаты</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SimpleShowLayout>
              <DateField source={'createdAt'} label={'Начало'} />

              <ArrayField source={'invoices'} label={''}>
                <Datagrid>
                  <ReferenceField
                    reference={'invoices'}
                    source={'invoiceId'}
                    linkType={'show'}
                    label={'id счета'}
                  >
                    <TextField source={'id'} label={'id счета'} />
                  </ReferenceField>
                  <TextField source={'invoice.amount'} label={'Сумма'} />
                  <SelectField
                    choices={InvoicesStatusChoices}
                    source={'invoice.status'}
                    label={'Статус'}
                  />
                  <SelectField
                    choices={ConsultationInvoicesTypeChoices}
                    source={'type'}
                    label={'Тип платежа'}
                  />
                  <DateField
                    source={'createdAt'}
                    label={'Дата создания'}
                    showTime
                  />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Консультация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SimpleShowLayout>
              <ArrayField source={'messages'} label={''}>
                <Datagrid>
                  <DateField
                    source={'createdAt'}
                    label={'Дата'}
                    options={{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    }}
                  />
                  <ReferenceField
                    reference={'users'}
                    source={'authorId'}
                    label={'Пользователь'}
                  >
                    <FullNameField />
                  </ReferenceField>
                  <TextField source={'content'} label={'Сообщение'} />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>
      </>
    </Show>
  );
};
