import { GetListParams, GetManyParams, GetOneParams } from "ra-core";

import { api } from "../../api";
import { Params } from "../../types";
import { nestQueryBuilder } from "../../utils/queryBuilder";
import { RouteDataProvider } from "../types";

// @ts-ignore
export const consultationsDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll("sort"),
      filter: query.getAll("filter"),
      join: ["invoices", "invoices.invoice", "client", "expert"],
    };
    const { data }: any =
      await api.V1AdminConsultationsApi.getManyBaseAdminConsultationsControllerConsultation(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    data.data = data.data.map((consultation: any) => {
      if (typeof consultation.reviews === "object") {
        Object.keys(consultation.reviews).map((key: string) => {
          if (
            consultation.reviews[key].authorId ===
            consultation.reviews[key].clientId
          ) {
            consultation.rating = consultation.reviews[key].rating;
          }
        });
      }
      return consultation;
    });

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const consultations = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === "number") {
        try {
          const { data: consultation } =
            await api.V1AdminConsultationsApi.getOneBaseAdminConsultationsControllerConsultation(
              id
            );
          consultations.push(consultation);
        } catch (e) {
          consultations.push({});
        }
      }
    }
    return { data: consultations };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {
      join: ["invoices", "invoices.invoice", "messages"],
    };

    const { data: consultation } =
      await api.V1AdminConsultationsApi.getOneBaseAdminConsultationsControllerConsultation(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );

    return {
      data: consultation,
    };
  },
} as RouteDataProvider;
