export const getTimeToBecomeAnExpert = (
  isExpert: boolean,
  timeToBecomeAnExpert: string
): string | null => {
  if (isExpert) {
    if (!!timeToBecomeAnExpert) {
      return timeToBecomeAnExpert;
    } else return new Date().toISOString();
  } else return null;
};
