import {
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { uploadFile } from '../../utils/uploadFile';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const adminNewsProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
    };
    const { data }: any =
      await api.V1AdminNewsApi.getManyBaseAdminNewsConrollerNews(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const customRequests = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: customRequest } =
            await api.V1AdminCustomRequestsApi.getOneBaseAdminCustomRequestsControllerCustomRequest(
              id
            );
          customRequests.push(customRequest);
        } catch (e) {
          customRequests.push({});
        }
      }
    }
    return { data: customRequests };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {};

    const { data: customRequest } =
      await api.V1AdminNewsApi.getOneBaseAdminNewsConrollerNews(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );

    return {
      data: customRequest,
    };
  },

  async update(params: UpdateParams<any>) {
    const file = params?.data?.imageUrl;
    let image;
    if (file) {
      try {
        if (file.rawFile) {
          image = await uploadFile(file.rawFile);
        } else {
          image = params.data.imageUrl;
        }
      } catch (e) {
        return Promise.reject('Не удалось загрузить картинку');
      }
    }
    try {
      const { data: customRequest } =
        await api.V1AdminNewsApi.updateOneBaseAdminNewsConrollerNews(
          parseInt(params.id as string),
          {
            content: params.data.content,
            toWhom: params.data.toWhom,
            scheduledTime: params.data.scheduledTime,
            imageUrl: image,
          }
        );
      return {
        data: customRequest,
      };
    } catch (e: any) {
      const errMessage = Array.isArray(e?.message) ? e?.message[0] : e?.message;
      return Promise.reject(errMessage || 'Не удалось обновить новость');
    }
  },

  async create(params: any) {
    const file = params?.data?.imageUrl;
    let image;
    if (file) {
      try {
        if (file.rawFile) {
          image = await uploadFile(file.rawFile);
        } else {
          image = params.data.imageUrl;
        }
      } catch (e) {
        return Promise.reject('Не удалось загрузить картинку');
      }
    }

    try {
      const { data } =
        await api.V1AdminNewsApi.createOneBaseAdminNewsConrollerNews({
          ...params.data,
          imageUrl: image,
        });
      return {
        data: data,
      };
    } catch (e) {
      return Promise.reject('Не удалось создать новость');
    }
  },

  async delete(params: DeleteParams) {
    const id = params.id;
    if (typeof id === 'number') {
      const { data: customRequest } =
        await api.V1AdminNewsApi.getOneBaseAdminNewsConrollerNews(id);
      const { status } =
        await api.V1AdminNewsApi.deleteOneBaseAdminNewsConrollerNews(id);
      if (status === 200) {
        return { data: customRequest };
      }
    }
    return Promise.reject({ data: params.previousData });
  },
} as RouteDataProvider;
