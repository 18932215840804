import {
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { ExpertService } from '../../api/generated';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const servicesDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['user'],
    };
    const { data }: any =
      await api.V1AdminExpertServicesApi.getManyBaseAdminExpertServicesControllerExpertService(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    data.data = data.data.map((consultation: any) => {
      if (typeof consultation.reviews === 'object') {
        Object.keys(consultation.reviews).map((key: string) => {
          if (
            consultation.reviews[key].authorId ===
            consultation.reviews[key].clientId
          ) {
            consultation.rating = consultation.reviews[key].rating;
          }
        });
      }
      return consultation;
    });

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    let services: ExpertService[] = [];
    try {
      const { data } =
        await api.V1AdminExpertServicesApi.getManyBaseAdminExpertServicesControllerExpertService();
      services = data.data;
    } catch (e) {
      console.log(e);
    }

    return { data: services };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {
      join: ['users'],
    };

    const { data: service } =
      await api.V1AdminExpertServicesApi.getOneBaseAdminExpertServicesControllerExpertService(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );

    return {
      data: service,
    };
  },
  async update(params: UpdateParams<ExpertService>) {
    try {
      console.log({ ...params.previousData, ...params.data });

      const { data } =
        await api.V1AdminExpertServicesApi.updateOneBaseAdminExpertServicesControllerExpertService(
          parseInt(params.id.toString()),
          { ...params.previousData, ...params.data }
        );

      return {
        data,
      };
    } catch (e) {
      console.log(e);
    }
  },

  async delete(params: DeleteParams) {
    const id = params?.id;
    try {
      const { data } =
        await api.V1AdminExpertServicesApi.deleteOneBaseAdminExpertServicesControllerExpertService(
          parseInt(id.toString())
        );

      return { data };
    } catch (e) {
      console.log(e);
    }
  },
} as RouteDataProvider;
