import { ReviewComplaintDecisionEnum } from "../../api/generated";

export { default as ListComplaints } from "./list-complaints";
export { default as ShowComplaint } from "./show-complaints";
export { default as EditComplaint } from "./edit-complaints";

export const complaintDecision = [
  { id: ReviewComplaintDecisionEnum.Apology, name: "Извинение" },
  { id: ReviewComplaintDecisionEnum.NoDecision, name: "Нет решения" },
  { id: ReviewComplaintDecisionEnum.Refund, name: "Возврат" },
  { id: ReviewComplaintDecisionEnum.Refusal, name: "Отказ" },
  {
    id: ReviewComplaintDecisionEnum.RefundAndBlocking,
    name: "Возврат и блокировка",
  },
  {
    id: ReviewComplaintDecisionEnum.RefundAndWarning,
    name: "Возврат и предупреждение",
  },
];
