import { GetListParams, GetManyParams, GetOneParams } from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const invoicesDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['author', 'consultationInvoice', 'extraService'],
    };
    const { data }: any =
      await api.V1AdminInvoicesApi.getManyBaseAdminInvoicesControllerInvoice(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    data.data = data.data.map((invoice: any) => {
      if (invoice.consultationInvoice === null) {
        invoice.purposeOfTransfer = 'доп услуга';
      } else if (
        invoice.extraService === null &&
        invoice.consultationInvoice.type
      ) {
        invoice.purposeOfTransfer =
          invoice.consultationInvoice.type === 'initiation'
            ? 'консультация'
            : 'продление';
      } else {
        invoice.purposeOfTransfer = '-';
      }

      return invoice;
    });
    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const invoices = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: invoice } =
            await api.V1AdminInvoicesApi.getOneBaseAdminInvoicesControllerInvoice(
              id
            );
          invoices.push(invoice);
        } catch (e) {
          invoices.push({});
        }
        const { data: invoice } =
          await api.V1AdminInvoicesApi.getOneBaseAdminInvoicesControllerInvoice(
            id
          );
        invoices.push(invoice);
      }
    }
    return { data: invoices };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {
      join: ['author'],
    };

    const { data: invoice } =
      await api.V1AdminInvoicesApi.getOneBaseAdminInvoicesControllerInvoice(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );

    return {
      data: invoice,
    };
  },
} as RouteDataProvider;
