export const cookie = {
    set(name: string, value: string) {
        document.cookie = `${name}=${value}`;
    },
    get(name: string){
        const cookies = document.cookie.split(';');
        const res = cookies.find(c => c.startsWith(name + '='));
        if (res) {
            return res.substring(res.indexOf('=') + 1);
        }
    },
    delete(name: string){
        const value = this.get(name);
        this.set(name, `${value};expires=Thu, 01 Jan 1970 00:00:00 GMT`)
    }
};