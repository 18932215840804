import { Record } from "ra-core";
import React, { FC, useCallback } from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  ImageField,
  List,
  SelectField,
  TextField,
  TextInput,
} from "react-admin";

import {
  ApproveContentChangeRequestDtoStatusEnum,
  ContentChangeRequest,
} from "../../../api/generated";
import Pagination from "../../../components/pagination";
import { downloadCSV } from "../../../utils/download-csv";

const cutLongText = (text: string | undefined) => {
  if (text && text.length > 50) {
    return text.substr(0, 47) + "...";
  }
  return text;
};

const exporter = async (content: ContentChangeRequest[]) => {
  downloadCSV({
    filename: `content-change.csv`,
    data: content,
    columns: [
      "id",
      "status",
      "expert.firstName",
      "expert.lastName",
      "aboutSelf",
      "avatarUrl",
      "updatedAt",
    ],
    columnsNames: [
      "id",
      "статус",
      "имя",
      "фамилия",
      "о себе",
      "ссылка на аватар",
      "дата обновления",
    ],
  });
};

const ContentChangeRequestsFilters = (props: any) => (
  <Filter {...props}>
    <TextInput source={"expert.firstName"} label={"Имя"} />
    <TextInput source={"expert.lastName"} label={"Фамилия"} />
  </Filter>
);

export const ListContentChangeRequest: FC = (props: any) => {
  const rowClick = useCallback(() => (id: any, basePath: any, record: any) => {
    const href = `${window.location.origin}#${basePath}/${id}/show`;
    window.open(href, '_blank');
    return Promise.resolve('');
}, []);

  return (
    <List
      {...props}
      pagination={<Pagination />}
      perPage={50}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      filters={<ContentChangeRequestsFilters />}
      hasShow
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={"id"} label={"id"} />
        <SelectField
          source={"status"}
          label={"Статус"}
          choices={[
            {
              id: ApproveContentChangeRequestDtoStatusEnum.Pending,
              name: "На проверке",
            },
            {
              id: ApproveContentChangeRequestDtoStatusEnum.Fulfilled,
              name: "Подтвержден",
            },
            {
              id: ApproveContentChangeRequestDtoStatusEnum.Rejected,
              name: "Отклонен",
            },
          ]}
        />
        <TextField source={"expert.firstName"} label={"Имя"} />
        <TextField source={"expert.lastName"} label={"Фамилия"} />
        <FunctionField
          source={"aboutSelf"}
          label={"О себе"}
          render={(record: Record | undefined) =>
            cutLongText(record?.aboutSelf)
          }
        />
        <ImageField source={"avatarUrl"} label={"Аватар"} />
        <DateField
          source={"updatedAt"}
          label={"Дата обновления"}
          options={{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }}
        />
      </Datagrid>
    </List>
  );
};
