import dayjs from 'dayjs';
import { GetListParams } from 'ra-core';

type BaseQuery = {
  join: string[];
  [key: string]: any;
} & GetListParams;

type Query = Partial<BaseQuery>;

const getFilterValue = (value: unknown) => {
  if (value === null) {
    return '$isnull';
  } else return '$eq';
};

export const nestQueryBuilder = (query: Query) => {
  const nestQuery = new URLSearchParams();

  if (query.pagination) {
    const { page, perPage } = query.pagination;
    nestQuery.append('page', `${page}`);
    nestQuery.append('limit', `${perPage}`);
  }

  if (query.sort) {
    const { field, order } = query.sort;
    const sort = `${field},${order}`;
    nestQuery.append('sort', sort);
  }

  if (query.filter) {
    const filter = query.filter;
    const filterEntries = Object.entries(filter);

    let filterType: string;
    filterEntries.forEach(([key, value]) => {
      if (key.startsWith('from') || key.startsWith('until')) {
        // filter by created date
        const isFrom = !!filter.from;
        const isUntil = !!filter.until;
        if (isUntil && isFrom) {
          nestQuery.append(
            'filter',
            `createdAt||$between||${filter.from},${filter.until}`
          );
        }
        if (isFrom) {
          nestQuery.append('filter', `createdAt||$gte||${filter.from}`);
        }
        return;
      }
      //filter by day last exist time
      if (key.startsWith('dayExistsFrom') || key.startsWith('dayExistsUntil')) {
        const isFromDayExists = !!filter.dayExistsFrom;
        const isUntilDayExists = !!filter.dayExistsUntil;
        const dateFromDayExists = dayjs().subtract(
          filter.dayExistsFrom,
          'days'
        );
        const dateUntilDayExists = dayjs().subtract(
          filter.dayExistsUntil,
          'days'
        );
        if (isFromDayExists) {
          nestQuery.append(
            'filter',
            `lastExitTime||$lte||${dateFromDayExists}`
          );
        }
        if (isUntilDayExists) {
          nestQuery.append(
            'filter',
            `lastExitTime||$gte||${dateUntilDayExists}`
          );
        }
        return;
      }

      //filter by totalEarned expert
      if (
        key.startsWith('totalEarnedFrom') ||
        key.startsWith('totalEarnedUntil')
      ) {
        const isFromDayExists = !!filter.totalEarnedFrom;
        const isUntilDayExists = !!filter.totalEarnedUntil;
        if (isFromDayExists) {
          nestQuery.append(
            'filter',
            `totalEarned||$gte||${filter.totalEarnedFrom}`
          );
        }
        if (isUntilDayExists) {
          nestQuery.append(
            'filter',
            `totalEarned||$lte||${filter.totalEarnedUntil}`
          );
        }
        return;
      }

      if (
        key.startsWith('is') ||
        key === 'id' ||
        key.toLowerCase().includes('id') ||
        key === 'status' ||
        key
      ) {
        filterType = getFilterValue(value);
      } else {
        filterType = '$startsL';
      }

      if (key.includes('promocodeId')) {
        nestQuery.append('filter', `promocodeId||${filterType}`);
      }

      if (key.includes('customRequest.id')) {
        nestQuery.append('filter', `customRequest.id||${filterType}`);
      }

      const exceptionsArray = [
        'operationType',
        'success',
        'type',
        'ownerId',
        'tariffPlan',
        'expertRejection',
      ];

      if (exceptionsArray.indexOf(key) !== -1) {
        filterType = '$eq';
      }
      if (typeof filter[key] === 'object' && filter[key] !== null) {
        Object.keys(filter[key]).forEach((filterKey: any) => {
          if (typeof filter[key][filterKey] === 'object') {
            Object.keys(filter[key][filterKey]).forEach(
              (secondLevelKey: any) => {
                nestQuery.append(
                  'filter',
                  `${key}.${filterKey}.${secondLevelKey}||${filterType}||${filter[key][filterKey][secondLevelKey]}`
                );
              }
            );
          } else {
            nestQuery.append(
              'filter',
              `${key}.${filterKey}||${filterType}||${filter[key][filterKey]}`
            );
          }
        });
      } else {
        nestQuery.append('filter', `${key}||${filterType}||${filter[key]}`);
      }
    });
  }

  if (query.join) {
    query.join.forEach((joinField) => {
      nestQuery.append('join', joinField);
    });
  }

  return nestQuery;
};
