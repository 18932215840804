import React, { FC, useCallback } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  SelectField,
  TextField,
} from 'react-admin';

import {
  ContentChangeRequest,
  ProcessPortfolioDtoStatusEnum,
} from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';

const exporter = async (content: ContentChangeRequest[]) => {
  downloadCSV({
    filename: `content-change.csv`,
    data: content,
    columns: [
      'id',
      'status',
      'expertId',
      'imageUrls[0]',
      'imageUrls[1]',
      'imageUrls[2]',
      'imageUrls[3]',
      'imageUrls[4]',
      'imageUrls[5]',
      'imageUrls[6]',
      'imageUrls[7]',
      'imageUrls[8]',
      'imageUrls[9]',
      'updatedAt',
    ],
    columnsNames: [
      'id',
      'статус',
      'id эксперта',
      'ссылка на фото 1',
      'ссылка на фото 2',
      'ссылка на фото 3',
      'ссылка на фото 4',
      'ссылка на фото 5',
      'ссылка на фото 6',
      'ссылка на фото 7',
      'ссылка на фото 8',
      'ссылка на фото 9',
      'ссылка на фото 10',
      'дата обновления',
    ],
  });
};

export const ListPortfolio: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, '_blank');
      return Promise.resolve('');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      perPage={50}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      hasShow
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={'id'} label={'id'} />
        <DateField
          source={'updatedAt'}
          label={'Дата обновления'}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }}
        />

        <TextField source={'expertId'} label={'ID эксперта'} />
        <FunctionField
          render={(record: any) => {
            return (
              <div style={{ display: 'flex', gap: 10 }}>
                {record?.imageUrls.map((image: string) => (
                  <img
                    key={image}
                    src={image}
                    style={{ height: 30, maxWidth: 30, objectFit: 'cover' }}
                  />
                ))}
              </div>
            );
          }}
          label={'Контент'}
        />
        <SelectField
          source={'status'}
          label={'Статус'}
          choices={[
            {
              id: ProcessPortfolioDtoStatusEnum.InReview,

              name: 'На проверке',
            },
            {
              id: ProcessPortfolioDtoStatusEnum.Confirmed,

              name: 'Подтвержден',
            },
            {
              id: ProcessPortfolioDtoStatusEnum.Rejected,

              name: 'Отклонен',
            },
          ]}
        />
      </Datagrid>
    </List>
  );
};
