import React, { FC } from 'react';
import {
  DateField,
  DateTimeInput,
  Edit,
  ImageInput,
  required,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  Toolbar,
  useNotify,
} from 'react-admin';

import Actions, { ActionsType } from '../../../components/actions';
import { ContentInput } from '../../../components/content-input';
import { PreviewImage } from '../../../components/preview-image';
import { AdminNewsStatus, AdminNewsToWhomChoices } from '../index';

export const EditAdminNews: FC = (props: any) => {
  const requiredField = required();
  const notify = useNotify();
  const PostEditToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      onSuccess={() => notify('Новость обновлена')}
      hasShow
      hasList
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<PostEditToolbar />}>
        <TextField source={'id'} label={'id'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <ImageInput
          source={'imageUrl'}
          label={'Изображение'}
          resource={'imageUrl'}
          multiple={false}
          accept={'image/jpeg,image/png'}
          maxSize={5242880}
        >
          <PreviewImage source='src' />
        </ImageInput>
        <ContentInput
          label={'Сообщение'}
          source='content'
          validate={requiredField}
        />
        <SelectInput
          source={'toWhom'}
          label={'Кому'}
          choices={AdminNewsToWhomChoices}
          validate={requiredField}
        />
        <TextField source={'numberOfViews'} label={'Кол-во просмотров'} />
        <DateTimeInput
          source={'scheduledTime'}
          label={'Запланированное время'}
        />
        <SelectField
          source={'status'}
          choices={AdminNewsStatus}
          label={'Статус'}
        />
      </SimpleForm>
    </Edit>
  );
};
