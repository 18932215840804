import { makeStyles } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import { GetManyUserResponseDto } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';

const useStyles = makeStyles({
  avatar: { '& > img': { maxHeight: 32, maxWidth: 32, borderRadius: '50%' } },
});

const UsersTariffPlansFilters = (props: any) => (
  <Filter {...props}>
    <TextInput source={'firstName'} label={'Имя'}/>
    <TextInput source={'lastName'} label={'Фамилия'}/>
    <TextInput source={'tariffPlan.commissionPercent'} label={'% по тарифному плану'}/>
  </Filter>
);

const exporter = async (usersTariffPlans: GetManyUserResponseDto[]) => {        
 
  downloadCSV({ 
      filename: `user-tariff-plans.csv`,
      data: usersTariffPlans,
      columns: [
        'firstName',
        'lastName',
        'tariffPlan.commissionPercent'
      ], columnsNames: [
        "имя",
        "фамилия",
        "процент по тарифному плану",
      ]
    })
};

export const ListUsersTariffPlans: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve('show');
    },
    []
  );

  const classNames = useStyles();

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<UsersTariffPlansFilters />}
      hasShow
      perPage={50}
      hasEdit
      exporter={exporter}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={rowClick()} hasBulkActions={false}>
        <TextField source={'firstName'} label={'Имя'}/>
        <TextField source={'lastName'} label={'Фамилия'}/>
        <TextField source={'tariffPlan.commissionPercent'} label={'% по тарифному плану'}/>
      </Datagrid>
    </List>
  );
};
