import { useNotify, useRedirect } from 'ra-core';
import React, { FC, useState } from 'react';
import {
    BooleanField,
    Button,
    Confirm,
    DateField,
    Edit,
    ReferenceField,
    SelectField,
    SimpleShowLayout,
    TextField} from 'react-admin';

import { api } from '../../../api';
import FullNameField from '../../../components/full-name-field';
import { InvoicesStatusChoices } from '../index';

export const EditInvoices: FC = (props: any) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        setIsOpen(s => !s);
    };

    const onCancelPayment = async () => {
        const id = parseInt(props.id);
        try{
            await api.V1AdminInvoicesApi.adminInvoicesControllerCancelOne(id);
            redirect('/invoices', 'list');
        }catch (e){
            notify('Не удалось отменить счет', 'error');
        }
        toggleIsOpen();
    };

    return(
        <Edit {...props}>
            <SimpleShowLayout>
                <TextField source={'id'} label={'id'}/>
                <TextField source={'amount'} label={'Сумма'}/>
                <ReferenceField reference={'users'} source={'authorId'}>
                    <FullNameField/>
                </ReferenceField>
                <SelectField choices={InvoicesStatusChoices} source={'status'} label={'Статус'}/>
                <BooleanField source={'isFree'} label={'Бесплатно'}/>
                <DateField source={'createdAt'} label={'Дата создания'}/>
                <DateField source={'expiresIn'} label={'Истекает'}/>
                <DateField source={'updatedAt'} label={'Дата обновления'}/>
                <Confirm
                    isOpen={isOpen}
                    onConfirm={onCancelPayment}
                    onClose={toggleIsOpen}
                    content={'Вы уверены что, хотите отклонить счет?'}
                    title={'Необходимо подтверждение'}
                    confirm={'Подтвердить'}
                    cancel={'Отмена'}
                />
                <Button label={'Отклонить счет'} onClick={toggleIsOpen} variant={'contained'} style={{backgroundColor: 'indianred'}}/>
            </SimpleShowLayout>
        </Edit>
    );
};
