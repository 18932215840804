import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  FunctionField,
  Labeled,
  NumberField,
  ReferenceField,
  SelectField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  UrlField,
  useRedirect,
  useShowController,
} from 'react-admin';

import {
  BlockUserDtoBlockStatusEnum,
  ExpertStepDtoStepEnum,
} from '../../../api/generated';
import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import BigImageField from '../../../components/big-image-field/big-image-field';
import { stepsTextMapping } from '../../../components/experts-steps-field/experts-steps-field';
import TitleUser from '../../../components/title-user';
import { userProfileUrl } from '../../../constants';

const ConsultationField: FC = (props: any) => {
  const redirect = useRedirect();
  const onClick = () => {
    redirect('show', '/consultations', props.record.id);
  };
  return (
    <Labeled label={'id консультации'}>
      <>
        <span>{props.record.id}</span>
        <ShowButton
          id={props.record.id}
          onClick={onClick}
          basePath={'/consultations'}
          resource={'consultations'}
          label={'Показать'}
        />
      </>
    </Labeled>
  );
};

export const ShowUser: FC = (props: any) => {
  const { record } = useShowController(props);

  return (
    <Show
      {...props}
      title={<TitleUser />}
      actions={<Actions type={ActionsType.show} />}
      hasList
      hasEdit
      basePath={'users'}
    >
      <>
        <Accordion>
          <AccordionSummary>
            <Typography>Данные пользователя</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div style={{ position: 'relative' }}>
              <SimpleShowLayout>
                <TextField source={'firstName'} label={'Имя'} />
                <TextField source={'lastName'} label={'Фамилия'} />
                {record?.isExpert && (
                  <UrlField
                    label={'Ссылка на профиль эксперта'}
                    source={'firstName'}
                    target={'_blank'}
                    href={`${userProfileUrl}${record.id}`}
                  />
                )}
                <TextField source={'phone'} label={'Телефон'} />
                <EmailField source={'email'} label={'Email'} />
                <BigImageField source={'avatarUrl'} label={'Аватар'} />
                <DateField source={'birthDate'} label={'Дата рождения'} />
              </SimpleShowLayout>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>
            <Typography>Системная информация</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <SimpleShowLayout>
              <DateField
                source={'createdAt'}
                label={'Дата регистрации'}
                showTime
              />
              <DateField
                source={'lastExitTime'}
                label={'Дата последнего визита'}
                showTime
              />
              <BooleanField source={'isAdmin'} label={'Админ'} />
              <SelectField
                source={'block'}
                label={'Статус аккаунта'}
                choices={[
                  {
                    id: BlockUserDtoBlockStatusEnum.Full,
                    name: 'Полная блокировка',
                  },
                  {
                    id: BlockUserDtoBlockStatusEnum.Topic,
                    name: 'Блокировка в темах',
                  },
                  {
                    id: BlockUserDtoBlockStatusEnum.Partial,
                    name: 'Частичная блокировка',
                  },
                  {
                    id: BlockUserDtoBlockStatusEnum.Unblock,
                    name: 'Не заблокирован',
                  },
                ]}
              />
              <TextField source={'utmMark.utm_source'} label={'utm_source'} />
              <TextField source={'utmMark.utm_term'} label={'utm_term'} />
              <TextField
                source={'utmMark.utm_campaign'}
                label={'utm_campaign'}
              />
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Верификация эксперта</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <SimpleShowLayout>
              <TextField source={'aboutSelf'} label={'О себе'} />
              <TextField source={'internalRating'} label={'Рейтинг эксперта'} />
              <UrlField source={'vKontakteUrl'} label={'VK'} />
              <UrlField source={'telegramUrl'} label={'Telegram'} />
              <UrlField source={'youtubeUrl'} label={'YouTube'} />
              <UrlField source={'odnoklassnikiUrl'} label={'Odnoklassniki'} />
              <UrlField source={'personalSite'} label={'Личный сайт'} />
              <UrlField source={'diplomasUrl'} label={'Дипломы'} />
              <UrlField source={'reviewsUrl'} label={'Отзывы'} />
              <UrlField source={'videoUrl'} label={'Видеовизитка'} />
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Данные клиента</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <SimpleShowLayout>
              <TextField
                source={'outgoingClientRating'}
                label={'Средняя поставленная оценка'}
              />
              <TextField
                source={'outgoingReviewCount'}
                label={'количество поставленных оценок'}
              />
              <TextField
                source={'clientBadReviewsCount'}
                label={'количество жалоб (оценок 3 и ниже)'}
              />
              <TextField
                source={'totalPayed'}
                label={'общая сумма выплат пользователя'}
              />
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Данные эксперта</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <SimpleShowLayout>
              <ArrayField source={'categories'} label={'Категории'}>
                <Datagrid>
                  <TextField source={'category.name'} label={''} />
                </Datagrid>
              </ArrayField>
              <TextField source={'aboutSelf'} label={'О себе'} />
              <BooleanField source={'isExpert'} label={'Эксперт'} />
              <TextField source={'expertRating'} label={'Средняя оценка'} />
              <TextField
                source={'totalEarned'}
                label={'общая сумма заработанных средств'}
              />
              <TextField
                source={'expertConsultationsCount'}
                label={'Оказал консультаций'}
              />
              <BooleanField source={'hasFreeChat'} label={'Бесплатные чаты'} />
              <BooleanField
                source={'hasFreeConsultations'}
                label={'3 Бесплатных консультации'}
              />
              <TextField source={'complaintsCount'} label={'Жалоб'} />
              <TextField source={''} label={'Оказал услуг'} />
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Консультации</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <SimpleShowLayout>
              <ArrayField source={'consultations.data'} label={'Консультации'}>
                <Datagrid>
                  <DateField source={'createdAt'} label={'Начало'} />
                  <ConsultationField />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Услуги</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <SimpleShowLayout>
              <ArrayField source={'expertServices'} label={'Услуги'}>
                <Datagrid>
                  <DateField source={'createdAt'} label={'Дата создания'} />
                  <DateField source={'updatedAt'} label={'Дата обновления'} />
                  <ReferenceField
                    source={'id'}
                    linkType={'edit'}
                    label={'Название'}
                    reference={'services'}
                  >
                    <TextField source={'title'} />
                  </ReferenceField>
                  <NumberField source={'price'} label={'Стоимость'} />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Typography>Шаги эксперта</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div style={{ position: 'relative' }}>
              <FunctionField
                style={{ width: '100%' }}
                label={'Шаги эксперта'}
                render={(record: any) => {
                  if (!record?.steps?.length) {
                    return <p>Путь еще не начат</p>;
                  }
                  return (
                    <div>
                      {record.steps.map(
                        (i: ExpertStepDtoStepEnum, id: number) =>
                          `${stepsTextMapping[i]}${
                            id !== record.steps.length - 1 ? ' → ' : ''
                          }`
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    </Show>
  );
};
