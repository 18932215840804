import React, { FC, useCallback } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

import CategoryNameField from '../../../components/category-field';
import { FullNameFieldWithId } from '../../../components/full-name-field-with-id';
import Pagination from '../../../components/pagination';

export const ListFeedback: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, '_blank');
      return Promise.resolve('');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      perPage={50}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      hasShow={false}
      bulkActionButtons={false}
    >
      <Datagrid>
        <DateField
          source={'updatedAt'}
          label={'Дата создания'}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />

        <ReferenceField
          reference={'categories'}
          source={'categoryId'}
          label={'Тема'}
        >
          <TextField source='rubrics[0].name' />
        </ReferenceField>

        <ReferenceField
          reference={'categories'}
          source={'categoryId'}
          label={'Подтема'}
        >
          <CategoryNameField />
        </ReferenceField>
        <TextField source={'content'} label={'Вопрос'} />
        <TextField source={'feedbackCount'} label={'Кол-во откликов'} />
        <ReferenceField
          reference={'users'}
          source={'clientId'}
          label={'Пользователь'}
        >
          <FullNameFieldWithId />
        </ReferenceField>
        <TextField source={'client.utmMark.utm_source'} label={'utm_source'} />
        <TextField source={'client.utmMark.utm_term'} label={'utm_term'} />
        <TextField
          source={'client.utmMark.utm_campaign'}
          label={'utm_campaign'}
        />
      </Datagrid>
    </List>
  );
};
