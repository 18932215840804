import { Link } from '@material-ui/core';
import React, { FC } from 'react';
import {
  ArrayField,
  DateField,
  FunctionField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import BigImageField from '../../../components/big-image-field/big-image-field';

export const ShowCategories: FC = (props: any) => {
  return (
    <Show
      {...props}
      actions={<Actions type={ActionsType.show} />}
      hasList
      hasEdit
      basePath={'custom-requests'}
    >
      <SimpleShowLayout>
        <TextField source={'id'} label={'id'} />
        <TextField source={'name'} label={'Название'} />
        <TextField source={'shortName'} label={'Короткое название'} />
        <BigImageField source={'iconUrl'} label={'Иконка'} />
        <TextField
          source={'rootCategory.name'}
          label={'Родительская категория'}
        />
        <ArrayField source='rubrics' label={'Темы'}>
          <SingleFieldList
            linkType={false}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {/* <ChipField source='name' /> */}
            <FunctionField
              source='name'
              render={(record: any) => {
                const href = `${window.location.origin}#/rubrics/${record.id}/show`;
                return <Link href={href}> {record.name}</Link>;
              }}
            />
          </SingleFieldList>
        </ArrayField>
        <TextField source={'expertsCount'} label={'Кол-во экспертов'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <TextField source={'SEODescription'} label={'SEO Описание'} />
      </SimpleShowLayout>
    </Show>
  );
};
