import polyglotI18nProvider from "ra-i18n-polyglot";
//@ts-ignore
import russianMessages from "ra-language-russian";
import React, { Dispatch, useState } from "react";
import { Admin, Resource } from "react-admin";

import { categoriesIcons } from "./components/categories-icons/categories-icons";
import LoginPage from "./components/login-page";
import Layout from "./layout/Layout";
import { mainProvider } from "./provider";
import { authProvider } from "./provider/auth-provider";
import {
  CreateTariffPlans,
  EditContentChangeRequest,
  EditFaq,
  EditTariffPlans,
  EditUser,
  EditVerificationRequest,
  ListConsultations,
  ListContentChangeRequest,
  ListFaq,
  ListTariffPlans,
  ListVerificationRequests,
  ShowConsultations,
  ShowContentChangeRequest,
  ShowFaq,
  ShowTariffPlans,
  ShowUser,
  ShowVerificationRequest,
  UserList,
} from "./resources";
import {
  CreateAdminNews,
  EditAdminNews,
  ListAdminNews,
  ShowAdminNews,
} from "./resources/admin-news";
import answerNpsPolls from "./resources/answerNpsPolls";
import {
  CreateCategory,
  EditCategories,
  ListCategories,
  ShowCategories,
} from "./resources/categories";
import {
  EditComplaint,
  ListComplaints,
  ShowComplaint,
} from "./resources/complaints";
import {
  EditContract,
  ListContract,
  ShowContract,
} from "./resources/contracts";
import {
  EditCustomRequest,
  ListCustomRequest,
  ShowCustomRequest,
} from "./resources/custom-requests";
import { EditExpert, ExpertList, ShowExpert } from "./resources/experts";
import { ListFeedback } from "./resources/feedback";
import { ListInvoices, ShowInvoices } from "./resources/invoices";
import EditInvoices from "./resources/invoices/edit-invoices";
import { ListPayments, ShowPayments } from "./resources/payments";
import { ListPortfolio, ShowPortfolio } from "./resources/portfolio";
import EditPortfolio from "./resources/portfolio/edit-portfolio";
import {
  CreatePromocode,
  EditPromocode,
  ListPromocodes,
  ShowPromocode,
} from "./resources/promocodes";
import { EditReceipts, ListReceipts, ShowReceipts } from "./resources/receipts";
import services from "./resources/sevices";
import { ListSuggested } from "./resources/suggested-categories";
import { EditTheme, ListThemes, ShowThemes } from "./resources/themes";
import { ListTransactions, ShowTransactions } from "./resources/transactions";
import {
  EditUsersTariffPlans,
  ListUsersTariffPlans,
  ShowUsersTariffPlans,
} from "./resources/users-tariff-plans";
import ListWallets from "./resources/wallets/list-wallets";
import ShowWallets from "./resources/wallets/show-wallets";

const i18nProvider = polyglotI18nProvider(() => russianMessages, "ru");

interface ThemeInterface {
  theme: string;
  toggleTheme: Dispatch<React.SetStateAction<string>>;
}

const theme: ThemeInterface = {
  theme: "light",
  toggleTheme: () => {},
};

export const ThemeContext = React.createContext(theme);

function App() {
  const [theme, setTheme] = useState("light");
  return (
    //@ts-ignore
    <ThemeContext.Provider value={{ theme: theme, toggleTheme: setTheme }}>
      <div className="App">
        <Admin
          dataProvider={mainProvider}
          title={"Consulty"}
          loginPage={LoginPage}
          authProvider={authProvider}
          layout={Layout}
          i18nProvider={i18nProvider}
        >
          <Resource
            name={"users"}
            list={UserList}
            show={ShowUser}
            edit={EditUser}
            icon={categoriesIcons.PeopleAltIcon}
            options={{ label: "Пользователи" }}
          />
          <Resource
            name={"experts"}
            list={ExpertList}
            show={ShowExpert}
            edit={EditExpert}
            icon={categoriesIcons.PeopleAltIcon}
            options={{ label: "Эксперты" }}
          />
          <Resource
            name={"consultations"}
            list={ListConsultations}
            show={ShowConsultations}
            icon={categoriesIcons.QuestionAnswerIcon}
            options={{ label: "Консультации" }}
          />
          <Resource {...services} options={{ label: "Услуги" }} />
          <Resource
            name={"complaints"}
            list={ListComplaints}
            show={ShowComplaint}
            edit={EditComplaint}
            icon={categoriesIcons.ReceiptIcon}
            options={{ label: "Жалобы" }}
          />
          <Resource
            name={"content-change-request"}
            list={ListContentChangeRequest}
            show={ShowContentChangeRequest}
            edit={EditContentChangeRequest}
            icon={categoriesIcons.PublishedWithChangesIcon}
            options={{ label: "Изменения данных пользователя" }}
          />
          <Resource
            name={"contracts"}
            list={ListContract}
            show={ShowContract}
            edit={EditContract}
            icon={categoriesIcons.FactCheckIcon}
            options={{ label: "Договоры" }}
          />
          <Resource
            name={"custom-requests"}
            list={ListCustomRequest}
            show={ShowCustomRequest}
            edit={EditCustomRequest}
            icon={categoriesIcons.EmojiPeopleIcon}
            options={{ label: "Заявки на тариф/промокод" }}
          />
          <Resource
            name={"verification-requests"}
            list={ListVerificationRequests}
            show={ShowVerificationRequest}
            edit={EditVerificationRequest}
            icon={categoriesIcons.VerifiedIcon}
            options={{ label: "Запросы верификации" }}
          />
          <Resource
            name={"admin-news"}
            list={ListAdminNews}
            show={ShowAdminNews}
            edit={EditAdminNews}
            create={CreateAdminNews}
            icon={categoriesIcons.Chat}
            options={{ label: "Канал новостей" }}
          />
          <Resource
            name={"payments"}
            list={ListPayments}
            show={ShowPayments}
            icon={categoriesIcons.PaymentIcon}
            // edit={EditPayments}
            options={{ label: "Платежи" }}
          />

          <Resource
            name={"invoices"}
            list={ListInvoices}
            show={ShowInvoices}
            edit={EditInvoices}
            icon={categoriesIcons.ReceiptIcon}
            options={{ label: "Счета" }}
          />

          <Resource
            name={"wallets"}
            list={ListWallets}
            show={ShowWallets}
            icon={categoriesIcons.AccountBalanceWalletIcon}
            options={{ label: "Кошельки" }}
          />
          <Resource
            name={"transactions"}
            list={ListTransactions}
            show={ShowTransactions}
            icon={categoriesIcons.CompareArrowsIcon}
            options={{ label: "Транзакции" }}
          />
          <Resource
            name={"promocodes"}
            list={ListPromocodes}
            create={CreatePromocode}
            show={ShowPromocode}
            edit={EditPromocode}
            icon={categoriesIcons.LoyaltyIcon}
            options={{ label: "Промокоды" }}
          />
          <Resource
            name={"user-tariff-plans"}
            list={ListUsersTariffPlans}
            show={ShowUsersTariffPlans}
            edit={EditUsersTariffPlans}
            icon={categoriesIcons.FeedIcon}
            options={{ label: "Тарифные планы пользователей" }}
          />
          <Resource
            name={"tariff-plans"}
            list={ListTariffPlans}
            show={ShowTariffPlans}
            edit={EditTariffPlans}
            create={CreateTariffPlans}
            icon={categoriesIcons.FeedIcon}
            options={{ label: "Тарифные планы" }}
          />
          <Resource
            name={"rubrics"}
            list={ListThemes}
            show={ShowThemes}
            edit={EditTheme}
            icon={categoriesIcons.CategoryIcon}
            options={{ label: "Темы" }}
          />
          <Resource
            name={"categories"}
            list={ListCategories}
            show={ShowCategories}
            edit={EditCategories}
            create={CreateCategory}
            icon={categoriesIcons.CategoryIcon}
            options={{ label: "Подтемы" }}
          />
          <Resource
            name={"suggested-categories"}
            list={ListSuggested}
            icon={categoriesIcons.CategoryIcon}
            options={{ label: "Предложенные темы" }}
          />
          <Resource
            name={"faq"}
            list={ListFaq}
            show={ShowFaq}
            edit={EditFaq}
            icon={categoriesIcons.HelpIcon}
            options={{ label: "FAQ" }}
          />

          <Resource
            name={"receipts"}
            icon={categoriesIcons.FactCheckIcon}
            list={ListReceipts}
            edit={EditReceipts}
            show={ShowReceipts}
            options={{ label: "Чеки" }}
          />
          <Resource name={"answer-nps-polls"} {...answerNpsPolls} />
          <Resource
            name={"portfolio"}
            list={ListPortfolio}
            show={ShowPortfolio}
            edit={EditPortfolio}
            icon={categoriesIcons.PeopleAltIcon}
            options={{ label: "Портфолио" }}
          />
          <Resource
            name={"feedback"}
            list={ListFeedback}
            icon={categoriesIcons.HelpIcon}
            options={{ label: "Вопросы и отклики" }}
          />
          {/* <Resource
            name={"answer-nps-polls"}
            // {...answer-nps-polls}
            icon={categoriesIcons.PollIcon}
            list={ListReceipts}
            // edit={EditReceipts}
            // show={ShowReceipts}
            options={{ label: "NPS" }}
          /> */}
          {/*<Resource name={'withdrawal'}*/}
          {/*          list={ListWithdrawal}*/}
          {/*          show={ShowWithdrawal}*/}
          {/*          edit={EditWithdrawal}*/}
          {/*          options={{label: 'Выводы'}}*/}
          {/*/>*/}
        </Admin>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
