export const calculatePeriod = (lastExitTime: string) => {
  if (!lastExitTime) {
    return 0;
  }
  const date1 = new Date(lastExitTime);
  const date2 = new Date();

  const diff = date2.getTime() - date1.getTime();

  return Number(diff / (1000 * 3600 * 24)).toFixed();
};
