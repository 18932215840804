import React, { FC } from 'react';
import {
  Edit,
  ImageField,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import { ApproveContentChangeRequestDtoStatusEnum } from '../../../api/generated';
import Actions, { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';
import FullNameField from '../../../components/full-name-field/';

export const EditContentChangeRequest: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode={'pessimistic'}
      hasList
      hasShow
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceField
          label={'Пользователь'}
          reference={'users'}
          source={'authorId'}
        >
          <FullNameField />
        </ReferenceField>
        <ImageField source={'avatarUrl'} label={'Аватар'} />
        <TextInput source={'commentAvatarUrl'} label={'Комментарий'} />
        <TextField source={'aboutSelf'} label={'О себе'} />
        <TextInput source={'commentAboutSelf'} label={'Комментарий'} />
        <SelectInput
          source={'status'}
          label={'Статус'}
          choices={[
            {
              id: ApproveContentChangeRequestDtoStatusEnum.Pending,
              name: 'На проверке',
            },
            {
              id: ApproveContentChangeRequestDtoStatusEnum.Fulfilled,
              name: 'Подтвержден',
            },
            {
              id: ApproveContentChangeRequestDtoStatusEnum.Rejected,
              name: 'Отклонен',
            },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};
