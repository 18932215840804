import { UserExpertRejectionEnum } from "../../api/generated";

export { default as ExpertList } from "./expert-list";
export { default as ShowExpert } from "./show-expert";
export { default as EditExpert } from "./edit-expert";

export const ReasonRejectedChoices = [
  { id: UserExpertRejectionEnum.DidNotAnswerUs, name: "Не ответил нам" },
  {
    id: UserExpertRejectionEnum.DoNotUnderstandWhatTheServiceIsAbout,
    name: "Не понял о чем сервис",
  },
  {
    id: UserExpertRejectionEnum.WhileNotConsulting,
    name: "Пока не консультирует",
  },
  { id: UserExpertRejectionEnum.BigCommission, name: "Большая комиссия" },
  { id: UserExpertRejectionEnum.DidNotLikeTheSite, name: "Не понравился сайт" },
  { id: UserExpertRejectionEnum.AnotherReason, name: "Другая причина" },
];
