import { Box, Button } from '@mui/material';
import { FC } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  useNotify,
} from 'react-admin';

import { api } from '../../../api';
import { FullNameFieldWithId } from '../../../components/full-name-field-with-id';
import { PaginationNps } from '../../../components/pagination/paginationNps';

export const ListAnswerNpsPolls: FC = (props: any) => {
  const notify = useNotify();

  async function createPollForAll() {
    try {
      await api.V1AdminNpsPollsApi.adminNpsPollsControllerCreateNpsPoll(
        'forAll'
      ).then(() => {
        notify('Опрос создан для всех');
      });
    } catch (e) {
      return Promise.reject('Не удалось создать опрос');
    }
  }

  async function createPollForExperts() {
    try {
      await api.V1AdminNpsPollsApi.adminNpsPollsControllerCreateNpsPoll(
        'forExperts'
      ).then(() => {
        notify('Опрос создан для экспертов');
      });
    } catch (e) {
      return Promise.reject('Не удалось создать опрос');
    }
  }

  async function createPollForClients() {
    try {
      await api.V1AdminNpsPollsApi.adminNpsPollsControllerCreateNpsPoll(
        'forClients'
      ).then(() => {
        notify('Опрос создан для клиентов');
      });
    } catch (e) {
      return Promise.reject('Не удалось создать опрос');
    }
  }

  return (
    <>
      <Box display='flex' marginTop={5} marginBottom={5}>
        <Box maxWidth='40em' marginRight={5}>
          <Button variant='contained' onClick={createPollForAll}>
            Запушить опрос для всех
          </Button>
        </Box>

        <Box maxWidth='40em' marginRight={5} onClick={createPollForClients}>
          <Button variant='contained'>Запушить опрос для клиентов</Button>
        </Box>

        <Box maxWidth='40em'>
          <Button variant='contained' onClick={createPollForExperts}>
            Запушить опрос для экспертов
          </Button>
        </Box>
      </Box>

      <List
        {...props}
        //filters={listFilters}
        pagination={<PaginationNps />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={5000}
        bulkActionButtons={false}
        syncWithLocation={true}
      >
        <Datagrid hasBulkActions={false}>
          <TextField source={'rating'} label={'Оценка'} />
          <TextField source={'text'} label={'Комментарий'} />
          <DateField
            source={'createdAt'}
            label={'Дата создания'}
            fullWidth
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
          <ReferenceField
            source={'userId'}
            linkType={'show'}
            label={'Пользователь'}
            reference={'users'}
            fullWidth
          >
            <FullNameFieldWithId />
          </ReferenceField>
          <TextField source={'isExpert'} label={'Эксперт'} fullWidth />
        </Datagrid>
      </List>
    </>
  );
};
