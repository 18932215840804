import { ContractStatusEnum, ContractTypeEnum } from '../../api/generated';

export { default as EditContract } from './edit-contract';
export { default as ShowContract } from './show-contract';
export { default as ListContract } from './list-contract';


export const ContractStatusChoices = [
    {id: ContractStatusEnum.Accepted, name: 'Принят'},
    {id: ContractStatusEnum.Rejected, name: 'Отклонен'},
    // {id: ContractStatusEnum.PassedReview, name: 'Прошел проверку'},
    {id: ContractStatusEnum.WaitingReview, name: 'Ожидает проверки'},
    // {id: ContractStatusEnum.ReviewRejection, name: 'Нужны исправления'},
];

export const ContractTypeChoices = [
    {id: ContractTypeEnum.Ie, name: 'ИП'},
    {id: ContractTypeEnum.Llc, name: 'ООО'},
    {id: ContractTypeEnum.Individual, name: 'Физическое лицо'},
    {id: ContractTypeEnum.SelfEmployed, name: 'Самозанятый'},
];
