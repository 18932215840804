import React, { FC } from "react";
import {
  BooleanField,
  BooleanInput,
  DateField,
  Edit,
  EmailField,
  FormDataConsumer,
  FunctionField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

import { UserExpertRejectionEnum } from "../../../api/generated";
import Actions, { ActionsType } from "../../../components/actions";
import EditToolbar from "../../../components/edit-toolbar";
import TitleUser from "../../../components/title-user";
import { calculatePeriod } from "../../../utils/calculate-period";
import { ReasonRejectedChoices } from "..";

export const EditExpert: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      hasShow
      hasList
      title={<TitleUser />}
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source={"firstName"} label={"Имя"} />
        <TextField source={"lastName"} label={"Фамилия"} />
        <TextField source={"age"} label={"Возраст"} sortable={false} />
        <TextField source={"phone"} label={"Телефон"} />
        <EmailField source={"email"} label={"Email"} />
        <TextField source={"id"} label={"id"} />
        <DateField source={"createdAt"} label={"Дата создания"} />
        <TextField
          source={"clientConsultationsCount"}
          label={"кол-во консультаций"}
        />
        <TextField
          source={"expertConsultationsCount"}
          label={"Э - Консультаций"}
        />
        <TextField source={"totalEarned"} label={"Э - Выручка"} />
        <BooleanField source={"isApproved"} label={"Подтвержденый"} />
        <BooleanField source={"isContractAccepted"} label={"Э - договор"} />
        <BooleanField source={"isProfileVerified"} label={"Э - верификация"} />
        <BooleanField
          source={"isSpecialTarif"}
          label={"Э - получен тариф"}
          sortable={false}
        />
        <BooleanField source={"hasFreeChat"} label={"Э - бесплатные чаты"} />
        <FunctionField
          render={(record: any) => calculatePeriod(record.lastExitTime)}
          label={"Дней с последнего визита"}
        />
        <BooleanInput source={"isExpertContacted"} label={"Скрипт - WA/TG"} />
        <SelectInput
          source={"expertRejection"}
          label={"Причина отказа"}
          choices={ReasonRejectedChoices}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.expertRejection ===
              UserExpertRejectionEnum.AnotherReason && (
              <TextInput
                source={"commentExpertRejection"}
                label={"Комментарий причины отказа"}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
