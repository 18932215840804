import React, { FC } from 'react';
import {
  BooleanField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { PromocodeTypeEnum } from '../../../api/generated';
import Actions, { ActionsType } from '../../../components/actions';

export const ShowPromocode: FC = (props: any) => {
  return (
    <Show {...props} actions={<Actions type={ActionsType.show} />}>
      <SimpleShowLayout>
        <TextField source={'id'} label={'id'} />
        <TextField source={'title'} label={'Заголовок'} />
        <TextField source={'code'} label={'Код'} />
        <TextField
          source={'privilege.freeConsultationsCount'}
          label={'Бесплатных консультаций'}
        />
        <SelectField
          source={'type'}
          label={'Тип'}
          choices={[
            {
              id: PromocodeTypeEnum.FreeConsultation,
              name: 'Бесплатная консультация',
            },
          ]}
        />
        <ReferenceField
          reference={'custom-requests'}
          source={'customRequest.id'}
          label={'Привязан к заявке'}
        >
          <TextField source='id' />
        </ReferenceField>
        <BooleanField source={'isActive'} label={'Активный'} />
      </SimpleShowLayout>
    </Show>
  );
};
