import React, { FC } from 'react';
import {
    BooleanField,
    DateField,
    Show,
    SimpleShowLayout,
    TextField} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';

export const ShowTariffPlans: FC = (props: any) => {
    return (
        <Show
            {...props}
            title={'Тарифный план'}
            actions={<Actions type={ActionsType.show}/>}
            hasList
            hasEdit
            noDelete
            basePath={'tariff-plans'}
        >
            <SimpleShowLayout>
                <TextField source={'id'} label={'id'}/>
                <TextField source={'commissionPercent'} label={'Процент комиссии'}/>
                <DateField source={'startDate'} label={'Начало'}/>
                <DateField source={'endDate'} label={'Конец'}/>
                <BooleanField source={'isDefault'} label={'По умолчанию'}/>
                <DateField source={'createdAt'} label={'Дата создания'}/>
            </SimpleShowLayout>
        </Show>
    );
};