import React, { FC } from "react";
import {SaveButton, Toolbar } from "react-admin";

export const EditToolbar: FC = (props: any) => {
  return (
    <Toolbar {...props} alwaysEnable>
      <SaveButton
        label={"Сохранить"}
        redirect={"show"}
        disabled={props.pristine}
      />
      {/* <DeleteButton
                label={'Удалить'}
                redirect={'list'}
                confirmContent={'Вы уверенены?'}
            /> */}
    </Toolbar>
  );
};
