import { makeStyles } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  EmailField,
  Filter,
  ImageField,
  List,
  NumberInput,
  TextField,
  TextInput,
} from 'react-admin';

import { User } from '../../../api/generated';
import ExpertStepsField from '../../../components/experts-steps-field';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';

const useStyles = makeStyles({
  avatar: { '& > img': { maxHeight: 32, maxWidth: 32 } },
  stepsField: { width: '450px', display: 'flex' },
});

const UsersFilters = (props: any) => (
  <Filter {...props}>
    <TextInput label={'Имя'} source={'firstName'} alwaysOn />
    <TextInput label={'Фамилия'} source={'lastName'} alwaysOn />
    <BooleanInput label={'Эксперт'} source={'isExpert'} />
    <TextInput source={'email'} label={'Email'} />
    <TextInput source={'phone'} label={'Телефон'} />
    <NumberInput source={'id'} label={'id'} />
    <DateTimeInput source={'from'} label={'С какой даты'} />
    <DateTimeInput source={'until'} label={'По какую дату'} />
    <TextInput source={'utmMark.utm_source'} label={'utm_source'} />
    <TextInput source={'utmMark.utm_term'} label={'utm_term'} />
    <TextInput source={'utmMark.utm_campaign'} label={'utm_campaign'} />
  </Filter>
);

export const UserList: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, '_blank');
      return Promise.resolve('');
    },
    []
  );

  const exporter = async (users: User[]) => {
    downloadCSV({
      filename: `users.csv`,
      data: users,
      columns: [
        'firstName',
        'lastName',
        'age',
        'phone',
        'email',
        'id',
        'createdAt',
        'clientConsultationsCount',
        'expertConsultationsCount',
        'isExpert',
        'avatarUrl',
        'utmMark.utm_source',
        'utmMark.utm_term',
        'utmMark.utm_campaign',
      ],
      columnsNames: [
        'имя',
        'фамилия',
        'возраст',
        'телефон',
        'электронная почта',
        'id',
        'дата создания',
        'количество консультаций',
        'количество экспертных консультаций',
        'является экспертом',
        'ссылка на аватар',
        'utm_source',
        'utm_term',
        'utm_campaign',
      ],
    });
  };
  const classNames = useStyles();

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<UsersFilters />}
      hasShow
      hasEdit
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
      perPage={50}
      exporter={exporter}
      syncWithLocation={true}
    >
      <Datagrid hasBulkActions={false} rowClick={rowClick()}>
        <TextField source={'firstName'} label={'Имя'} />
        <TextField source={'lastName'} label={'Фамилия'} />
        <TextField source={'age'} label={'Возраст'} sortable={false} />
        <TextField source={'phone'} label={'Телефон'} />
        <EmailField source={'email'} label={'Email'} />
        <TextField source={'id'} label={'id'} />
        <DateField source={'createdAt'} label={'Дата регистрации'} />
        <TextField
          source={'clientConsultationsCount'}
          label={'кол-во консультаций'}
        />
        <TextField
          source={'expertConsultationsCount'}
          label={'Эксперт - Консультаций'}
        />
        <BooleanField source={'isExpert'} label={'Эксперт'} />
        <ImageField
          source={'avatarUrl'}
          label={'Аватар'}
          sortable={false}
          className={classNames.avatar}
        />
        <TextField source={'utmMark.utm_source'} label={'utm_source'} />
        <TextField source={'utmMark.utm_term'} label={'utm_term'} />
        <TextField source={'utmMark.utm_campaign'} label={'utm_campaign'} />
        <ExpertStepsField label='Шаги эксперта' />
      </Datagrid>
    </List>
  );
};
