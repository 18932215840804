import { GetListParams, GetOneParams, UpdateParams } from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const portfolioDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['expert'],
    };
    const { data }: any =
      await api.V1AdminPortfoliosApi.getManyBaseAdminPortfoliosConrollerPortfolio(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getOne(params: GetOneParams) {
    let id;
    if (typeof params.id === 'number') {
      id = params.id;
    } else {
      id = parseInt(params.id);
    }
    return await api.V1AdminPortfoliosApi.getOneBaseAdminPortfoliosConrollerPortfolio(
      id
    );
  },

  async update(params: UpdateParams) {
    const id = params.data.id;
    if (typeof id === 'number') {
      const { data: request } =
        await api.V1AdminPortfoliosApi.adminPortfoliosConrollerProcessPortfolio(
          id,
          {
            status: params.data.status,
            adminComment: params.data.adminComment,
          }
        );
      const { data: updatedRequest } =
        await api.V1AdminPortfoliosApi.getOneBaseAdminPortfoliosConrollerPortfolio(
          id
        );
      return {
        data: updatedRequest,
      };
    }
    return Promise.reject();
  },
} as RouteDataProvider;
