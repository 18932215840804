


import { RouteDataProvider } from '../types';


// @ts-ignore
export const withdrawalDataProvider = {

    // async getList(params: GetListParams) {
    //     const query = nestQueryBuilder(params);
    //     const reqParams: Params = {
    //         page: params.pagination.page,
    //         limit: params.pagination.perPage,
    //         sort: query.getAll('sort'),
    //         filter: query.getAll('filter'),
    //     };
    //     const {data}: any = await api.V1WithdrawalRequestApi.getManyBaseAdminWithdrawalRequestsControllerWithdrawalRequest(
    //         reqParams.fields,
    //         reqParams.s,
    //         reqParams.filter,
    //         reqParams.or,
    //         reqParams.sort,
    //         reqParams.join,
    //         reqParams.limit,
    //         reqParams.offset,
    //         reqParams.page,
    //         reqParams.cache,
    //         reqParams.options
    //     );
    //
    //     return {
    //         data: data.data,
    //         total: data.total
    //     };
    // },
    //
    // async getMany(params: GetManyParams) {
    //     const transactions = [];
    //     for (let i = 0; i < params.ids.length; i++) {
    //         const id = params.ids[i];
    //         if (typeof id === 'number') {
    //             const {data: user} = await api.V1WithdrawalRequestApi.getOneBaseAdminWithdrawalRequestsControllerWithdrawalRequest(id);
    //             transactions.push(user);
    //         }
    //     }
    //     return {data: transactions};
    // },
    //
    // async getOne(params: GetOneParams) {
    //     const reqParams: Params = {
    //
    //     };
    //
    //     const {data: transaction} = await api.V1WithdrawalRequestApi.getOneBaseAdminWithdrawalRequestsControllerWithdrawalRequest(
    //         parseInt(params.id.toString()),
    //         reqParams.fields,
    //         reqParams.join
    //     );
    //
    //     return {
    //         data: transaction
    //     }
    // },
    //
    // async delete(params: DeleteParams){
    //     const id = params.id;
    //     if (typeof id === 'number') {
    //         const {data: withdrawal} = await api.V1WithdrawalRequestApi.getOneBaseAdminWithdrawalRequestsControllerWithdrawalRequest(id);
    //         const {status} = await api.V1WithdrawalRequestApi.deleteOneBaseAdminWithdrawalRequestsControllerWithdrawalRequest(id);
    //         if (status === 200) {
    //             return {data: withdrawal};
    //         }
    //     }
    //     return Promise.reject({data: params.previousData});
    // }
} as RouteDataProvider;
