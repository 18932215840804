import { makeStyles } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  EmailField,
  Filter,
  FunctionField,
  List,
  NumberInput,
  SelectField,
  SelectInput,
  TextField,
} from 'react-admin';

import { User } from '../../../api/generated';
import ExpertStepsField from '../../../components/experts-steps-field';
import Pagination from '../../../components/pagination';
import { calculatePeriod } from '../../../utils/calculate-period';
import { downloadCSV } from '../../../utils/download-csv';
import { ReasonRejectedChoices } from '..';

const useStyles = makeStyles({
  stepsField: { width: '450px', display: 'flex' },
});

const UsersFilters = (props: any) => (
  <Filter {...props}>
    {/* <BooleanInput label={"Э - подтвержденный"} source={"confirmed"} /> */}
    <BooleanInput label={'Э -договор'} source={'isContractAccepted'} />
    {/* <BooleanInput label={"Э - верификация"} source={"veryfied"} /> */}
    <BooleanInput
      label={'Э - бесплатные чаты включены'}
      source={'hasFreeChat'}
    />
    <BooleanInput label={'Скрипт - WA/TG'} source={'isExpertContacted'} />
    <DateTimeInput source={'from'} label={'С какой даты'} />
    <DateTimeInput source={'until'} label={'По какую дату'} />
    <NumberInput source='dayExistsFrom' label='Дней с последнего визита, от' />
    <NumberInput source='dayExistsUntil' label='Дней с последнего визита, до' />
    <NumberInput source='totalEarnedFrom' label='Э - выручка, от' />
    <NumberInput source='totalEarnedUntil' label='Э - выручка, до' />
    <SelectInput
      source={'expertRejection'}
      label={'Причина отказа'}
      choices={ReasonRejectedChoices}
    />
  </Filter>
);

export const ExpertList: FC = (props: any) => {
  const classNames = useStyles();
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#/users/${id}/show`;
      window.open(href, '_blank');
      return Promise.resolve('');
    },
    []
  );

  const exporter = async (users: User[]) => {
    downloadCSV({
      filename: `users.csv`,
      data: users,
      columns: [
        'firstName',
        'lastName',
        'age',
        'phone',
        'email',
        'id',
        'createdAt',
        'clientConsultationsCount',
        'expertConsultationsCount',
        'totalEarned',
        'isApproved',
        'isContractAccepted',
        'isProfileVerified',
        'hasFreeChat',
        'lastExitTime',
        'isExpertContacted',
        'expertRejection',
      ],
      columnsNames: [
        'имя',
        'фамилия',
        'возраст',
        'телефон',
        'электронная почта',
        'id',
        'дата создания',
        'количество консультаций',
        'количество экспертных консультаций',
        'Эксперт - Выручка',
        'Подтвержденый',
        'Э - договор',
        'Э - верификация',
        'Э - бесплатные чаты',
        'Дней с последнего визита',
        'Скрипт - WA/TG',
        'Причина отказа',
      ],
    });
  };

  const expertRowStyle = (record: any, index: number) => {
    const countOfDay = calculatePeriod(record.lastExitTime);
    if (countOfDay >= 7 && countOfDay <= 8) {
      return {
        backgroundColor: '#75B9BE',
      };
    }

    if (countOfDay >= 9 && !record.expertRejection) {
      return {
        backgroundColor: '#EE7674',
      };
    }
  };

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<UsersFilters />}
      hasShow
      hasEdit
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
      perPage={50}
      exporter={exporter}
      syncWithLocation={true}
      filter={{ isExpert: true }}
    >
      <Datagrid
        hasBulkActions={false}
        rowClick={rowClick()}
        rowStyle={expertRowStyle}
      >
        <TextField source={'firstName'} label={'Имя'} />
        <TextField source={'lastName'} label={'Фамилия'} />
        <TextField source={'age'} label={'Возраст'} sortable={false} />
        <TextField source={'phone'} label={'Телефон'} />
        <EmailField source={'email'} label={'Email'} />
        <TextField source={'id'} label={'id'} />
        <DateField source={'createdAt'} label={'Дата регистрации'} />
        <TextField
          source={'clientConsultationsCount'}
          label={'кол-во консультаций'}
        />
        <TextField
          source={'expertConsultationsCount'}
          label={'Э - Консультаций'}
        />
        <TextField source={'totalEarned'} label={'Э - Выручка'} />
        <BooleanField
          source={'confirmed'}
          label={'Подтвержденый'}
          sortable={false}
        />
        <BooleanField source={'isContractAccepted'} label={'Э - договор'} />
        <BooleanField
          source={'veryfied'}
          label={'Э - верификация'}
          sortable={false}
        />
        <BooleanField
          source={'isSpecialTarif'}
          label={'Э - получен тариф'}
          sortable={false}
        />
        <BooleanField source={'hasFreeChat'} label={'Э - бесплатные чаты'} />
        <FunctionField
          render={(record: any) => calculatePeriod(record.lastExitTime)}
          label={'Дней с последнего визита'}
        />
        <BooleanField source={'isExpertContacted'} label={'Скрипт - WA/TG'} />
        <SelectField
          source={'expertRejection'}
          label={'Причина отказа'}
          choices={ReasonRejectedChoices}
        />
        <ExpertStepsField label='Шаги эксперта' />
      </Datagrid>
    </List>
  );
};
