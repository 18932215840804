import React, { FC } from 'react';
import {
    ReferenceField,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField,
} from 'react-admin';

import { ApproveContentChangeRequestDtoStatusEnum } from '../../../api/generated';
import Actions, { ActionsType } from '../../../components/actions';
import BigImageField from '../../../components/big-image-field/big-image-field';
import FullNameField from '../../../components/full-name-field';

export const ShowContentChangeRequest: FC = (props: any) => {

    return (
        <Show {...props}
              hasList
              hasEdit
              actions={<Actions type={ActionsType.show}/>}
        >
            <SimpleShowLayout>
                <ReferenceField reference={'users'} source={'expertId'} label={'Эксперт'}>
                    <FullNameField/>
                </ReferenceField>
                <TextField source={'aboutSelf'} label={'О себе'}/>
                <BigImageField source={'avatarUrl'} label={'Аватар'}/>
                <SelectField source={'status'}
                             label={'Статус'}
                             choices={[
                                 {id: ApproveContentChangeRequestDtoStatusEnum.Pending, name: 'На проверке'},
                                 {id: ApproveContentChangeRequestDtoStatusEnum.Fulfilled, name: 'Подтвержден'},
                                 {id: ApproveContentChangeRequestDtoStatusEnum.Rejected, name: 'Отклонен'},
                             ]}
                />
            </SimpleShowLayout>
        </Show>
    );
};