import React, { FC, useCallback } from 'react';
import { BooleanField,Datagrid, DateField, List, TextField } from 'react-admin';

import { TariffPlan } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';

const exporter = async (tariffPlans: TariffPlan[]) => {        
    downloadCSV({ 
        filename: `tariff-plans.csv`,
        data: tariffPlans,
        columns: [
            'id',
            'commissionPercent',
            'startDate',
            'endDate',
            'isDefault',
            'createdAt',
        ],
        columnsNames: [
          "id",
          "процент комиссии",
          "начальная дата",
          "конечная дата",
          "является тарифным планом по умолчанию",
          "дата создания",
      ]
      })
  };

export const ListTariffPlans : FC = (props: any) => {

    const rowClick = useCallback(() => (id: any, basePath: any, record: any) => {
        return Promise.resolve('show');
    }, []);

    return (
        <List {...props}
              pagination={<Pagination/>}
              bulkActionButtons={false}
              perPage={50}
              exporter={exporter}
        >
            <Datagrid rowClick={rowClick()}>
                <TextField source={'id'} label={'id'}/>
                <TextField source={'commissionPercent'} label={'Процент комиссии'}/>
                <DateField source={'startDate'} label={'Начало'}/>
                <DateField source={'endDate'} label={'Конец'}/>
                <BooleanField source={'isDefault'} label={'По умолчанию'}/>
                <DateField source={'createdAt'} label={'Дата создания'}/>
            </Datagrid>
        </List>
    );
};