import axios from "axios";

import { API_BASE_URL } from "../constants";
import { cookie } from "../services/cookie";

const httpClient = axios.create({
  baseURL: API_BASE_URL,
});

httpClient.interceptors.request.use(
  (config) => {
    const token = cookie.get("token") || localStorage.getItem("token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  () => {}
);

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      throw error.response.data;
    }

    if (error.data) {
      throw error.data;
    }

    throw error;
  }
);

export default httpClient;
