import {
    GetListParams,
    GetManyParams,
    GetOneParams,
} from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';


// @ts-ignore
export const transactionsDataProvider = {

    async getList(params: GetListParams) {
        const query = nestQueryBuilder(params);
        const reqParams: Params = {
            page: params.pagination.page,
            limit: params.pagination.perPage,
            sort: query.getAll('sort'),
            filter: query.getAll('filter'),
        };
        const {data}: any = await api.V1AdminTransactionsApi.getManyBaseAdminTransactionsControllerTransaction(
            reqParams.fields,
            reqParams.s,
            reqParams.filter,
            reqParams.or,
            reqParams.sort,
            reqParams.join,
            reqParams.limit,
            reqParams.offset,
            reqParams.page,
            reqParams.cache,
            reqParams.options
        );

        return {
            data: data.data,
            total: data.total
        };
    },

    async getMany(params: GetManyParams) {
        const transactions = [];
        for (let i = 0; i < params.ids.length; i++) {
            const id = params.ids[i];
            if (typeof id === 'number') {
                try {
                    const {data: transaction} = await api.V1AdminTransactionsApi.getOneBaseAdminTransactionsControllerTransaction(id);
                    transactions.push(transaction);
                }catch (e) {
                    transactions.push({});
                }
            }
        }
        return {data: transactions};
    },

    async getOne(params: GetOneParams) {
        const reqParams: Params = {

        };

        const {data: transaction} = await api.V1AdminTransactionsApi.getOneBaseAdminTransactionsControllerTransaction(
            parseInt(params.id.toString()),
            reqParams.fields,
            reqParams.join
        );

        return {
            data: transaction
        }
    },
} as RouteDataProvider;
