import {
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const contentChangeRequestDataProvider = {
  async delete(params: DeleteParams) {
    const id = params.id;
    if (typeof id === 'number') {
      const { data: request } =
        await api.V1AdminContentChangeRequestsApi.getOneBaseAdminContentChangeRequestControllerContentChangeRequest(
          id
        );
      const { status } =
        await api.V1AdminContentChangeRequestsApi.deleteOneBaseAdminContentChangeRequestControllerContentChangeRequest(
          id
        );
      if (status === 200) {
        return { data: request };
      }
    }
    return Promise.reject({ data: params.previousData });
  },

  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['expert'],
    };
    const { data }: any =
      await api.V1AdminContentChangeRequestsApi.getManyBaseAdminContentChangeRequestControllerContentChangeRequest(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

      data.data = data.data.map((item: any) => {
        item.firstName = item.firstName? item.firstName : '—'; 
        item.lastName = item.lastName? item.lastName : '—';            
        return item;
      })

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const requests = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: contentChangeRequest } =
            await api.V1AdminContentChangeRequestsApi.getOneBaseAdminContentChangeRequestControllerContentChangeRequest(
              id
            );
          requests.push(contentChangeRequest);
        } catch (e) {
          requests.push({});
        }
      }
    }
    return { data: requests };
  },

  async getOne(params: GetOneParams) {
    let id;
    if (typeof params.id === 'number') {
      id = params.id;
    } else {
      id = parseInt(params.id);
    }
    return await api.V1AdminContentChangeRequestsApi.getOneBaseAdminContentChangeRequestControllerContentChangeRequest(
      id
    );
  },

  async update(params: UpdateParams) {
    const id = params.data.id;
    if (typeof id === 'number') {
      const { data: request } =
        await api.V1AdminContentChangeRequestsApi.adminContentChangeRequestControllerProcessRequest(
          id,
          {
            status: params.data.status,
            commentAboutSelf: params.data.commentAboutSelf,
            commentAvatarUrl: params.data.commentAvatarUrl,
          }
        );
      const { data: updatedRequest } =
        await api.V1AdminContentChangeRequestsApi.getOneBaseAdminContentChangeRequestControllerContentChangeRequest(
          id
        );
      return {
        data: updatedRequest,
      };
    }
    return Promise.reject();
  },
} as RouteDataProvider;
