import React, { FC, useCallback } from 'react';
import { Datagrid, List, TextField } from 'react-admin';

export const ListFaq: FC = (props: any) => {

    const rowClick = useCallback(() => (id: any, basePath: any, record: any) => {
        return Promise.resolve('show');
    }, []);

    return (
        <List {...props}
            bulkActionButtons={false}
              pagination={false}
        >
            <Datagrid
                hasBulkActions={false}
                rowClick={rowClick()}
            >
                <TextField source={'id'}/>
            </Datagrid>
        </List>
    );
};