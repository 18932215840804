import React, { FC, useCallback } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  ImageField,
  List,
  SelectField,
  TextField,
} from 'react-admin';

import { CustomRequest } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';
import { truncate } from '../../../utils/truncateText';
import { AdminNewsStatus, AdminNewsToWhomChoices } from '..';

const exporter = async (customReq: CustomRequest[]) => {
  downloadCSV({
    filename: `admin-news.csv`,
    data: customReq,
    columns: [
      'id',
      'content',
      'content',
      'content',
      'toWhom',
      'numberOfViews',
      'createdAt',
      'createdAt',
      'status',
    ],
    columnsNames: [
      'id',
      'Сообщение',
      'Ссылка',
      'Фото',
      'Кому',
      'Кол-во просмотров',
      'Дата',
      'Время',
      'Статус',
    ],
  });
};

export const ListAdminNews: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, '_blank');
      return Promise.resolve('');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      hasShow
      hasEdit
      perPage={50}
      sort={{ field: 'createdAt', order: 'DESC' }}
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()} hasBulkActions={false}>
        <TextField source={'id'} label={'id'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        {/* <RichTextField source={'content'} label={'Сообщение'} /> */}
        {/* <FunctionField /> */}
        <FunctionField
          render={(record: any) => {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: truncate(record.content, 500),
                }}
              />
            );
          }}
        />
        <ImageField source={'imageUrl'} label={'Изображение'} />
        <SelectField
          source={'toWhom'}
          choices={AdminNewsToWhomChoices}
          label={'Кому'}
        />
        <TextField source={'numberOfViews'} label={'Кол-во просмотров'} />
        <DateField
          source={'scheduledTime'}
          label={'Запланированное время'}
          options={{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <SelectField
          source={'status'}
          choices={AdminNewsStatus}
          label={'Статус'}
        />
      </Datagrid>
    </List>
  );
};
