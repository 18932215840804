import React, { FC, useCallback } from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';

import { Category } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';

const exporter = async (category: Category[]) => {
  downloadCSV({
    filename: `categories.csv`,
    data: category,
    columns: [
      'id',
      'name',
      'rootCategory.name',
      'rubricNames',
      'iconUrl',
      'expertsCount',
      'createdAt',
    ],
    columnsNames: [
      'id',
      'название',
      'категория',
      'темы',
      'ссылка на иконку',
      'количество экспертов',
      'дата создания',
    ],
  });
};

export const ListCategories: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve('show');
    },
    []
  );

  const CategoriesFilters = (props: any) => (
    <Filter {...props}>
      <TextInput source={'rootCategory.name'} label={'Подтемы'} />
      <TextInput source={'rubrics.name'} label={'Темы'} />
      <TextInput source={'name'} label={'Название'} />
    </Filter>
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<CategoriesFilters />}
      hasShow
      hasEdit
      perPage={50}
      sort={{ field: 'expertsCount', order: 'DESC' }}
      exporter={exporter}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={rowClick()} hasBulkActions={false}>
        <TextField source={'id'} label={'id'} />
        <TextField source={'name'} label={'Название'} />
        <TextField source={'shortName'} label={'Короткое название'} />
        <TextField source={'rootCategory.name'} label={'Подтемы'} />
        <ArrayField source='rubrics' label={'Темы'}>
          <SingleFieldList>
            <TextField source='name' />
          </SingleFieldList>
        </ArrayField>
        <ImageField source={'iconUrl'} label={'Иконка'} />
        <TextField source={'expertsCount'} label={'Кол-во экспертов'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <TextField source={'SEODescription'} label={'SEO Описание'} />
      </Datagrid>
    </List>
  );
};
