import { FC, useCallback } from 'react';
import {
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';

import { Wallet } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';

const exporter = async (wallets: Wallet[]) => {
  downloadCSV({
    filename: `wallets.csv`,
    data: wallets,
    columns: [
      'id',
      'balance',
      'owner.firstName',
      'owner.lastName',
      'createdAt',
    ],
    columnsNames: ['id', 'баланс', 'имя', 'фамилия', 'дата создания'],
  });
};

const WalletsFilters = (props: any) => (
  <Filter {...props}>
    <TextInput source={'owner.firstName'} label={'Имя'} />
    <TextInput source={'owner.lastName'} label={'Фамилия'} />
    <DateTimeInput source={'from'} label={'С какой даты'} />
    <DateTimeInput source={'until'} label={'По какую дату'} />
  </Filter>
);

export const ListWallets: FC = (props) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve('show');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<WalletsFilters />}
      bulkActionButtons={false}
      perPage={50}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()} hasBulkActions={false}>
        <TextField source={'id'} label={'id'} />
        <TextField source={'balance'} label={'Баланс'} />
        <TextField source={'owner.firstName'} label={'Имя'} />
        <TextField source={'owner.lastName'} label={'Фамилия'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
      </Datagrid>
    </List>
  );
};
