import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CategoryIcon from "@mui/icons-material/Category";
import Chat from "@mui/icons-material/Chat";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FeedIcon from "@mui/icons-material/Feed";
import HelpIcon from "@mui/icons-material/Help";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import PaymentIcon from "@mui/icons-material/Payment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PollIcon from "@mui/icons-material/Poll";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ReceiptIcon from "@mui/icons-material/Receipt";
import VerifiedIcon from "@mui/icons-material/Verified";

export const categoriesIcons = {
  PeopleAltIcon,
  VerifiedIcon,
  PublishedWithChangesIcon,
  QuestionAnswerIcon,
  LoyaltyIcon,
  FeedIcon,
  HelpIcon,
  PaymentIcon,
  ReceiptIcon,
  CompareArrowsIcon,
  AccountBalanceWalletIcon,
  EmojiPeopleIcon,
  CategoryIcon,
  FactCheckIcon,
  PollIcon,
  Chat,
};
