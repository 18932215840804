import React, { FC } from 'react';
import {
  DateField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import ArrayStringField from '../../../components/array-string-field/array-string-field';
import BigImageField from '../../../components/big-image-field/big-image-field';
import FullNameField from '../../../components/full-name-field';
import { ContractStatusChoices, ContractTypeChoices } from '../index';

export const ShowContract: FC = (props: any) => {
  return (
    <Show
      {...props}
      actions={<Actions type={ActionsType.show} />}
      hasList
      hasEdit
      basePath={'custom-requests'}
    >
      <SimpleShowLayout>
        <TextField source={'id'} label={'id'} />
        <ReferenceField
          source={'ownerId'}
          label={'Пользователь'}
          reference={'users'}
        >
          <FullNameField />
        </ReferenceField>
        <SelectField
          choices={ContractStatusChoices}
          source={'status'}
          label={'Статус'}
        />
        <ArrayStringField
          source={'adminComments'}
          label={'Комментарий администратора'}
        />
        <SelectField
          choices={ContractTypeChoices}
          source={'type'}
          label={'Тип'}
        />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <UrlField source={'pdfUrl'} label={'Ссылка на pdf'} />

        <TextField source={'payload.fullName'} label={'ФИО'} />
        <TextField source={'payload.inn'} label={'ИНН'} />

        <TextField
          source={'payload.contactPhone'}
          label={'Контактный телефон'}
        />
        <TextField source={'payload.email'} label={'Email'} />

        <DateField source={'payload.birthDate'} label={'Дата рождения'} />

        <TextField source={'payload.passportSeries'} label={'Серия паспорта'} />
        <TextField source={'payload.passportNumber'} label={'Номер паспорта'} />
        <DateField
          source={'payload.passportWhenGiven'}
          label={'Дата выдачи паспорта'}
        />
        <TextField
          source={'payload.residencePlaceRegistrationAddress'}
          label={'Адрес регистрации по месту жительства'}
        />
        <TextField
          source={'payload.physicalAddress'}
          label={'Физический адрес'}
        />
        <TextField
          source={'payload.passportIssuedBy'}
          label={'Паспорт выдан'}
        />
        <BigImageField
          source={'payload.passportRegistrationPageUrl'}
          label={'Регистрация'}
        />
        <BigImageField
          source={'payload.passportSecondAndThirdPageUrl'}
          label={'Первая и вторая страница'}
        />
        <BigImageField
          source={'payload.selfieWithPassportSecondAndThirdPageUrl'}
          label={'Селфи с паспортом'}
        />
      </SimpleShowLayout>
    </Show>
  );
};
