import React, { FC } from "react";
import {
  BooleanField,
  Edit,
  FormDataConsumer,
  FunctionField,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextField,
} from "react-admin";

import { complaintDecision } from "..";

export const EditComplaint: FC = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source={"feedback"} label={"Текст жалобы"} />
        <TextField source={"date"} label={"Дата создания"} sortable={false} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceField
              label="Консультация"
              source="consultationId"
              reference="consultations"
              link="show"
            >
              <FunctionField render={(record: any) => formData.consultation} />
            </ReferenceField>
          )}
        </FormDataConsumer>
        <ReferenceField
          label="От кого жалоба"
          source="clientId"
          reference="users"
          link="show"
        >
          <FunctionField
            render={(record: any) =>
              record && `${record.firstName} ${record.lastName} id ${record.id}`
            }
          />
        </ReferenceField>
        <ReferenceField
          label="На кого жалоба"
          source="expertId"
          reference="users"
          link="show"
        >
          <FunctionField
            render={(record: any) =>
              record && `${record.firstName} ${record.lastName} id ${record.id}`
            }
          />
        </ReferenceField>
        <BooleanField source="wantRefund" label={"Хочу вернуть деньги"} />
        <SelectInput
          source={"complaintDecision"}
          label={"Решение"}
          choices={complaintDecision}
        />
      </SimpleForm>
    </Edit>
  );
};
