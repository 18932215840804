import React, { FC } from 'react';
import { BooleanField, DateField, ReferenceField,SelectField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { TransactionsTypeChoices } from '../index';

export const ShowTransactions: FC = (props: any) => {
    return (
        <Show {...props}
              hasList

        >
            <SimpleShowLayout>
                <TextField source={'id'} label={'id'}/>
                <ReferenceField reference={'payments'} source={'paymentId'} label={'Платеж'}>
                    <TextField source={'id'}/>
                </ReferenceField>
                <TextField source={'status'} label={'Статус'}/>
                <SelectField choices={TransactionsTypeChoices} source={'type'} label={'Тип'}/>
                <DateField source={'createdAt'} label={'Дата создания'} showTime/>
                <DateField source={'updatedAt'} label={'Дата обновления'} showTime/>
                <BooleanField source={'success'} label={'Успешно'}/>
            </SimpleShowLayout>
        </Show>
    );
};
