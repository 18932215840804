import {
    GetListParams,
    GetManyParams,
    GetOneParams, UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { UpdateUserTariffPlanDto } from '../../api/generated';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';


// @ts-ignore
export const userTariffPlansProvider = {

    async getList(params: GetListParams) {
        const query = nestQueryBuilder(params);
        const reqParams: Params = {
            page: params.pagination.page,
            limit: params.pagination.perPage,
            sort: query.getAll('sort'),
            filter: ['isExpert||$eq||true', ...query.getAll('filter')],
        };
        const {data}: any = await api.V1AdminUsersTariffPlansApi.getManyBaseAdminUsersTariffPlansControllerUser(
            reqParams.fields,
            reqParams.s,
            reqParams.filter,
            reqParams.or,
            reqParams.sort,
            reqParams.join,
            reqParams.limit,
            reqParams.offset,
            reqParams.page,
            reqParams.cache,
            reqParams.options
        );

        
        data.data = data.data.map((item: any) => {
            item.firstName = item.firstName? item.firstName : '—'; 
            item.lastName = item.lastName? item.lastName : '—';            
            item.tariffPlan = item.tariffPlan? item.tariffPlan : item.tariffPlan = {commissionPercent: '—'};
            return item;
        })

        return {
            data: data.data,
            total: data.total
        };
    },

    async getMany(params: GetManyParams) {
        const tariffPlans = [];
        for (let i = 0; i < params.ids.length; i++) {
            const id = params.ids[i];
            if (typeof id === 'number') {
                try {
                    const {data: tariffPlan} = await api.V1AdminUsersTariffPlansApi.getOneBaseAdminUsersTariffPlansControllerUser(id);
                    tariffPlans.push(tariffPlan);
                }catch (e) {
                    tariffPlans.push({});
                }
            }
        }
        return {data: tariffPlans};
    },

    async getOne(params: GetOneParams) {
        const reqParams: Params = {};

        const {data: tariffPlan} = await api.V1AdminUsersTariffPlansApi.getOneBaseAdminUsersTariffPlansControllerUser(
            parseInt(params.id.toString()),
            reqParams.fields,
            reqParams.join
        );

        return {
            data: tariffPlan
        };
    },

    async update(params: UpdateParams<UpdateUserTariffPlanDto>) {
        try {
            const {data: about} = await api.V1AdminUsersTariffPlansApi.adminUsersTariffPlansControllerUpdateUserTariffPlan(
                parseInt(params.id as string),
                params.data
            );
            return {
                data: about,
            };
        } catch (e) {
            return Promise.reject('Не удалось обновить тарифный план пользователя');
        }
    },
} as RouteDataProvider;
