import { TablePagination } from "@material-ui/core";
import { useListContext } from "ra-core";
import React, { FC, useCallback } from "react";

export const PaginationNps: FC = () => {
  const { total, page, perPage, setPage, setPerPage } = useListContext();

  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || page > total - 1) {
        return;
      }
      setPage(page + 1);
    },
    [total, setPage]
  );

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage]
  );

  return (
    <TablePagination
      count={total}
      page={page - 1}
      onChangeRowsPerPage={handlePerPageChange}
      rowsPerPageOptions={[1000, 5000]}
      labelRowsPerPage={"На странице: "}
      onPageChange={handlePageChange}
      rowsPerPage={perPage}
    />
  );
};
