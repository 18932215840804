import { API_BASE_URL } from '../constants';
import {
  V1AdminAboutApi,
  V1AdminAnswerNpsPollsApi,
  V1AdminCategoriesApi,
  V1AdminComplaintsApi,
  V1AdminConsultationsApi,
  V1AdminContentChangeRequestsApi,
  V1AdminContractsApi,
  V1AdminCustomRequestsApi,
  V1AdminExpertServicesApi,
  V1AdminInvoicesApi,
  V1AdminNewsApi,
  V1AdminNpsPollsApi,
  V1AdminPaymentsApi,
  V1AdminPortfoliosApi,
  V1AdminPromocodesApi,
  V1AdminQuestionsAndFeedbackApi,
  V1AdminReceiptsApi,
  V1AdminSuggestedCategoriesApi,
  V1AdminTariffPlansApi,
  V1AdminTransactionsApi,
  V1AdminUsersApi,
  V1AdminUsersTariffPlansApi,
  V1AdminVerificationRequestsApi,
  V1AdminWalletsApi,
  V1ChatsApi,
  V1RubricsApi,
  V1StorageApi,
  V1UsersApi,
  V1WithdrawalRequestApi,
} from './generated';
import httpClient from './httpClient';

export const api = {
  V1UsersApi: new V1UsersApi(undefined, API_BASE_URL, httpClient),
  V1AdminUsersApi: new V1AdminUsersApi(undefined, API_BASE_URL, httpClient),
  V1AdminVerificationRequestsApi: new V1AdminVerificationRequestsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminContentChangeRequestsApi: new V1AdminContentChangeRequestsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminConsultationsApi: new V1AdminConsultationsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1ChatsApi: new V1ChatsApi(undefined, API_BASE_URL, httpClient),
  V1AdminPromocodesApi: new V1AdminPromocodesApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminTariffPlansApi: new V1AdminTariffPlansApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminAboutApi: new V1AdminAboutApi(undefined, API_BASE_URL, httpClient),
  V1AdminTransactionsApi: new V1AdminTransactionsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminInvoicesApi: new V1AdminInvoicesApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminPaymentsApi: new V1AdminPaymentsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1WithdrawalRequestApi: new V1WithdrawalRequestApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminWalletsApi: new V1AdminWalletsApi(undefined, API_BASE_URL, httpClient),
  V1AdminUsersTariffPlansApi: new V1AdminUsersTariffPlansApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminCustomRequestsApi: new V1AdminCustomRequestsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminCategoriesApi: new V1AdminCategoriesApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1StorageApi: new V1StorageApi(undefined, API_BASE_URL, httpClient),
  V1AdminNewsApi: new V1AdminNewsApi(undefined, API_BASE_URL, httpClient),
  V1RubricsApi: new V1RubricsApi(undefined, API_BASE_URL, httpClient),
  V1AdminContractsApi: new V1AdminContractsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminReceiptsApi: new V1AdminReceiptsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminSuggestedCategoriesApi: new V1AdminSuggestedCategoriesApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminNpsPollsApi: new V1AdminNpsPollsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),

  V1AdminAnswerNpsPollsApi: new V1AdminAnswerNpsPollsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),

  V1AdminComplaintsApi: new V1AdminComplaintsApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminPortfoliosApi: new V1AdminPortfoliosApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminExpertServicesApi: new V1AdminExpertServicesApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
  V1AdminQuestionsAndFeedbackApi: new V1AdminQuestionsAndFeedbackApi(
    undefined,
    API_BASE_URL,
    httpClient
  ),
};
