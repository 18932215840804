import { FC, useCallback } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  List,
  NumberInput,
  ReferenceField,
  SelectField,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";

import { Receipt } from "../../../api/generated";
import Empty from "../../../components/empty";
import Pagination from "../../../components/pagination";
import { downloadCSV } from "../../../utils/download-csv";
import { ReceiptStatusChoices } from "..";

const ReceiptsFilters = (props: any) => (
  <Filter {...props}>
    <TextInput label={"Имя"} source={"user.firstName"} alwaysOn />
    <TextInput label={"Фамилия"} source={"user.lastName"} alwaysOn />
    <AutocompleteInput
      choices={ReceiptStatusChoices}
      source={"status"}
      label={"Статус"}
    />
    <NumberInput source={"userId"} label={"id  эксперта"} />
    <NumberInput source={"id"} label={"id"} />
    <DateTimeInput source={"from"} label={"С какой даты"} />
    <DateTimeInput source={"until"} label={"По какую дату"} />
  </Filter>
);

const exporter = async (receipt: Receipt[]) => {
  downloadCSV({
    filename: `receipt.csv`,
    data: receipt,
    columns: [
      "id",
      "user.id",
      "user.firstName",
      "user.lastName",
      "consultationId",
      "createdAt",
      "status",
      "receiptFileUrl",
    ],
    columnsNames: [
      "id",
      "id эксперта",
      "имя",
      "фамилия",
      "id консультации",
      "дата создания",
      "статус",
      "чек",
    ],
  });
};

export const ListReceipts: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve("show");
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      bulkActionButtons={false}
      empty={<Empty title="Нет чеков" />}
      perPage={50}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<ReceiptsFilters />}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={"id"} label={"id"} />
        <ReferenceField
          link={"show"}
          label={"Эксперт"}
          source="userId"
          reference="users"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField source="user.firstName" label={"Имя"} />
        <TextField source="user.lastName" label={"Фамилия"} />
        <ReferenceField
          link={"show"}
          label={"Консультация"}
          source="consultationId"
          reference="consultations"
        >
          <TextField source="id" />
        </ReferenceField>
        <SelectField
          choices={ReceiptStatusChoices}
          source={"status"}
          label={"Статус"}
        />
        <DateField source={"createdAt"} label={"Дата создания"} />
        <UrlField source={"receiptFileUrl"} label={"Чек"} />
      </Datagrid>
    </List>
  );
};
