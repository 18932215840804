import { PaymentOperationTypeEnum, PaymentStatusEnum } from '../../api/generated';

export {default as ListPayments } from './list-payments';
export {default as ShowPayments } from './show-payments';
export {default as EditPayments } from './edit-payments';

export const PaymentOperationTypeChoices = [
    {id: PaymentOperationTypeEnum.Refill, name: 'Пополнение'},
    {id: PaymentOperationTypeEnum.Transfer, name: 'Перевод'},
    {id: PaymentOperationTypeEnum.Revert, name: 'Отмена'},
    {id: PaymentOperationTypeEnum.Withdrawal, name: 'Вывод'},
];

export const PaymentStatusChoices = [
    {id: PaymentStatusEnum.Pending, name: 'Отправлен'},
    {id: PaymentStatusEnum.Fulfilled, name: 'Подтвержден'},
    {id: PaymentStatusEnum.Rejected, name: 'Отклонен'},
];
