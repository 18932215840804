import { api } from '../api';
import httpClient from '../api/httpClient';
import { getUrl } from './getUrl';

export const uploadFile = async (file: File) => {
    const { size, type } = file;
    if (size > 5242880) {
        return Promise.reject('Файл слишком большой')
    }
    if (type !== 'image/jpeg' && type !== 'image/png') {
        return Promise.reject('Некорректный формат')
    }
    const signRes = await api.V1StorageApi.storageControllerSignFileUrl({
        contentType: file.type,
        fileKey: file.name,
    });

    await httpClient.put(signRes.data, file, {
        headers: {
            'Content-Type': file.type,
            'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE',
            'Access-Control-Allow-Origin': '*',
        },
    });

    return getUrl(signRes.data);
};
