import {
  useShowController,
} from "ra-core";
import React, { FC } from "react";
import {
  DateField,
  NumberField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

import FullNameField from "../../../components/full-name-field";
import { PaymentOperationTypeChoices, PaymentStatusChoices } from "../index";

export const ShowPayments: FC = (props: any) => {
  const { record } = useShowController(props);

  return (
    <Show {...props} hasList>
      <SimpleShowLayout>
        <TextField source={"id"} label={"id"} />
        <TextField source={"invoiceId"} label={"id счета"} />
        <TextField source={"amount"} label={"Сумма"} />
        {record?.operationType === "withdrawal" && (
          <NumberField
            options={{ maximumFractionDigits: 1 }}
            source={"commission"}
            label={"Комиссия"}
          />
        )}
        <SelectField
          choices={PaymentOperationTypeChoices}
          label={"Тип"}
          source={"operationType"}
        />
        <SelectField
          choices={PaymentStatusChoices}
          label={"Статус"}
          source={"status"}
        />
        <DateField source={"createdAt"} label={"Дата создания"} />
        <DateField source={"updatedAt"} label={"Дата обновления"} />
        <ReferenceField
          reference={"users"}
          source={"payerWallet.owner.id"}
          label={"Плательщик"}
        >
          <FullNameField />
        </ReferenceField>
        <TextField source={"paymentMethod.pan"} label={"Номер карты"} />
        <ReferenceField
          reference={"users"}
          source={"recipientWallet.owner.id"}
          label={"Получатель"}
        >
          <FullNameField />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
