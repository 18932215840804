import React, { FC } from 'react';
import {
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { ProcessPortfolioDtoStatusEnum } from '../../../api/generated';
import Actions, { ActionsType } from '../../../components/actions';
import BigImageField from '../../../components/big-image-field/big-image-field';
import FullNameField from '../../../components/full-name-field';

export const portfolioStatusOptions = [
  {
    id: ProcessPortfolioDtoStatusEnum.InReview,
    name: 'На проверке',
  },
  {
    id: ProcessPortfolioDtoStatusEnum.Confirmed,
    name: 'Подтвержден',
  },
  {
    id: ProcessPortfolioDtoStatusEnum.Rejected,
    name: 'Отклонен',
  },
];

export const ShowPortfolio: FC = (props: any) => {
  return (
    <Show
      {...props}
      hasList
      hasEdit
      actions={<Actions noDelete type={ActionsType.show} />}
    >
      <SimpleShowLayout>
        <ReferenceField
          reference={'users'}
          source={'expertId'}
          label={'Эксперт'}
        >
          <FullNameField />
        </ReferenceField>
        <BigImageField source={'imageUrls[0]'} label={'Фото 1'} />
        <BigImageField source={'imageUrls[1]'} label={'Фото 2'} />
        <BigImageField source={'imageUrls[2]'} label={'Фото 3'} />
        <BigImageField source={'imageUrls[3]'} label={'Фото 4'} />
        <BigImageField source={'imageUrls[4]'} label={'Фото 5'} />
        <BigImageField source={'imageUrls[5]'} label={'Фото 6'} />
        <BigImageField source={'imageUrls[6]'} label={'Фото 7'} />
        <BigImageField source={'imageUrls[7]'} label={'Фото 8'} />
        <BigImageField source={'imageUrls[8]'} label={'Фото 9'} />
        <BigImageField source={'imageUrls[9]'} label={'Фото 10'} />
        <TextField source={'adminComment'} label={'Комментарий'} />
        <SelectField
          source={'status'}
          label={'Статус'}
          choices={portfolioStatusOptions}
        />
      </SimpleShowLayout>
    </Show>
  );
};
