import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionField } from 'react-admin';

import { ExpertStepDtoStepEnum } from '../../api/generated';

export const stepsTextMapping: Record<ExpertStepDtoStepEnum, string> = {
  [ExpertStepDtoStepEnum._0checkboxexpert]: 'Галочка при регистрации',
  [ExpertStepDtoStepEnum._1start]: 'Кнопка "Стать экспертом"',
  [ExpertStepDtoStepEnum._2photo]: 'Фото',
  [ExpertStepDtoStepEnum._3about]: 'О себе',
  [ExpertStepDtoStepEnum._4theme]: 'Темы',
  [ExpertStepDtoStepEnum._5profile]: 'Тип договора',
  [ExpertStepDtoStepEnum._6doc]: 'Оферта',
  [ExpertStepDtoStepEnum._7rek]: 'Договор',
  [ExpertStepDtoStepEnum._8done]: 'Готово!',
};

const useStyles = makeStyles({
  stepsField: { width: '450px', display: 'flex' },
});

export const ExpertStepsField: FC<any> = (props: any) => {
  const classNames = useStyles();

  return (
    <FunctionField
      className={classNames.stepsField}
      label={'Шаги эксперта'}
      render={(record: any) => {
        if (!record?.steps?.length) {
          return <p>Путь еще не начат</p>;
        }
        return (
          <div>
            {record.steps.map(
              (i: ExpertStepDtoStepEnum, id: number) =>
                `${stepsTextMapping[i]}${
                  id !== record.steps.length - 1 ? ' → ' : ''
                }`
            )}
          </div>
        );
      }}
      {...props}
    />
  );
};
