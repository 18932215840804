import PollIcon from "@mui/icons-material/Poll";

import { ListAnswerNpsPolls } from "./list-answer-nps-polls";

// import ReviewList from './ReviewList';

export default {
  icon: PollIcon,
  list: ListAnswerNpsPolls,
  options: {
    label: "NPS",
  },
};
