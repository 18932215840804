import { DataProvider } from 'ra-core';

import {
  consultationsDataProvider,
  contentChangeRequestDataProvider,
  promocodesDataProvider,
  usersDataProvider,
  verificationRequestsDataProvider,
  walletsDataProvider,
} from './routes-providers';
import { adminNewsProvider } from './routes-providers/admin-news-provider';
import { answerNpsPollsProvider } from './routes-providers/answer-nps-polls-provider';
import { categoriesProvider } from './routes-providers/categories-provider';
import { complaintsDataProvider } from './routes-providers/complaints-provider';
import { contractProvider } from './routes-providers/contract-provider';
import { customRequestProvider } from './routes-providers/custom-request-provider';
import { faqDataProvider } from './routes-providers/faq-data-provider';
import { feedbackDataProvider } from './routes-providers/feedback-provider';
import { invoicesDataProvider } from './routes-providers/invoices-provider';
import { paymentsDataProvider } from './routes-providers/payments-provider';
import { portfolioDataProvider } from './routes-providers/portfolio-provider';
import { receipstDataProvider } from './routes-providers/receipts-provider';
import { rubricProvider } from './routes-providers/rubric-provider';
import { servicesDataProvider } from './routes-providers/sevices-provider';
import { suggestedCategoriesProvider } from './routes-providers/suggested-categories-provider';
import { tariffPlansDataProvider } from './routes-providers/tariff-plans-provider';
import { transactionsDataProvider } from './routes-providers/transactions-provider';
import { userTariffPlansProvider } from './routes-providers/user-tariff-plans-provider';
import { withdrawalDataProvider } from './routes-providers/withdrawal-provider';
import { RouteDataProvider } from './types';

const resourceProviderMapping: Record<string, RouteDataProvider> = {
  users: usersDataProvider,
  experts: usersDataProvider,
  'verification-requests': verificationRequestsDataProvider,
  'content-change-request': contentChangeRequestDataProvider,
  consultations: consultationsDataProvider,
  promocodes: promocodesDataProvider,
  'tariff-plans': tariffPlansDataProvider,
  faq: faqDataProvider,
  transactions: transactionsDataProvider,
  payments: paymentsDataProvider,
  invoices: invoicesDataProvider,
  withdrawal: withdrawalDataProvider,
  wallets: walletsDataProvider,
  'user-tariff-plans': userTariffPlansProvider,
  'custom-requests': customRequestProvider,
  'admin-news': adminNewsProvider,
  categories: categoriesProvider,
  rubrics: rubricProvider,
  contracts: contractProvider,
  receipts: receipstDataProvider,
  'suggested-categories': suggestedCategoriesProvider,
  'answer-nps-polls': answerNpsPollsProvider,
  complaints: complaintsDataProvider,
  portfolio: portfolioDataProvider,
  services: servicesDataProvider,
  feedback: feedbackDataProvider
};

export const mainProvider = {
  getMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getMany(params);
  },
  getOne: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getOne(params);
  },
  create: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.create(params);
  },
  delete: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.delete(params);
  },
  getList: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getList(params);
  },
  getManyReference: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.getManyReference(params);
  },
  update: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.update(params);
  },
  updateMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    return provider.updateMany(params);
  },
  deleteMany: (resource, params) => {
    const provider = resourceProviderMapping[resource];
    if (provider.deleteMany) {
      return provider.deleteMany(params);
    }
  },
} as DataProvider;
