import React, { FC } from 'react';
import {
    Edit,
ReferenceField,     ReferenceInput,
    SelectInput, SimpleForm,
} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';
import FullNameField from '../../../components/full-name-field';


export const EditUsersTariffPlans: FC = (props: any) => {

    return (
        <Edit {...props}
              mutationMode="pessimistic"
              hasShow
              hasList
              actions={<Actions type={ActionsType.edit}/>}
        >
            <SimpleForm toolbar={<EditToolbar/>}>
                <ReferenceField reference={'users'} source={'id'} linkType={'show'}>
                    <FullNameField/>
                </ReferenceField>

                <ReferenceInput reference={'tariff-plans'} source={'tariffPlanId'} label={'id тарифного плана'}>
                        <SelectInput optionText="id" type={'text'}/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
