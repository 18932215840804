import React, { FC } from "react";
import {
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  UrlField,
} from "react-admin";

import { AdminUpdateVerificationRequestDtoStatusEnum } from "../../../api/generated";
import Actions, { ActionsType } from "../../../components/actions";
import FullNameField from "../../../components/full-name-field";

export const ShowVerificationRequest: FC = (props: any) => {
  return (
    <Show
      {...props}
      hasEdit
      hasList
      actions={<Actions type={ActionsType.show} />}
    >
      <SimpleShowLayout>
        <ReferenceField
          label={"Пользователь"}
          reference={"users"}
          source={"authorId"}
        >
          <FullNameField />
        </ReferenceField>
        <UrlField source={"videoUrl"} label={"Видеовизитка"} />
        {/* <UrlField source={"instagramUrl"} label={"Instagram"} />
        <UrlField source={"facebookUrl"} label={"Facebook"} /> */}
        <UrlField source={"telegramUrl"} label={"Telegram"} />
        <UrlField source={"vKontakteUrl"} label={"Vkontakte"} />
        <UrlField source={"odnoklassnikiUrl"} label={"Одноклассники"} />
        <UrlField source={"youtubeUrl"} label={"Youtube"} />
        <UrlField source={"diplomasUrl"} label={"Дипломы"} />
        <UrlField source={"reviewsUrl"} label={"Отзывы"} />
        <UrlField source={"personalSite"} label={"Личный сайт"} />
        <SelectField
          source={"status"}
          label={"Статус"}
          choices={[
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Pending,
              name: "На проверке",
            },
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Fulfilled,
              name: "Принят",
            },
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Rejected,
              name: "Отклонен",
            },
          ]}
        />
      </SimpleShowLayout>
    </Show>
  );
};
