import { InvoiceStatusEnum } from '../../api/generated';

export {default as ListInvoices } from './list-invoices';
export {default as ShowInvoices } from './show-invoices';

export const InvoicesStatusChoices = [
    {id: InvoiceStatusEnum.Expired, name: 'Истёк'},
    {id: InvoiceStatusEnum.Fulfilled, name: 'Подтвержден'},
    {id: InvoiceStatusEnum.Pending, name: 'Отправлен'},
    {id: InvoiceStatusEnum.Rejected, name: 'Отклонен'},
    {id: InvoiceStatusEnum.WaitingWithdrawal, name: 'Ожидает вывода'},
    {id: InvoiceStatusEnum.Withdrawled, name: 'Выведен'},
    {id: InvoiceStatusEnum.Reversed, name: 'Возвращен клиенту'},
];