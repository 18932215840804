import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';
import {
  ArrayInput,
  DateField,
  Edit,
  ReferenceField,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  UrlField,
} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import { ReceiptStatusChoices } from '../index';

const EditToolbar: FC = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label={'Сохранить'}
        redirect={'list'}
        disabled={props.pristine}
      />
    </Toolbar>
  );
};

export const EditReceipts: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      hasList
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <Accordion style={{ width: '100%' }}>
          <AccordionSummary>
            <Typography>Данные чека</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <SimpleShowLayout>
              <TextField fullWidth source={'id'} label={'id'} />
              <ReferenceField
                link={'show'}
                label={'Эксперт'}
                source='userId'
                reference='users'
              >
                <TextField source='id' />
              </ReferenceField>
              <ReferenceField
                link={false}
                label={'Имя'}
                source='userId'
                reference='users'
              >
                <TextField source='firstName' />
              </ReferenceField>
              <ReferenceField
                link={false}
                label={'Фамилия'}
                source='userId'
                reference='users'
              >
                <TextField source='lastName' />
              </ReferenceField>
              <ReferenceField
                link={'show'}
                label={'Консультация'}
                source='consultationId'
                reference='consultations'
              >
                <TextField source='id' />
              </ReferenceField>
              <DateField source={'createdAt'} label={'Дата создания'} />
              <UrlField source={'receiptFileUrl'} label={'Чек'} />
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>
        <SelectInput
          source={'status'}
          label={'Статус'}
          choices={ReceiptStatusChoices}
        />
        <ArrayInput source={'adminComments'} label={'Комментарий'}>
          <SimpleFormIterator>
            <TextInput multiline source='' label='Комментарий' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
