import {
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { ProcessCustomRequestsDto } from '../../api/generated';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const customRequestProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['author', 'promocode'],
    };
    const { data }: any =
      await api.V1AdminCustomRequestsApi.getManyBaseAdminCustomRequestsControllerCustomRequest(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const customRequests = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: customRequest } =
            await api.V1AdminCustomRequestsApi.getOneBaseAdminCustomRequestsControllerCustomRequest(
              id
            );
          customRequests.push(customRequest);
        } catch (e) {
          customRequests.push({});
        }
      }
    }
    return { data: customRequests };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {
      join: ['promocode'],
    };

    const { data: customRequest } =
      await api.V1AdminCustomRequestsApi.getOneBaseAdminCustomRequestsControllerCustomRequest(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );

    return {
      data: customRequest,
    };
  },

  async update(params: UpdateParams<any>) {
    const { data, previousData } = params;

    const dto: ProcessCustomRequestsDto = {
      status: data.status,
      comment: data.comment,
      promocodeId: data.promocode?.id,
    };

    if (data?.promocode?.id === previousData?.promocode?.id) {
      dto.promocodeId = undefined;
    }
    if (data.promocode === null) {
      dto.promocodeId = null;
    }

    try {
      const { data: customRequest } =
        await api.V1AdminCustomRequestsApi.adminCustomRequestsControllerProcessOne(
          parseInt(params.id as string),
          {
            ...dto,
          }
        );
      return {
        data: customRequest,
      };
    } catch (e: any) {
      return Promise.reject(
        e?.message || 'Не удалось обновить запрос на привилегии'
      );
    }
  },

  async delete(params: DeleteParams) {
    const id = params.id;
    if (typeof id === 'number') {
      const { data: customRequest } =
        await api.V1AdminCustomRequestsApi.getOneBaseAdminCustomRequestsControllerCustomRequest(
          id
        );
      const { status } =
        await api.V1AdminCustomRequestsApi.deleteOneBaseAdminCustomRequestsControllerCustomRequest(
          id
        );
      if (status === 200) {
        return { data: customRequest };
      }
    }
    return Promise.reject({ data: params.previousData });
  },
} as RouteDataProvider;
