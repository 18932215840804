import { ImageField, ImageFieldProps } from 'ra-ui-materialui';
import { useRecordContext } from 'react-admin';
import styled from 'styled-components';

const BigImageField = (props: ImageFieldProps) => {
  const { source, resource } = props;
  const record = useRecordContext(props);

  const getUrl = () => {
    switch (resource) {
      case 'contracts': {
        return source
          ? record[source?.split('.')[0]][source?.split('.')[1]]
          : '';
      }
      case 'portfolio': {
        return source ? record?.imageUrls[source[source.length - 2]] : '';
      }
      default: {
        return source ? record[source] : '';
      }
    }
  };

  return (
    <Container onClick={() => window.open(getUrl(), '_blank')}>
      <ImageField {...props} />
    </Container>
  );
};

export default BigImageField;

const Container = styled.div`
  cursor: pointer;
`;
