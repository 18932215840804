import React, {FC} from 'react';
import { BooleanInput, Create, DateTimeInput, NumberInput, SimpleForm } from 'react-admin';

import EditToolbar from '../../../components/edit-toolbar';

export const CreateTariffPlans: FC = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm toolbar={<EditToolbar/>} defaultValue={''} submitOnEnter={false}>
                <NumberInput source={'commissionPercent'} label={'Процент комиссии'}/>
                <DateTimeInput source={'startDate'}
                               label={'Начало'}
                />
                <DateTimeInput source={'endDate'}
                               label={'Конец'}
                />
                <BooleanInput source={'isDefault'} label={'По умолчанию'}/>
            </SimpleForm>
        </Create>
    );
};