import {
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { UpdateRubricDto } from '../../api/generated';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const rubricProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
    };
    const { data }: any =
      await api.V1RubricsApi.rubricsControllerGetManyRubrics(
        reqParams.limit,
        reqParams.page,
        undefined,
        undefined,
        reqParams.options
      );

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const customRequests = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: customRequest } =
            await api.V1RubricsApi.rubricsControllerGetManyRubrics(id);
          customRequests.push(customRequest);
        } catch (e) {
          customRequests.push({});
        }
      }
    }
    return { data: customRequests };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {
      join: ['rubrics', 'rootCategory'],
    };
    const { data: customRequest } =
      await api.V1RubricsApi.rubricsControllerGetOneRubricCategories(
        parseInt(params.id.toString()),
        reqParams.fields
      );
    return {
      data: customRequest,
    };
  },

  async update(params: UpdateParams<UpdateRubricDto>) {
    const { data } = await api.V1RubricsApi.rubricsControllerUpdateOneCategoriy(
      parseInt(params.id.toString()),
      { ...params.data }
    );
    return {
      data,
    };
  },
} as RouteDataProvider;
