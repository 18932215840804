import React, { FC } from 'react';
import {
  Edit,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { AdminUpdateVerificationRequestDtoStatusEnum } from '../../../api/generated';
import Actions, { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';
import FullNameField from '../../../components/full-name-field';

export const EditVerificationRequest: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      hasShow
      hasList
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceField
          label={'Пользователь'}
          reference={'users'}
          source={'authorId'}
        >
          <FullNameField />
        </ReferenceField>
        <TextInput source={'videoUrl'} label={'Видеовизитка'} />
        <TextInput source={'commentVideoUrl'} label={'Комментарий'} />
        {/* <TextInput source={"instagramUrl"} label={"Instagram"} />
        <TextInput source={"commentInstagramUrl"} label={"Комментарий"} />
        <TextInput source={"facebookUrl"} label={"Facebook"} />
        <TextInput source={"commentFacebookUrl"} label={"Комментарий"} /> */}

        <TextInput source={'telegramUrl'} label={'Telegram'} />
        <TextInput source={'commentTelegramUrl'} label={'Комментарий'} />
        <TextInput source={'vKontakteUrl'} label={'Vkontakte'} />
        <TextInput source={'commentVKontakteUrl'} label={'Комментарий'} />
        <TextInput source={'odnoklassnikiUrl'} label={'Одноклассники'} />
        <TextInput source={'commentOdnoklassnikiUrl'} label={'Комментарий'} />
        <TextInput source={'youtubeUrl'} label={'Youtube'} />
        <TextInput source={'commentYoutubeUrl'} label={'Комментарий'} />

        <TextInput source={'diplomasUrl'} label={'Дипломы'} />
        <TextInput source={'commentDiplomasUrl'} label={'Комментарий'} />
        <TextInput source={'reviewsUrl'} label={'Отзывы'} />
        <TextInput source={'commentReviewsUrl'} label={'Комментарий'} />
        <TextInput source={'personalSite'} label={'Личный сайт'} />
        <TextInput source={'commentPersonalSite'} label={'Комментарий'} />
        <SelectInput
          source={'status'}
          label={'Статус'}
          choices={[
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Pending,
              name: 'На проверке',
            },
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Fulfilled,
              name: 'Принят',
            },
            {
              id: AdminUpdateVerificationRequestDtoStatusEnum.Rejected,
              name: 'Отклонен',
            },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};
