import { GetListParams } from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const answerNpsPollsProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['user'],
    };
    const { data } =
      await api.V1AdminAnswerNpsPollsApi.getManyBaseAdminAnswerNpsPollsControllerAnswerNpsPoll(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    const mappedEntries = data.data.map((item) => ({
      id: item.id,
      createdAt: item.createdAt ? item.createdAt : '—',
      rating: item.rating ? item.rating : '0',
      text: item.text ? item.text : '—',
      isExpert: item.user?.isExpert ? 'Да' : 'Нет',
      userId: item.userId ? item.userId : '—',
    }));

    return {
      data: mappedEntries,
      total: data.total,
    };
  },
} as RouteDataProvider;
