import {
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { Params, ParamsGetSingle } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const verificationRequestsDataProvider = {
  async delete(params: DeleteParams) {
    const id = params.id;
    if (typeof id === 'number') {
      const { data: request } =
        await api.V1AdminVerificationRequestsApi.getOneBaseAdminVerificationRequestsControllerVerificationRequest(
          id
        );
      const { status } =
        await api.V1AdminVerificationRequestsApi.deleteOneBaseAdminVerificationRequestsControllerVerificationRequest(
          id
        );
      if (status === 200) {
        return { data: request };
      }
    }
    return Promise.reject({ data: params.previousData });
  },

  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);

    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['author'],
    };
    const { data }: any =
      await api.V1AdminVerificationRequestsApi.getManyBaseAdminVerificationRequestsControllerVerificationRequest(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );
    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const requests = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      const reqParams: ParamsGetSingle = {
        id: +params.ids[i],
        fields: undefined,
        join: ['author'],
        cache: undefined,
      };
      if (typeof id === 'number') {
        try {
          const request =
            await api.V1AdminVerificationRequestsApi.getOneBaseAdminVerificationRequestsControllerVerificationRequest(
              reqParams.id,
              reqParams.fields,
              reqParams.join,
              reqParams.cache
            );
          requests.push(request);
        } catch (e) {
          requests.push({});
        }
      }
    }

    return { data: requests };
  },

  async getOne(params: GetOneParams) {
    const { data } =
      await api.V1AdminVerificationRequestsApi.getOneBaseAdminVerificationRequestsControllerVerificationRequest(
        parseInt(params.id.toString())
      );
    return { data: data };
  },

  async update(params: UpdateParams) {
    const id = params.data.id;
    if (typeof id === 'number') {
      const { data: request } =
        await api.V1AdminVerificationRequestsApi.adminVerificationRequestsControllerProcessingVerificationRequest(
          id,
          { ...params.data }
        );
      return {
        data: request,
      };
    }
    return Promise.reject();
  },
} as RouteDataProvider;
