import React, { FC } from 'react';
import {
  Edit,
  NumberField,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
} from 'react-admin';

import { FullNameFieldWithId } from '../../../components/full-name-field-with-id';

export const ServicesEdit: FC = (props: any) => {
  const notify = useNotify();
  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      onSuccess={() => notify('Услуга эксперта обновлена', 'success')}
    >
      <SimpleForm>
        <TextField source={'id'} label={'id'} />
        <TextInput source={'title'} label={'Название'} />
        <NumberField source={'price'} label={'Стоимость'} />
        <ReferenceField
          source={'userId'}
          linkType={'show'}
          label={'Эксперт'}
          reference={'users'}
        >
          <FullNameFieldWithId />
        </ReferenceField>
      </SimpleForm>
    </Edit>
  );
};
