import React, { FC, useCallback } from 'react';
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    DateTimeInput,
    Filter,
    List,
    ReferenceField,
    SelectField,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';

import { Transaction } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';
import { TransactionsTypeChoices } from '../index';

const exporter = async (transactions: Transaction[]) => {        
    downloadCSV({ 
        filename: `transactions.csv`,
        data: transactions,
        columns: [
            'id',
            'paymentId',
            'status',
            'type',
            'createdAt',
            'updatedAt',
            'success'
        ],
        columnsNames: [
          "id",
          "номер платежа",
          "статус",
          "тип",
          "дата создания",
          "дата обновления",
          "является успешным"
      ]
      })
  };

const TransactionsFilters = (props: any) => (
    <Filter {...props}>
        <SelectInput  choices={TransactionsTypeChoices} source={'type'} label={'Тип'}/>
        <BooleanInput source={'success'} label={'Успешно'}/>
        <DateTimeInput source={'from'} label={'С какой даты'}/>
        <DateTimeInput source={'until'} label={'По какую дату'}/>
        <TextInput source={'status'} label={'Статус'}/>
    </Filter>
);

export const ListTransactions: FC = (props: any) => {
    const rowClick = useCallback(() => (id: any, basePath: any, record: any) => {
        return Promise.resolve('show');
    }, []);

    return(
        <List {...props}
              pagination={<Pagination/>}
              bulkActionButtons={false}
              filters={<TransactionsFilters/>}
              exporter={exporter}
              sort={{field: 'updatedAt', order: 'DESC'}}
              perPage={50}
              hasShow
        >
            <Datagrid rowClick={rowClick()}>
                <TextField source={'id'} label={'id'}/>
                <ReferenceField reference={'payments'} source={'paymentId'}  label={'Платеж'}>
                    <TextField source={'id'}/>
                </ReferenceField>
                <TextField source={'status'} label={'Статус'}/>
                <SelectField choices={TransactionsTypeChoices} source={'type'} label={'Тип'}/>
                <DateField source={'createdAt'} label={'Дата создания'} showTime/>
                <DateField source={'updatedAt'} label={'Дата обновления'} showTime/>
                <BooleanField source={'success'} label={'Успешно'}/>
            </Datagrid>
        </List>
    );
};
