import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import {
    ArrayField,
    Datagrid,
    Show,
    SimpleShowLayout,
    TextField,
} from 'react-admin';

const AnswersField: FC = (props: any) => {
    const {record: {answer}} = props;
    return (
        <span>{answer.join(' ')}</span>
    );
};

export const ShowFaq: FC = (props: any) => {
    return (
        <Show
            {...props}
        >
            <>
                <Accordion>
                    <AccordionSummary>
                        <Typography>FAQ</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SimpleShowLayout>
                            <ArrayField source={'faq'}>
                                <Datagrid>
                                    <TextField source={'question'} label={'Вопрос'}/>
                                    <AnswersField/>
                                </Datagrid>
                            </ArrayField>
                        </SimpleShowLayout>
                    </AccordionDetails>
                </Accordion>


                {/* TODO remove comments when data appears
                <Accordion>
                    <AccordionSummary>
                        <Typography>Правила</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SimpleShowLayout>
                            <Typography>Политика конфиденциальности</Typography>
                            <Datagrid>
                                <TextField source={'privacyPolicy.title'} label={'Заголовок'}/>
                                <TextField source={'privacyPolicy.body'} label={'Тело'}/>
                            </Datagrid>

                            <Typography>Лицензионное соглашение</Typography>
                            <Datagrid>
                                <TextField source={'licenseAgreement.title'} label={'Заголовок'}/>
                                <TextField source={'licenseAgreement.body'} label={'Тело'}/>
                            </Datagrid>

                            <Typography>Правила использования</Typography>
                            <Datagrid>
                                <TextField source={'termsOfUse.title'} label={'Заголовок'}/>
                                <TextField source={'termsOfUse.body'} label={'Тело'}/>
                            </Datagrid>
                        </SimpleShowLayout>
                    </AccordionDetails>
                </Accordion>*/}
            </>
        </Show>
    );
};