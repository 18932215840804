import { ReceiptStatusEnum } from "../../api/generated";

export { ListReceipts } from './list-receipts';
export {EditReceipts} from './edit-receipts';
export {ShowReceipts} from './show-receipts';


export const ReceiptStatusChoices = [
    {id: ReceiptStatusEnum.ModerationRejected, name: 'Отклонен'},
    {id: ReceiptStatusEnum.WaitingModeration, name: 'Ожидает'},
    {id: ReceiptStatusEnum.PassedModeration, name: 'Прошел проверку'},
];