import React, { FC } from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import BigImageField from '../../../components/big-image-field/big-image-field';

export const ShowThemes: FC = (props: any) => {
  return (
    <Show
      {...props}
      actions={<Actions type={ActionsType.show} />}
      hasList
      hasEdit
      basePath={'custom-requests'}
    >
      <SimpleShowLayout>
        <TextField source={'id'} label={'id'} />
        <TextField source={'name'} label={'Название'} />
        <BigImageField source={'iconUrl'} label={'Иконка'} />
        <TextField source={'expertsCount'} label={'Кол-во экспертов'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <TextField source={'SEODescription'} label={'SEO Описание'} />
      </SimpleShowLayout>
    </Show>
  );
};
