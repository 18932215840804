import React, { FC } from 'react';
import {
  DateTimeInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';

import { CreatePromocodeDtoTypeEnum } from '../../../api/generated';
import Actions, { ActionsType } from '../../../components/actions';

export const EditPromocode: FC = (props) => {
  const notify = useNotify();
  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      onSuccess={() => notify('Промокод обновлен', 'success')}
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm>
        <SelectInput
          source={'type'}
          label={'Тип'}
          choices={[
            {
              id: CreatePromocodeDtoTypeEnum.FreeConsultation,
              name: 'Бесплатная консультация',
            },
          ]}
        />
        <TextInput source={'title'} label={'Заголовок'} />
        <TextInput source={'code'} label={'Код'} />

        <ReferenceInput
          reference={'custom-requests'}
          source={'customRequest.id'}
          label={'ID Заявки'}
          allowEmpty
          filter={{ promocodeId: null }}
        >
          <SelectInput optionText='id' />
        </ReferenceInput>
        <DateTimeInput source={'expiresIn'} label={'Истекает'} />
      </SimpleForm>
    </Edit>
  );
};
