import React, { FC } from 'react';
import {
  Create,
  DateTimeInput,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin';

import { ContentInput } from '../../../components/content-input';
import { AdminNewsToWhomChoices } from '..';

export const CreateAdminNews: FC = (props: any) => {
  const requiredField = required();
  return (
    <Create
      {...props}
      onFailure={(error) => {
        return error;
      }}
    >
      <SimpleForm>
        <ImageInput
          source={'imageUrl'}
          label={'Изображение'}
          multiple={false}
          accept={'image/jpeg,image/png'}
          maxSize={5242880}
        >
          <ImageField source={'imageUrl'} />
        </ImageInput>
        <ContentInput
          label={'Сообщение'}
          source='content'
          validate={requiredField}
        />
        <SelectInput
          source={'toWhom'}
          label={'Кому'}
          choices={AdminNewsToWhomChoices}
          validate={requiredField}
        />
        <DateTimeInput
          source={'scheduledTime'}
          label={'Запланированное время'}
        />
      </SimpleForm>
    </Create>
  );
};
