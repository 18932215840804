import { SvgIconProps } from '@material-ui/core';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, {FC, ReactElement, useState} from 'react';
import { Confirm, DeleteButton, EditButton, ListButton, ShowButton, TopToolbar, useDelete, useRedirect } from 'react-admin';

export enum ActionsType{
    edit = 'edit',
    show = 'show',
    list = 'list',
}

interface ActionsProps{
    type: ActionsType;
    hasEdit?: boolean;
    hasShow?: boolean;
    hasList?: boolean;
    noDelete?: boolean;
}

export const Actions: FC<ActionsProps> = ({type, hasList, hasEdit, hasShow, noDelete, ...props}) => {
    const {basePath, data, resource}: any = props;
    const redirect = useRedirect();

    const [isOpen, setIsOpen] = useState(false);

    const [deleteOne, {loading, error}] = useDelete();

    const toggleIsOpen = () => {
        setIsOpen(s => !s);
    };

    const onConfirm = () => {
        deleteOne(basePath, data.id, data);
        toggleIsOpen();
        redirect(`/${basePath}`, 'list');
    }



    let deleteIcon: ReactElement<SvgIconProps> = <DeleteForeverIcon />; 
    return(
        <TopToolbar>
            <Confirm
                isOpen={isOpen}
                onConfirm={onConfirm}
                onClose={toggleIsOpen}
                content={'Вы уверены что, хотите удалить?'}
                title={'Необходимо подтверждение'}
                confirm={'Подтвердить'}
                cancel={'Отмена'}
                />
            {type !== ActionsType.edit && hasEdit && (
                <EditButton label={'Изменить'} record={data}/>
            )}
            {type !== ActionsType.list && hasList &&(
                <ListButton label={'Показать всех'}/>
            )}
            {type !== ActionsType.show && hasShow && (
                <ShowButton label={'Показать'}  basePath={basePath} record={data}/>
            )}
            {type !== ActionsType.list && !noDelete &&(
                <DeleteButton
                    record={data}
                    resource={resource}
                    mutationMode="pessimistic"
                    label={'Удалить'}
                    confirmContent={'Вы уверены, что хотите удалить запись?'}
                    confirmTitle={'Удалить'}

                />
                // <Button icon={deleteIcon} label={'Удалить'} onClick={toggleIsOpen} style={{color: 'indianred'}}/>
            )}
        </TopToolbar>
    );
};
