import React, { FC } from 'react';
import {
  DateField,
  Edit,
  ImageField,
  ReferenceField,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
} from 'react-admin';

import Actions from '../../../components/actions';
import { ActionsType } from '../../../components/actions';
import EditToolbar from '../../../components/edit-toolbar';
import FullNameField from '../../../components/full-name-field';
import { ContractStatusChoices, ContractTypeChoices } from '../index';

export const EditContract: FC = (props: any) => {
  return (
    <Edit
      {...props}
      mutationMode='pessimistic'
      hasShow
      hasList
      actions={<Actions type={ActionsType.edit} />}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceField
          source={'ownerId'}
          label={'Пользователь'}
          reference={'users'}
        >
          <FullNameField />
        </ReferenceField>
        <SelectField
          choices={ContractTypeChoices}
          source={'type'}
          label={'Тип'}
        />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <UrlField source={'pdfUrl'} label={'Ссылка на pdf'} />

        <TextField source={'payload.fullName'} label={'ФИО'} />
        <TextField source={'payload.inn'} label={'ИНН'} />

        <TextField
          source={'payload.contactPhone'}
          label={'Контактный телефон'}
        />
        <TextField source={'payload.email'} label={'Email'} />

        <DateField source={'payload.birthDate'} label={'Дата рождения'} />

        <TextField source={'payload.passportSeries'} label={'Серия паспорта'} />
        <TextField source={'payload.passportNumber'} label={'Номер паспорта'} />
        <DateField
          source={'payload.passportWhenGiven'}
          label={'Дата выдачи паспорта'}
        />
        <TextField
          source={'payload.residencePlaceRegistrationAddress'}
          label={'Адрес регистрации по месту жительства'}
        />
        <TextField
          source={'payload.physicalAddress'}
          label={'Физический адрес'}
        />

        <TextField
          source={'payload.passportIssuedBy'}
          label={'Паспорт выдан'}
        />

        <ImageField
          source={'payload.passportRegistrationPageUrl'}
          label={'Регистрация'}
        />
        <ImageField
          source={'payload.passportSecondAndThirdPageUrl'}
          label={'Первая и вторая страница'}
        />
        <ImageField
          source={'payload.selfieWithPassportSecondAndThirdPageUrl'}
          label={'Селфи с паспортом'}
        />
        <SelectInput
          source={'status'}
          label={'Статус'}
          choices={ContractStatusChoices}
        />
        <TextInput source={'comment'} label={'Комментарий'} />
      </SimpleForm>
    </Edit>
  );
};
