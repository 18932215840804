import React, { FC, useCallback } from "react";
import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  TextField,
  TextInput,
} from "react-admin";

import { Rubric } from "../../../api/generated";
import Pagination from "../../../components/pagination";
import { downloadCSV } from "../../../utils/download-csv";

const exporter = async (rubric: Rubric[]) => {
  downloadCSV({
    filename: `rubrics.csv`,
    data: rubric,
    columns: ["id", "name", "iconUrl", "createdAt"],
    columnsNames: ["id", "название", "ссылка на иконку", "дата создания"],
  });
};

export const ListThemes: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve("show");
    },
    []
  );

  const CategoriesFilters = (props: any) => (
    <Filter {...props}>
      <TextInput source={"name"} label={"Название"} />
    </Filter>
  );
  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<CategoriesFilters />}
      perPage={50}
      sort={{ field: "expertsCount", order: "DESC" }}
      exporter={exporter}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={rowClick()} hasBulkActions={false}>
        <TextField source={"id"} label={"id"} />
        <TextField source={"name"} label={"Название"} />
        <ImageField source={"iconUrl"} label={"Иконка"} />
        <DateField source={"createdAt"} label={"Дата создания"} />
      </Datagrid>
    </List>
  );
};
