import { GetListParams, GetManyParams, GetOneParams } from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const paymentsDataProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);
    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: ['payerWallet', 'recipientWallet','payerWallet.owner', 'recipientWallet.owner'],
    };
    const { data }: any =
      await api.V1AdminPaymentsApi.getManyBaseAdminPaymentsControllerPayment(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );
      
    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const payments = [];
    for (let i = 0; i < params.ids.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: payment } =
            await api.V1AdminPaymentsApi.getOneBaseAdminPaymentsControllerPayment(
              id
            );
          payments.push(payment);
        } catch (e) {
          payments.push({});
        }
      }
    }
    return { data: payments };
  },

  async getOne(params: GetOneParams) {
    const reqParams: Params = {
      join: ['payerWallet', 'recipientWallet','payerWallet.owner', 'recipientWallet.owner','paymentMethod'],
    };

    const { data: payment } =
      await api.V1AdminPaymentsApi.getOneBaseAdminPaymentsControllerPayment(
        parseInt(params.id.toString()),
        reqParams.fields,
        reqParams.join
      );

    return {
      data: payment,
    };
  },
} as RouteDataProvider;
