import { Button, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useLogin, useNotify } from 'ra-core';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { api } from '../../api';
import { isNotPhone } from '../../utils/isNotPhone';

export interface LoginFormValues {
  phone: string;
  sms: string;
}

export const LoginForm: FC = () => {
  const login = useLogin();
  const notify = useNotify();

  const [isCodeSend, setIsCodeSend] = useState<boolean>(false);
  const [currentPhone, setCurrentPhone] = useState<string>('a');

  const { handleSubmit, control, setError, reset, watch } =
    useForm<LoginFormValues>({
      defaultValues: {
        phone: '',
        sms: '',
      },
    });

  const phoneValue = watch('phone');

  const onSendCode = async ({ phone }: LoginFormValues) => {
    try {
      const { data } = await api.V1UsersApi.usersControllerSendSms({
        phone: phone,
      });
      setIsCodeSend(true);
      setCurrentPhone(phone);
    } catch (e) {
      setError('phone', {
        message: 'Не удалось отправить код, попробуйте еще раз',
      });
    }
  };

  const onSubmit = (values: LoginFormValues) => {
    login(values).catch(() => {
      notify('Не удалось войти в аккаунт');
      reset();
    });
  };

  return (
    <Grid
      container
      direction={'column'}
      spacing={1}
      style={{
        padding: 10,
        gap: 20,
      }}
    >
      <Controller
        control={control}
        name={'phone'}
        rules={{
          validate: isNotPhone(),
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            fullWidth
            type={'number'}
            error={!!error}
            helperText={error?.message}
            label={'Номер'}
            variant={'standard'}
            title={'Номер'}
          />
        )}
      />
      {isCodeSend && currentPhone === phoneValue && (
        <Controller
          control={control}
          name={'sms'}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              type={'number'}
              variant={'standard'}
              label={'Смс-код'}
            />
          )}
        />
      )}
      <ButtonContainer>
        {(!isCodeSend || currentPhone !== phoneValue) && (
          <Button onClick={handleSubmit(onSendCode)}>Отправить код</Button>
        )}
        {isCodeSend && currentPhone === phoneValue && (
          <Button onClick={handleSubmit(onSubmit)}>Войти</Button>
        )}
        {isCodeSend && currentPhone === phoneValue && (
          <Button onClick={handleSubmit(onSendCode)}>Отправь еще раз</Button>
        )}
      </ButtonContainer>
    </Grid>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
