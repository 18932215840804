import React, { FC } from "react";
import { useRecordContext } from "react-admin";

export const FullNameFieldWithId: FC<any> = (props: any) => {
  const record = useRecordContext(props);

  return (
    <span>{`${record.firstName} ${record.lastName}, id ${record.id}`}</span>
  );
};
