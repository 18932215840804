import { FC } from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

import { FullNameFieldWithId } from "../../../components/full-name-field-with-id";
import Pagination from "../../../components/pagination";

export const ListSuggested: FC = (props: any) => {
  return (
    <List
      {...props}
      hasShow
      pagination={<Pagination />}
      hasEdit
      sort={{ field: "createdAt", order: "DESC" }}
      bulkActionButtons={false}
      perPage={50}
      syncWithLocation={true}
    >
      <Datagrid hasBulkActions={false}>
        <TextField
          source={"suggestedCategoryName"}
          label={"Предложенная тема"}
        />
        <DateField
          source={"createdAt"}
          label={"Дата создания"}
          options={{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }}
        />
        <TextField source={"source"} label={"Источник"} />
        <ReferenceField
          source={"userId"}
          linkType={"show"}
          label={"Пользователь"}
          reference={"users"}
        >
          <FullNameFieldWithId />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
