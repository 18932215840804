import { FC, useCallback } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  List,
  SelectField,
  TextField,
  TextInput,
} from 'react-admin';

import { Invoice } from '../../../api/generated';
import Pagination from '../../../components/pagination';
import { downloadCSV } from '../../../utils/download-csv';
import { InvoicesStatusChoices } from '../index';

const exporter = async (invoice: Invoice[]) => {        
  downloadCSV({ 
      filename: `invoices.csv`,
      data: invoice,
      columns: [
        'id',
        'amount',
        'author.firstName',
        'author.lastName',
        'status',
        'isFree',
        'createdAt',
        'expiresIn',
        'updatedAt',
      ],
      columnsNames: [
        "id",
        "сумма",
        "имя",
        "фамилия",
        "статус",
        "является бесплатной",
        "дата создания",
        "дата истечения",
        "дата обновления"
    ]
    })
};

const InvoicesFilters = (props: any) => (
  <Filter {...props}>
    <TextInput source={'author.firstName'} label={'Имя'} />
    <TextInput source={'author.lastName'} label={'Фамилия'} />
    <DateTimeInput source={'from'} label={'С какой даты'} showTime/>
    <DateTimeInput source={'until'} label={'По какую дату'} showTime/>
  </Filter>
);

export const ListInvoices: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      return Promise.resolve('show');
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<InvoicesFilters />}
      bulkActionButtons={false}
      perPage={50}
      sort={{field: 'updatedAt', order: 'DESC'}}
      exporter={exporter}
      hasShow
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={'id'} label={'id'} />
        <TextField source={'amount'} label={'Сумма'} />
        <TextField source={'author.firstName'} label={'Имя'} />
        <TextField source={'author.lastName'} label={'Фамилия'} />
        <TextField source={'purposeOfTransfer'} label={'Назначение платежа'} sortable={false}/>
        <SelectField
          choices={InvoicesStatusChoices}
          source={'status'}
          label={'Статус'}
        />
        <BooleanField source={'isFree'} label={'Бесплатно'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <DateField source={'expiresIn'} label={'Истекает'} />
        <DateField source={'updatedAt'} label={'Дата обновления'} />
      </Datagrid>
    </List>
  );
};
