import {
  CustomRequestStatusEnum,
  CustomRequestTypeEnum,
} from "../../api/generated";

export { default as EditCustomRequest } from "./edit-custom-request";
export { default as ShowCustomRequest } from "./show-custom-request";
export { default as ListCustomRequest } from "./list-custom-request";

export const CustomRequestTypeChoices = [
  {
    id: CustomRequestTypeEnum.PersonalPromocode,
    name: "Персональный промокод",
  },
  { id: CustomRequestTypeEnum.SpecialTariff, name: "Специальный тариф" },
];

export const CustomRequestStatusChoices = [
  { id: CustomRequestStatusEnum.Pending, name: "Запрошен" },
  { id: CustomRequestStatusEnum.Fulfilled, name: "Подтвержден" },
  { id: CustomRequestStatusEnum.Rejected, name: "Отклонен" },
];
