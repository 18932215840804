import React, { FC, useCallback } from "react";
import {
  BooleanField,
  Datagrid,
  List,
  SelectField,
  TextField,
} from "react-admin";

import Pagination from "../../../components/pagination";
import { complaintDecision } from "..";

export const ListComplaints: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, "_blank");
      return Promise.resolve("");
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid rowClick={rowClick()}>
        <TextField source={"feedback"} label={"Текст жалобы"} />
        <TextField source={"date"} label={"Дата создания"} sortable={false} />
        <TextField
          source={"consultation"}
          label={"Консультация"}
          sortable={false}
        />
        <TextField
          source={"objComplaint"}
          label={"От кого жалоба"}
          sortable={false}
        />
        <TextField
          source={"subComplaint"}
          label={"На кого жалоба"}
          sortable={false}
        />
        <BooleanField source="wantRefund" label={"Хочу вернуть деньги"} />
        <SelectField
          source="complaintDecision"
          label={"Решение"}
          choices={complaintDecision}
        />
      </Datagrid>
    </List>
  );
};
