import React, {FC} from 'react';
import { BooleanField, DateField, SelectField, Show, SimpleShowLayout, TextField } from 'react-admin';

import { InvoicesStatusChoices } from '../index';

export const ShowInvoices: FC = (props: any) => {
    return (
        <Show {...props} hasList hasEdit >
            <SimpleShowLayout>
                <TextField source={'id'} label={'id'}/>
                <TextField source={'amount'} label={'Сумма'}/>
                <TextField source={'author.firstName'} label={'Имя'} />
                <TextField source={'author.lastName'} label={'Фамилия'} />
                <SelectField choices={InvoicesStatusChoices} source={'status'} label={'Статус'}/>
                <BooleanField source={'isFree'} label={'Бесплатно'}/>
                <DateField source={'createdAt'} label={'Дата создания'} showTime/>
                <DateField source={'expiresIn'} label={'Истекает'} showTime/>
                <DateField source={'updatedAt'} label={'Дата обновления'} showTime/>
            </SimpleShowLayout>
        </Show>
    );
};
