import React, { FC } from "react";
import {
  BooleanField,
  DateField,
  EmailField,
  FunctionField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

import Actions, { ActionsType } from "../../../components/actions";
import TitleUser from "../../../components/title-user";
import { calculatePeriod } from "../../../utils/calculate-period";
import { ReasonRejectedChoices } from "..";

export const ShowExpert: FC = (props: any) => {
  return (
    <Show
      {...props}
      title={<TitleUser />}
      actions={<Actions type={ActionsType.show} />}
      hasList
      hasEdit
      basePath={"users"}
    >
      <SimpleShowLayout>
        <TextField source={"firstName"} label={"Имя"} />
        <TextField source={"lastName"} label={"Фамилия"} />
        <TextField source={"age"} label={"Возраст"} sortable={false} />
        <TextField source={"phone"} label={"Телефон"} />
        <EmailField source={"email"} label={"Email"} />
        <TextField source={"id"} label={"id"} />
        <DateField source={"createdAt"} label={"Дата создания"} />
        <TextField
          source={"clientConsultationsCount"}
          label={"кол-во консультаций"}
        />
        <TextField
          source={"expertConsultationsCount"}
          label={"Э - Консультаций"}
        />
        <TextField source={"totalEarned"} label={"Э - Выручка"} />
        <BooleanField source={"isApproved"} label={"Подтвержденый"} />
        <BooleanField source={"isContractAccepted"} label={"Э - договор"} />
        <BooleanField source={"isProfileVerified"} label={"Э - верификация"} />
        <BooleanField
          source={"isSpecialTarif"}
          label={"Э - получен тариф"}
          sortable={false}
        />
        <BooleanField source={"hasFreeChat"} label={"Э - бесплатные чаты"} />
        <FunctionField
          render={(record: any) => calculatePeriod(record.lastExitTime)}
          label={"Дней с последнего визита"}
        />
        <BooleanField source={"isExpertContacted"} label={"Скрипт - WA/TG"} />
        <SelectField
          source={"expertRejection"}
          label={"Причина отказа"}
          choices={ReasonRejectedChoices}
        />
        <TextField
          source={"commentExpertRejection"}
          label={"Комментарий причины отказа"}
        />
      </SimpleShowLayout>
    </Show>
  );
};
