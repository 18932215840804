import {
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetOneParams,
  UpdateParams,
} from 'ra-core';

import { api } from '../../api';
import { Params } from '../../types';
import { calculateAge } from '../../utils/calculate-age';
import { getTimeToBecomeAnExpert } from '../../utils/calculateTimeToBecomeExpert';
import { defineConfirmed } from '../../utils/defineConfirmed';
import { defineIsSpecialTarif } from '../../utils/defineIsSpecialTarif';
import { defineVeryfied } from '../../utils/defineVeryfied';
import { nestQueryBuilder } from '../../utils/queryBuilder';
import { RouteDataProvider } from '../types';

// @ts-ignore
export const usersDataProvider = {
  async delete(params: DeleteParams) {
    const id = params.id;
    if (typeof id === 'number') {
      const { data: user } =
        await api.V1AdminUsersApi.getOneBaseAdminUsersControllerUser(id);
      const { status } =
        await api.V1AdminUsersApi.deleteOneBaseAdminUsersControllerUser(id);
      if (status === 200) {
        return { data: user };
      }
    }
    return Promise.reject({ data: params.previousData });
  },

  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);

    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll('sort'),
      filter: query.getAll('filter'),
      join: [
        'appraisingReviews',
        'utmMark',
        'customRequests',
        'contentChangeRequests',
        'profileVerificationRequests',
      ],
    };

    const { data }: any =
      await api.V1AdminUsersApi.getManyBaseAdminUsersControllerUser(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    data.data = data.data.map((item: any) => {
      item.firstName = item.firstName ? item.firstName : '—';
      item.lastName = item.lastName ? item.lastName : '—';
      item.email = item.email ? item.email : '—';
      item.age = calculateAge(item.birthDate);
      item.age = calculateAge(item.birthDate);
      item.age = calculateAge(item.birthDate);
      item.isSpecialTarif = defineIsSpecialTarif(item.customRequests);
      item.confirmed = defineConfirmed(item.contentChangeRequests);
      item.veryfied = defineVeryfied(item.profileVerificationRequests);
      return item;
    });

    return {
      data: data.data,
      total: data.total,
    };
  },

  async getMany(params: GetManyParams) {
    const users = [];
    for (let i = 0; i < params.ids?.length; i++) {
      const id = params.ids[i];
      if (typeof id === 'number') {
        try {
          const { data: user } =
            await api.V1AdminUsersApi.getOneBaseAdminUsersControllerUser(id);
          users.push(user);
        } catch (e) {
          users.push({ id: -1 });
        }
      }
    }
    return { data: users };
  },

  async getOne(params: GetOneParams) {
    const user = await api.V1AdminUsersApi.getOneBaseAdminUsersControllerUser(
      parseInt(params.id.toString()),
      undefined,
      [
        'appraisingReviews',
        'assessedReviews',
        'categories',
        'categories.category',
        'utmMark',
        'customRequests',
        'contentChangeRequests',
        'profileVerificationRequests',
        'expertServices',
      ]
    );

    const expertInfo = await api.V1UsersApi.usersControllerGetUserOne(
      parseInt(params.id.toString()),
      undefined,
    );

    const consultationsReqParams: Params = {
      filter: [`clientId||$eq||${user.data.id}`],
      or: [`expertId||$eq||${user.data.id}`],
    };

    const consultations =
      await api.V1AdminConsultationsApi.getManyBaseAdminConsultationsControllerConsultation(
        consultationsReqParams.fields,
        consultationsReqParams.s,
        consultationsReqParams.filter,
        consultationsReqParams.or
      );
    const complaints = user.data.expertDislikesCount;
    const res = {
      ...user.data,
      complaintsCount: complaints,
      consultations: consultations.data,
      isSpecialTarif: defineIsSpecialTarif(user.data.customRequests),
      internalRating: expertInfo.data.expertRatingTable.internalRating
    };

    return {
      data: res,
    };
  },

  async update(params: UpdateParams) {
    const id = params.data.id;
    const isExpert = params.data.isExpert;
    const timeToBecomeAnExpert = params.data.timeToBecomeAnExpert;

    if (typeof id === 'number') {
      if (window.location.href.slice(-2) === '/1') {
        if (params.previousData.block !== params.data.block) {
          await api.V1AdminUsersApi.adminUsersControllerBlockUser({
            id: id,
            blockStatus: params.data.block,
            reasonBlock: params.data.reasonBlock,
          });
          return {
            data: params.data,
          };
        }
      } else {
        const { data: user } =
          await api.V1AdminUsersApi.updateOneBaseAdminUsersControllerUser(id, {
            ...params.data,
            reasonBlock: undefined,
            block: undefined,
            timeToBecomeAnExpert: getTimeToBecomeAnExpert(
              isExpert,
              timeToBecomeAnExpert
            ),
          });

        return {
          data: user,
        };
      }
    }
    return Promise.reject();
  },
} as RouteDataProvider;
