import { GetListParams } from "ra-core";

import { api } from "../../api";
import { Params } from "../../types";
import { nestQueryBuilder } from "../../utils/queryBuilder";
import { RouteDataProvider } from "../types";

// @ts-ignore
export const suggestedCategoriesProvider = {
  async getList(params: GetListParams) {
    const query = nestQueryBuilder(params);

    const reqParams: Params = {
      page: params.pagination.page,
      limit: params.pagination.perPage,
      sort: query.getAll("sort"),
      filter: query.getAll("filter"),
      join: ["user"],
    };
    const { data }: any =
      await api.V1AdminSuggestedCategoriesApi.getManyBaseAdminSuggestedCategoriesControllerSuggestedCategory(
        reqParams.fields,
        reqParams.s,
        reqParams.filter,
        reqParams.or,
        reqParams.sort,
        reqParams.join,
        reqParams.limit,
        reqParams.offset,
        reqParams.page,
        reqParams.cache,
        reqParams.options
      );

    data.data = data.data.map((item: any) => {
      item.suggestedCategoryName = item.suggestedCategoryName
        ? item.suggestedCategoryName
        : "—";
      item.createdAt = item.createdAt ? item.createdAt : "—";
      item.source = item.source ? item.source : "—";
      item.userId = item.userId ? item.userId : "—";

      return item;
    });

    return {
      data: data.data,
      total: data.total,
    };
  },
} as RouteDataProvider;
