import { FC, useCallback } from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import { Contract, ContractStatusEnum } from "../../../api/generated";
import Pagination from "../../../components/pagination";
import { downloadCSV } from "../../../utils/download-csv";
import { ContractStatusChoices } from "../index";

const exporter = async (contract: Contract[]) => {
  downloadCSV({
    filename: `contracts.csv`,
    data: contract,
    columns: [
      "id",
      "owner.firstName",
      "owner.lastName",
      "status",
      "updatedAt",
      "createdAt",
    ],
    columnsNames: [
      "id",
      "имя",
      "фамилия",
      "статус",
      "дата изменения",
      "дата создания",
    ],
  });
};

const ContractsFilters = (props: any) => (
  <Filter {...props}>
    <TextInput source={"owner.firstName"} label={"Имя"} />
    <TextInput source={"owner.lastName"} label={"Фамилия"} />
    <SelectInput
      source="status"
      label={"Статус"}
      alwaysOn
      choices={[
        { id: ContractStatusEnum.Accepted, name: "Принят" },
        { id: ContractStatusEnum.Rejected, name: "Отклонен" },
        { id: ContractStatusEnum.PassedReview, name: "Прошел проверку" },
        { id: ContractStatusEnum.WaitingReview, name: "Ожидает проверки" },
        { id: ContractStatusEnum.ReviewRejection, name: "Нужны исправления" },
      ]}
    />
  </Filter>
);

export const ListContract: FC = (props: any) => {
  const rowClick = useCallback(
    () => (id: any, basePath: any, record: any) => {
      const href = `${window.location.origin}#${basePath}/${id}/show`;
      window.open(href, "_blank");
      return Promise.resolve("");
    },
    []
  );

  return (
    <List
      {...props}
      pagination={<Pagination />}
      filters={<ContractsFilters />}
      hasShow
      hasEdit
      perPage={50}
      sort={{ field: "createdAt", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter}
    >
      <Datagrid rowClick={rowClick()} hasBulkActions={false}>
        <TextField source={"id"} label={"id"} />
        <TextField source={"owner.firstName"} label={"Имя"} />
        <TextField source={"owner.lastName"} label={"Фамилия"} />
        <SelectField
          choices={ContractStatusChoices}
          source={"status"}
          label={"Статус"}
        />
        <DateField source={"updatedAt"} label={"Дата изменения"} />
        <DateField source={"createdAt"} label={"Дата создания"} />
      </Datagrid>
    </List>
  );
};
