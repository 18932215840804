import { FC } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';

import { FullNameFieldWithId } from '../../../components/full-name-field-with-id';
import Pagination from '../../../components/pagination';
import { ShowEditRecodrButton } from '../../../components/show-edit-record-button';

export const ServicesList: FC = (props: any) => {
  return (
    <List
      {...props}
      hasShow
      pagination={<Pagination />}
      hasEdit
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      perPage={50}
    >
      <Datagrid hasBulkActions={false}>
        <NumberField source={'id'} label={'ID'} />
        <DateField source={'createdAt'} label={'Дата создания'} />
        <DateField source={'updatedAt'} label={'Дата обновления'} />
        <TextField source={'title'} label={'Название'} />
        <NumberField source={'price'} label={'Стоимость'} />
        <ReferenceField
          source={'userId'}
          linkType={'show'}
          label={'Эксперт'}
          reference={'users'}
        >
          <FullNameFieldWithId />
        </ReferenceField>
        <ShowEditRecodrButton />
      </Datagrid>
    </List>
  );
};
