import React, { FC } from 'react';
import { Login } from 'react-admin';

import { LoginForm } from './login-form';

export const LoginPage: FC = (props) => {
    return (
        <Login {...props}>
            <LoginForm/>
        </Login>
    );
};

